import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

import Search from "./search";

function not(a: readonly number[], b: readonly number[]) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
	return [...a, ...not(b, a)];
}

export default function WorkcenterTransfer({
	allCompanyWorkCenters,
	companyUserData,
	setCompanyUserData,
}: any) {
	const [checked, setChecked] = React.useState<readonly number[]>([]);
	const [left, setLeft] = React.useState<readonly number[]>([]);
	const [right, setRight] = React.useState<readonly number[]>([]);

	const [lsearch, setLsearch] = React.useState("");
	const [rsearch, setRsearch] = React.useState("");

	React.useEffect(() => {
		if (allCompanyWorkCenters) {
			const left = allCompanyWorkCenters.map(
				(workCenter: { id: number }) => workCenter.id
			);
			const right = companyUserData.userWorkCenters
				? companyUserData.userWorkCenters.map(
						(workCenter: { id: number }) => workCenter.id
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  )
				: [];
			const unselected = not(left, right);
			setLeft(unselected);
		}
	}, [allCompanyWorkCenters, companyUserData]);

	React.useEffect(() => {
		if (companyUserData && companyUserData.userWorkCenters) {
			const right = companyUserData.userWorkCenters.map(
				(workCenter: { id: number }) => workCenter.id
			);
			setRight(right);
			setChecked(right);
		}
	}, [companyUserData]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, right);

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = (items: readonly number[]) =>
		intersection(checked, items).length;

	const handleToggleAll = (items: readonly number[]) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	React.useEffect(() => {
		setCompanyUserData((prev: any) => {
			const wcIds = rightChecked.join(",");
			if (prev.workCenterIds !== wcIds) {
				return {
					...prev,
					workCenterIds: wcIds,
					userWorkCenters: allCompanyWorkCenters.filter((workCenter: any) =>
						rightChecked.includes(workCenter.id)
					),
				};
			}
			return prev;
		});
	}, [rightChecked, setCompanyUserData, allCompanyWorkCenters]);
	const customList = (title: React.ReactNode, items: readonly number[]) => (
		<Box
			sx={{
				border: "1px solid #E0E0E0",
				borderRadius: "4px",
				marginTop: "16px",
			}}
		>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={
							numberOfChecked(items) === items.length && items.length !== 0
						}
						indeterminate={
							numberOfChecked(items) !== items.length &&
							numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{
							"aria-label": "all items selected",
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} selected`}
			/>
			<Divider />
			<List
				sx={{
					width: 300,
					height: 260,
					//	bgcolor: "background.paper",
					overflow: "auto",
				}}
				dense
				component='div'
				role='list'
			>
				{items.map((value: number) => {
					const labelId = `transfer-list-all-item-${value}-label`;
					const wc = allCompanyWorkCenters.find(
						(workCenter: any) => workCenter.id === value
					);

					if (wc && wc.name) {
						return (
							<ListItemButton
								key={value}
								role='listitem'
								onClick={handleToggle(value)}
							>
								<ListItemIcon>
									<Checkbox
										checked={checked.indexOf(value) !== -1}
										tabIndex={-1}
										disableRipple
										inputProps={{
											"aria-labelledby": labelId,
										}}
									/>
								</ListItemIcon>
								<ListItemText id={wc.id} primary={wc.name} />
							</ListItemButton>
						);
					}
				})}
			</List>
		</Box>
	);

	const handleSearch = (text: string, side: number[]) => {
		if (text === "") return side;
		const search = text.toLowerCase();
		return side.filter((wc: number) => {
			const wcName = allCompanyWorkCenters.find(
				(workCenter: any) => workCenter.id === wc
			)?.name;
			return wcName?.toLowerCase().includes(search);
		});
	};

	return (
		<Grid
			container
			spacing={2}
			justifyContent='space-around'
			alignItems='center'
			mt={2}
		>
			<Grid item>
				<Search value={lsearch} onChange={(text: string) => setLsearch(text)} />
				{customList("Available Work Centers", handleSearch(lsearch, [...left]))}
			</Grid>
			<Grid item>
				<Grid container direction='column' alignItems='center'>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedRight}
						disabled={leftChecked.length === 0}
						aria-label='move selected right'
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedLeft}
						disabled={rightChecked.length === 0}
						aria-label='move selected left'
					>
						&lt;
					</Button>
				</Grid>
			</Grid>
			<Grid item>
				<Search value={rsearch} onChange={(text: string) => setRsearch(text)} />
				{customList("Selected Work Centers", handleSearch(rsearch, [...right]))}
			</Grid>
		</Grid>
	);
}
