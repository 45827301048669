import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useDispatch } from "react-redux";

import { createDraftShiftAsync } from "../../../../../../export/scheduler";
import {JOB, SKILLS} from "../../../../../assets/constants";
import {CopyShiftsDataBySkillsProps, CopyShiftsDataProps} from "./types"

interface ActionButtonsProps {
	handleClose: () => void;
	copiedShiftData: CopyShiftsDataProps[];
	copiedShiftDataBySkills: CopyShiftsDataBySkillsProps[];
	shiftsByFilter: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ handleClose, copiedShiftData, copiedShiftDataBySkills, shiftsByFilter }) => {
	const dispatch = useDispatch();
	const disabled = (shiftsByFilter === JOB && copiedShiftData.length === 0) || (shiftsByFilter === SKILLS && copiedShiftDataBySkills.length === 0);
	return (
		<Box
			display='flex'
			width='100%'
			justifyContent='flex-end'
			alignItems='center'
		>
			{" "}
			<Box ml={2} display='flex' justifyContent='space-between' width='220px'>
				<Button color='primary' variant='text' onClick={handleClose} sx={{letterSpacing: "0.02857em"}}>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='primary'
					type='submit'
					disabled={disabled}
					onClick={() => {
						let data: CopyShiftsDataProps[] | CopyShiftsDataBySkillsProps[] = [];
						if (shiftsByFilter === JOB) {
							data = copiedShiftData;
						} else if (shiftsByFilter === SKILLS) {
							data = copiedShiftDataBySkills;
						}
						dispatch(
							createDraftShiftAsync({ copiedShiftData: data, copiedOn: true })
						);
					}}
					sx={{letterSpacing: "0.02857em"}}
				>
					Copy Shifts
				</Button>
			</Box>
		</Box>
	);
};

export default ActionButtons;
