export const colors = {
    primary: "#202020",
    secondary: "#f0ae51",
    dark: "#000",
    gray: "#808080",
    orange: "#ffa500",
    delete: "#DA1E28", // error borders, error text
    border: "#dadce0", //passcode bg
    button: "#3476F4", // button bg, passcode border
    title: "#414141",
    box: "#F8FCFF",
    light: "#fff", // container bg, light text
    selection: "#366BBF",
    dot: "#6FDC8C",
    shadow: "#171717",
    selected: "#E0EBFF",
    transparent: "transparent",
    error: "tomato", // unused
    disabledText: "rgba(0, 0, 0, 0.26)",
    disabledBg: "#ddd",
    disclaimer: "#6a6a6a",
    scheduled: "green",
    decline: "red",
    errorBox: "#FBEAEA",
    warningBox: "rgb(255, 243, 195)",
    green: "#2F6113",
    greenLight: "#A6DF6F",
    yellow: "#FFCC00",
    yellowLight: "#FFFF66",
    red: "#FF0E0E",
    redLight: "#FFCCCB",
    dialogBackground: "rgba(0,0,0,0.3)",
    grey: "#EDEDED",
    greyLight: "#F9F9F9",
    path: "#E8ECEF",
    stepperBackground: "#2F4D8B",
    infoBoxBackground: "#FDF0E6",
    errorText: "#5F2B01",
    warningColor: "#ED6C02",
    adminInfoIconColor: "#0288D1",
    adminInfoTextColor: "#013654",
    adminInfoBackgroundColor: "#E6F3FA",
    errorBorder: "#E44B48",
    adminPlaceholderBackground: "#FAFAFA",
    adminPlaceholderBorder: "rgba(0,0,0,0.12)",
    infoBox: "#E6F3FA",
    infoIcon: "#0288D1",
    noticeIcon: "#ED6C02",
    noticeText: "#5F2B01",
    noticeBox: "#FDF0E6",
    infoText: "#013654",
    shiftCalendarButtonBorder: "#79747E",
    shiftCalendarText: "#49454F",
};
export default colors;
