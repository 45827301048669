// TODO - Type 'any' needs to be fixed.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import startOfDay from "date-fns/startOfDay";

import axios from "../../../utils/api-client";
import { setEndReducer } from "../reducers/setEndReducer";
import { setStartReducer } from "../reducers/setStartReducer"
import { AttendanceReportsStateTypes, ShiftWorkerHoursModel } from "./attendance-reports-types";



const initialState: AttendanceReportsStateTypes = {
    currentDate: startOfDay(new Date()).toISOString(),
    dayReport: [],
    getDayReport: {
        status: "idle",
        errorMessage: "",
    },
    workCenterReport: [],
    getWorkCenterReport: {
        status: "idle",
        errorMessage: "",
    },
    startDate: new Date(
        new Date().setMonth(new Date().getMonth() - 1)
    ).toISOString(),
    endDate: new Date().toISOString(),
    allWorkers: [],
    fetchWorkersData: {
        status: "idle",
        errorMessage: "",
    },

}

export const getDayReport: any = createAsyncThunk(
    "worker/getDayReport",
    async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
        const date = getState().report.currentDate;

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Reports/GetReportDayWorkers`,
                {
                    params: {
                        dateOfReport: date,
                    },
                }
            );

            if (res.status === 200) {
                return fulfillWithValue(res.data);
            }
            if (res.status === 204) {
                return fulfillWithValue([]);
            }
            return rejectWithValue("An error occurred");
        } catch (error: any) {
            // console.log(error);
            if (error.message === "Request failed with status code 404")
                return rejectWithValue({ error: "No data found" });
            return rejectWithValue({ error: error.response.data });
        }
    }
);

// export const markAttendance = createAsyncThunk(
//   "worker/absent",
//   async (payload, thunkAPI) => {
//     console.log('payload to mark attendance: ', payload)
//     try {
//       console.log('marking absent/present with: ', payload)
//       const res = await fetch(`${process.env.REACT_APP_API_END_POINT}/Workers/Absent`, {
//         method: 'post',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': 'Token ' + localStorage.getItem("token"),
//         },
//         body: JSON.stringify(payload?._data)
//       })

//       if (res.status === 200) {
//         console.log(res, ' is the response for absent/present')
//         return thunkAPI.fulfillWithValue(res.data);
//       }
//       if(res.status === 204) {
//         return thunkAPI.fulfillWithValue([])
//       }
//       return thunkAPI.rejectWithValue("An error occurred");
//     } catch (error) {
//       console.log(error);
//       if (error.message === "Request failed with status code 404")
//         return thunkAPI.rejectWithValue({ error: "No data found" });
//       return thunkAPI.rejectWithValue({ error: error.response.data });
//     }
//   }
// );

export const getWorkCenterReport: any = createAsyncThunk(
    "worker/getWorkCenterReport",
    async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
        const start = getState().report.startDate;
        const end = getState().report.endDate;

        try {
            const res = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Reports/GetReportWorkerHours`,
                {
                    params: {
                        dateOfReportStart: start,
                        dateOfReportEnd: end,
                    },
                }
            );
            //dont know this type
            const r = res.data.map((it: any, i: number) => (it = { ...it, id: i }));

            if (res.status === 200) {
                return fulfillWithValue(r);
            }
            if (res.status === 204) {
                return fulfillWithValue([]);
            }
            return rejectWithValue("An error occurred");
        } catch (error: any) {
            // console.log(error);
            if (error.message === "Request failed with status code 404")
                return rejectWithValue({ error: "No data found" });
            return rejectWithValue({ error: error.response.data });
        }
    }
);

// export const getAllWorkers = createAsyncThunk(
//  "manageWorkers/skillLevels",
//  async (payload, { fulfillWithValue, rejectWithValue }) => {
//      try {
//          const getSkillLevels = await axios.get(
//              `${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
//          );
//          return fulfillWithValue(getSkillLevels.data);
//      } catch (error) {
//          return rejectWithValue(error.message);
//      }
//  }
// );

export const fetchWorkersData: any = createAsyncThunk(
    "fetchWorkersData",
    async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
        try {
            const getAllWorkers = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/companies/workers`
            );
            if (getAllWorkers.status === 200) {
                return fulfillWithValue(getAllWorkers.data);
            }
            if (getAllWorkers.status === 204) {
                return fulfillWithValue([]);
            }
            return rejectWithValue("An Error Occured");
        } catch (error: any) {
            // console.error(error);
            return rejectWithValue(error.message);
        }
    }
);
export const ReportSlice = createSlice({
    name: "report",
    initialState,
    extraReducers: {
        [getDayReport.pending]: (state, action) => {
            state.getDayReport.status = "pending";
        },
        [getDayReport.fulfilled]: (state, action) => {
            let report = [];
            if (action.payload) {
                report = action.payload.map(
                    (item: ShiftWorkerHoursModel) =>
                    (item = {
                        ...item,
                        fullName: `${item.firstName} ${item.lastName}`,
                        id: item.workerId + item.rowId,
                    })
                );
            }
            state.dayReport = report;
            state.getDayReport.status = "fulfilled";
        },
        [getDayReport.rejected]: (state, action) => {
            const error = action.payload?.error;
            if (error === "No data found") {
                state.getDayReport.status = "error";
                state.getDayReport.errorMessage = "There are no reports available .";
            } else {
                state.getDayReport.status = "error";
                state.getDayReport.errorMessage = error ?? "An error occurred.";
            }
        },
        [getWorkCenterReport.pending]: (state, action) => {
            state.getWorkCenterReport.status = "pending";
        },
        [getWorkCenterReport.fulfilled]: (state, action) => {
            let report = [];
            if (action.payload) {
                report = action.payload.map(
                    (item: ShiftWorkerHoursModel) =>
                    (item = {
                        ...item,
                        fullName: `${item.firstName} ${item.lastName}`,
                        id: item.workerId + item.shiftId,
                    })
                );
            }
            state.workCenterReport = report;
            state.getWorkCenterReport.status = "fulfilled";
        },
        [getWorkCenterReport.rejected]: (state, action) => {
            const error = action.payload?.error;
            if (error === "No data found") {
                state.getWorkCenterReport.status = "error";
                state.getWorkCenterReport.errorMessage =
                    "There are no reports available .";
            } else {
                state.getWorkCenterReport.status = "error";
                state.getWorkCenterReport.errorMessage = error ?? "An error occurred.";
            }
        },
        [fetchWorkersData.fulfilled]: (state, action) => {
            const allWorkers = action.payload;
            state.allWorkers = allWorkers;
            state.fetchWorkersData.status = "fulfilled";
        },
        [fetchWorkersData.pending]: (state) => {
            state.fetchWorkersData.status = "pending";
        },
        [fetchWorkersData.rejected]: (state, action) => {
            state.fetchWorkersData.status = "error";
            state.fetchWorkersData.errorMessage =
                action.payload ?? "An error occurred";
        },
    },
    reducers: {
        setStart: setStartReducer,
        setEnd: setEndReducer,
        resetWorkCenterReport: (state) => {
            state.workCenterReport = [];
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload;
        },
    },
});

export const { setStart, setEnd, resetWorkCenterReport, setCurrentDate } =
    ReportSlice.actions;

export default ReportSlice.reducer;

