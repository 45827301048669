// TODO - Type 'any' needs to be fixed.
import isPast from "date-fns/isPast";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../js/features/ManageJobs/ManageJobsSlice";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {
	configureDatesOfTheWeek,
	getInitValues,
	yupInitObject,
} from "../utils";

const useEditShiftDialog = () => {
	const [filteredJobs, setFilteredJobs] = useState<any[]>([]);

	const {
		allShiftsData,
		shiftIdForEditShift,
		defaultDurationHrs,
		minDurationHrs,
		maxDurationHrs,
		shiftId,
		filterJobRoles,
		filterAllWorkcenters,
	} = useSchedulerData();

	const dispatch = useDispatch();

	const currentShiftDetails = useCallback(() => {
		const shiftData = allShiftsData.filter(
			(shift: { id: number | string }) =>
				shift.id.toString() === shiftIdForEditShift
		);
		return shiftData[0];
	}, [allShiftsData, shiftIdForEditShift]);

	const [initialValues, setInitialValues] = useState(
		getInitValues({ allShiftsData, shiftIdForEditShift, currentShiftDetails, filterAllWorkcenters })
	);
	const [schema, setSchema] = useState({});

	const [totalHours, setTotalHours] = useState(0);
	const [totalMins, setTotalMins] = useState(0);

	const [initialShiftDateTimeValues, setInitialShiftDateTimeValue] = useState(
		getInitValues({ allShiftsData, shiftIdForEditShift, currentShiftDetails, filterAllWorkcenters })
	);
	const [selectedDatesOfTheWeek, setSelectedDatesOfTheWeek] = useState(
		configureDatesOfTheWeek(
			new Date(
				getInitValues({
					allShiftsData,
					shiftIdForEditShift,
					currentShiftDetails,
					filterAllWorkcenters,
				}).startTime
			)
		)
	);

	const handleWorkcenterChange = useCallback(
		(workcenterId) => {
			const jobs: any[] = [];
			const selectedWorkcenter = filterAllWorkcenters.find(
				(workcenter: { id: number }) => workcenter.id === parseInt(workcenterId)
			);
			const jobIds = selectedWorkcenter.jobIds.split(",").map((id: string) => parseInt(id));
			filterJobRoles.forEach((job: { id: number }) => {
				if (jobIds.includes(job.id)) {
					jobs.push(job);
				}
			});
			setFilteredJobs(jobs);
		},
		[filterJobRoles, filterAllWorkcenters, setFilteredJobs]
	);

	const getNumberOfStaff = useCallback(
		(
			jobRole: string | null,
			locationId: string,
			startTime: string,
			endTime: string,
			shiftSkills?: any[]
		) => {
			if (!locationId || !startTime || !endTime) return;
			if (jobRole === "" && shiftSkills?.length === 0) return;

			dispatch(
				fetchMaxWorkersByLocationJob({
					locationId,
					jobId: jobRole === "" ? null : jobRole,
					startDateTime: startTime,
					endDateTime: endTime,
					shiftSkills,
				})
			);
		},
		[dispatch]
	);

	const calculateHours = useCallback(
		(startTime, endTime, numberOfWorkersNeeded) => {
			const { hours, minutes } = calculateShiftHoursAndMinutes(
				startTime,
				endTime,
				numberOfWorkersNeeded
			);

			setTotalHours(hours);
			setTotalMins(minutes);
		},
		[setTotalHours, setTotalMins]
	);

	const calculateTotalHoursFromWorkers = useCallback(
		(formValues, numberOfWorkersNeeded) => {
			const { startTime, endTime } = formValues;
			if (startTime && endTime && numberOfWorkersNeeded) {
				calculateHours(startTime, endTime, numberOfWorkersNeeded);
			}
		},
		[calculateHours]
	);

	const calculateTotalHoursFromStartTime = (
		formValues: {
			numberOfWorkersNeeded: string;
			endTime: string;
		},
		startTime: string
	) => {
		const { numberOfWorkersNeeded, endTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const calculateTotalHoursFromEndTime = (
		formValues: {
			numberOfWorkersNeeded: string;
			startTime: string;
		},
		endTime: string
	) => {
		const { numberOfWorkersNeeded, startTime } = formValues;
		if (startTime && endTime && numberOfWorkersNeeded) {
			calculateHours(startTime, endTime, numberOfWorkersNeeded);
		}
	};

	const foundJob = (jobId: number) => {
		const found = filteredJobs.filter(
			(job: { id: number }) => job.id === jobId
		);
		return found.length > 0;
	};

	useEffect(() => {
		const initValues = getInitValues({
			allShiftsData,
			shiftIdForEditShift,
			currentShiftDetails,
			filterAllWorkcenters,
		});

		handleWorkcenterChange(initValues.workCenterId);
		getNumberOfStaff(
			initValues.jobId ?? null,
			initValues.locationId,
			initValues.startTime,
			initValues.endTime,
			initValues.shiftSkills
		);
		calculateTotalHoursFromWorkers(
			initValues,
			initValues.numberOfWorkersNeeded
		);
		const schema = Yup.object(yupInitObject);
		setInitialValues(initValues);

		setSchema(schema);
	}, [
		defaultDurationHrs,
		allShiftsData,
		shiftIdForEditShift,
		currentShiftDetails,
		minDurationHrs,
		maxDurationHrs,
		handleWorkcenterChange,
		getNumberOfStaff,
		calculateTotalHoursFromWorkers,
		filterAllWorkcenters,
	]);

	const isDisabled = (currentDate: Date) => {
		return isPast(currentDate) || false;
	};

	return [
		{
			initialValues,
			schema,
			initialShiftDateTimeValues,
			filteredJobs,
			selectedDatesOfTheWeek,
			totalHours,
			totalMins,
			shiftId,
		},
		{
			isDisabled,
			calculateTotalHoursFromEndTime,
			calculateTotalHoursFromStartTime,
			calculateTotalHoursFromWorkers,
			handleWorkcenterChange,
			getNumberOfStaff,
			setInitialShiftDateTimeValue,
			setSelectedDatesOfTheWeek,
			setTotalHours,
			foundJob,
		},
	];
};

export default useEditShiftDialog;
