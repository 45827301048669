// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import ArrowBack from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { GridRowId } from "@mui/x-data-grid";
import isPast from "date-fns/isPast";
import _ from "lodash";
import { useEffect, useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory} from "react-router-dom";

import {
	assignWorkers,
	renotifyWorkers,
	resetShiftDetails
} from "../../../../../../export/scheduler";
import {
	ASSIGN_SHIFT,
	SHIFT_DETAILS,
	WORKCENTER,
} from "../../../../../assets/constants";
import CheckboxPopover from "../../../../../design/checkbox-popover";
import {
	formatDate,
	getHourMinuteDifference,
} from "../../../../../utils/helpers";
import AssignShiftTable from "../../shift-details/assign-shift-table";
import Notice from "../../shift-details/notice";
import ShiftDetailsTable from "../../shift-details/ShiftDetailsTable";
import ShiftMetaData from "../../shift-details/ShiftMetaData";
import { WorkersExtendedPropV2 } from "../shift-details-dialog/types";
import useShiftDetailsDialogController from "./controllers/use-shift-details-dialog-controller";
import ReviewWorkerAssignShiftCheckbox from "./review-worker-assign-shift-checkbox";

const useStyles = makeStyles((theme) => ({
	actionBar: {
		marginTop: 8,
		marginBottom: 8,
		marginRight: 16,
	},

	createdMetaData: {
		fontSize: "0.8em",
		color: "#ccc",
	},
}));

interface ShiftDetailsDialogProps {
	open: boolean;
	handleClose: any;
	resetAssignWorkers: any;
	shiftId: any;
	isWorkerRequest: boolean;
}

interface AssignWorkerResponseProp {
	workerId: string;
	shiftId: number;
	isSuccess: boolean;
	message: string;
}
const ShiftDetailsDialog: React.FC<ShiftDetailsDialogProps> = ({
	open,
	handleClose,
	resetAssignWorkers,
	shiftId,
	isWorkerRequest,
}) => {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(SHIFT_DETAILS);
	const [search, setSearch] = useState("");
	const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
	const [columnAnchorEl, setColumnAnchorEl] = useState<null | HTMLElement>(null);
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);
	const [
		{
			shiftMetadata,
			jobRoleMetadata,
			workCenterMetadata,
			availableWorkersForShiftRow,
			columns,
			allSkillLevels,
			confirmedWorkersCount,
			iAmSure,
			rowsV2,
			statusOptions,
		},
		{ setAvailableWorkersForShiftRow, setRowsV2, setIAmSure, setStatusOptions, setColumns },
	] = useShiftDetailsDialogController(
		shiftId,
		isWorkerRequest,
		search,
		activeStep
	);

	const [workersSelected, setWorkersSelected] = useState<GridRowId[]>([]);
	const [workersSelectedForAssignShift, setWorkersSelectedForAssignShift] =
		useState<GridRowId[]>([]);
	const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
		number[]
	>([]);

	const status = useSelector(
		(state: any) => state.supervisor?.shiftDetailsV2?.status
	);

	const errorMessage = useSelector(
		(state: any) => state.supervisor?.shiftDetailsV2?.errorMessage
	);

	const renotifyWorkersStatus = useSelector(
		(state: any) => state.supervisor?.renotifyWorkers.status
	);

	const assignWorkersStatus = useSelector(
		(state: any) => state.supervisor?.assignWorkers.status
	);

	const assignWorkersResponse: AssignWorkerResponseProp[] = useSelector(
		(state: any) => state.supervisor?.assignWorkers.response
	);

	const assignShiftToWorkerEnabled = useSelector(
		(state: any) => state.user.userData.assignShiftToWorkerEnabled
	);

	const assignShiftToWorkerHrsBeforeShift = useSelector(
		(state: any) => state.user.userData.assignShiftToWorkerHrsBeforeShift
	);

	const canShowNoticeForForceAssign = () => {
		try {
			const isReadOnly = Array.isArray(userRoles)
				? userRoles.includes("ReadOnly")
				: false;
			const requirementFulfilled =
				confirmedWorkersCount !== undefined
					? parseInt(shiftMetadata.numberOfWorkersNeeded) -
							confirmedWorkersCount ===
					  0
					: parseInt(shiftMetadata?.numberOfWorkersNeeded) -
							parseInt(shiftMetadata?.slotsFilled) ===
					  0;
			const withinAssignTimeRange =
				parseInt(assignShiftToWorkerHrsBeforeShift) * 60 >
					getHourMinuteDifference(
						new Date(shiftMetadata?.startDate),
						new Date()
					).differenceInMinutes &&
				getHourMinuteDifference(new Date(shiftMetadata?.startDate), new Date())
					.differenceInMinutes > 0;
			return (
				!requirementFulfilled &&
				assignShiftToWorkerEnabled &&
				!isReadOnly &&
				shiftMetadata?.shiftAllocationType === WORKCENTER &&
				withinAssignTimeRange
			);
		} catch (e) {
			return false;
		}
	};

	const getAssignedWorkerSuccessMessage = () => {
		let returnValue = "";
		if (Array.isArray(assignWorkersResponse)) {
			const successWorkers = assignWorkersResponse.filter(
				(assignWorker) => assignWorker.isSuccess
			);
			if (successWorkers.length > 0) {
				returnValue = `Shift assigned to ${successWorkers.length} worker${
					successWorkers.length > 1 ? "s" : ""
				}`;
			} else {
				returnValue = "";
			}
		}
		return returnValue;
	};

	useEffect(() => {
		try {
			if (assignWorkersStatus === "fulfilled") {
				if (
					Array.isArray(assignWorkersResponse) &&
					setAvailableWorkersForShiftRow &&
					setRowsV2 &&
					setIAmSure
				) {
					// All successfully assigned workers
					const successWorkers = assignWorkersResponse.filter(
						(assignedWorker) => assignedWorker.isSuccess
					);
					const successWorkersIds = successWorkers.map((successWorker) => {
						return successWorker.workerId;
					});

					// All workers having error while assigning
					const errorWorkers = assignWorkersResponse.filter(
						(assignedWorker) => !assignedWorker.isSuccess
					);
					const errorWorkersIds = errorWorkers.map((errorWorker) => {
						return errorWorker.workerId;
					});

					// Remove confirmed workers and show error to those who can not be assigned
					setAvailableWorkersForShiftRow((prevState) => {
						let newState: WorkersExtendedPropV2[] = [];
						prevState.forEach((worker) => {
							if (errorWorkersIds.includes(worker.workerId)) {
								const selectedResponse = errorWorkers.find(
									(errorWorker) => errorWorker.workerId === worker.workerId
								);
								if (selectedResponse) {
									newState.push({
										...worker,
										errorMessage: selectedResponse.message,
									});
								}
							} else {
								newState.push(worker);
							}
						});
						newState = _.orderBy(newState, "errorMessage", ["desc"]);
						newState = newState.filter((obj: any, index: number) => {
							return index === newState.findIndex((o: any) => obj.id === o.id);
						});
						newState = _.orderBy(newState, "order", ["asc"]);
						return newState;
					});

					// Update main workers list with confirmed status
					setRowsV2((prevState) => {
						let newState: WorkersExtendedPropV2[] = [];
						prevState.forEach((worker) => {
							if (successWorkersIds.includes(worker.workerId)) {
								newState.push({
									...worker,
									status: "Assigned",
									order: 2,
								});
							} else if (errorWorkersIds.includes(worker.workerId)) {
								const selectedResponse = errorWorkers.find(
									(errorWorker) => errorWorker.workerId === worker.workerId
								);
								if (selectedResponse) {
									newState.push({
										...worker,
										errorMessage: selectedResponse.message,
									});
								}
							} else {
								newState.push(worker);
							}
						});
						newState = newState.filter((obj: any, index: number) => {
							return index === newState.findIndex((o: any) => obj.id === o.id);
						});
						newState = _.orderBy(newState, "order", ["asc"]);
						return newState;
					});

					// If non fail then navigate to previous step
					const isFailed = assignWorkersResponse.find(
						(response) => !response.isSuccess
					);
					if (!isFailed) {
						setActiveStep(SHIFT_DETAILS);
					}
					setIAmSure(false);
				}
			}
		} catch (e) {
			// Do nothing
		}
	}, [
		assignWorkersResponse,
		assignWorkersStatus,
		setAvailableWorkersForShiftRow,
		setRowsV2,
		setIAmSure,
	]);

	const publishedDateISOString =
		shiftMetadata?.createdDate &&
		new Date(
			new Date(shiftMetadata?.createdDate)?.getTime() -
				new Date()?.getTimezoneOffset() * 60 * 1000
		).toISOString();

	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth={false}
		>
			{status === "pending" ? (
				<DialogContent dividers>
					<Box
						width={1155}
						height={500}
						display='flex'
						alignItems='center'
						justifyContent='center'
						mr='auto'
						ml='auto'
						flexDirection='column'
					>
						<CircularProgress />
						<Typography>Loading Shift Details</Typography>
					</Box>
				</DialogContent>
			) : (
				<>
					<DialogTitle
						id='customized-dialog-title'
						minWidth={"1155px"}
						sx={{
							padding: 0,
						}}
					>
						{activeStep === SHIFT_DETAILS && (
							<>
								<ShiftMetaData
									shiftMetadata={shiftMetadata}
									jobRoleMetadata={jobRoleMetadata}
									workCenterMetadata={workCenterMetadata}
									allSkillLevels={allSkillLevels}
									confirmedWorkersCount={confirmedWorkersCount}
								/>
								{canShowNoticeForForceAssign() && (
									<Box
										sx={{
											padding: "0px 45px 5px 45px",
										}}
									>
										{/* In case there is no worker to assign when trying to assign worker */}
										{(availableWorkersForShiftRow === undefined ||
											availableWorkersForShiftRow.length === 0) && (
											<Notice
												message='No workers available for shift assignment.'
												variant='error'
											/>
										)}
										{/* When there are some worker which can be assigned */}
										{availableWorkersForShiftRow !== undefined &&
											availableWorkersForShiftRow.length > 0 && (
												<Notice
													variant='normal'
													// message={`${
													//   getHourMinuteDifference(
													//     new Date(shiftMetadata?.startDate),
													//     new Date()
													//   ).differenceInText
													// } remaining for shift to start. You can manually assign the shift to eligible workers.`}
													message='Not enough employees agreed to come for the OT shift posted. Please assign the employees needed and contact the employee directly to inform them that they are being assigned to the forced OT shift.'
													buttonLabel='Assign Shift'
													callToAction={() => {
														setActiveStep(ASSIGN_SHIFT);
													}}
												/>
											)}
									</Box>
								)}
							</>
						)}
						{/* This header is for assign shift to worker */}
						{activeStep === ASSIGN_SHIFT && (
							<Box
								sx={{
									padding: "45px 45px 5px 45px",
									width: "100%",
								}}
							>
								<Box
									mb={1.5}
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "flex-start",
										justifyContent: "flex-start",
									}}
								>
									<Box
										sx={{
											cursor: "pointer",
										}}
										onClick={() => setActiveStep(SHIFT_DETAILS)}
									>
										<ArrowBack
											style={{
												color: "rgba(0, 0, 0, 0.87)",
												width: "32px",
												height: "32px",
												padding: "4px",
											}}
										/>
									</Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
											justifyContent: "flexStart",
											marginLeft: "4px",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontSize: "24px",
												fontWeight: "400",
												lineHeight: "31px",
												color: "rgba(0, 0, 0, 0.87)",
											}}
										>
											Assign shift
										</Typography>
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontSize: "16px",
												fontWeight: "400",
												lineHeight: "28px",
												color: "rgba(0, 0, 0, 0.87)",
												marginTop: "12px",
											}}
										>
											{confirmedWorkersCount !== undefined
												? `${confirmedWorkersCount} / ${
														shiftMetadata.numberOfWorkersNeeded
												  } workers confirmed, ${
														parseInt(shiftMetadata.numberOfWorkersNeeded) -
														confirmedWorkersCount
												  } more worker${
														parseInt(shiftMetadata.numberOfWorkersNeeded) -
															confirmedWorkersCount >
														1
															? "s"
															: ""
												  } required`
												: `${shiftMetadata?.slotsFilled} / ${
														shiftMetadata?.numberOfWorkersNeeded
												  } workers confirmed, ${
														parseInt(shiftMetadata?.numberOfWorkersNeeded) -
														parseInt(shiftMetadata?.slotsFilled)
												  } more worker${
														parseInt(shiftMetadata?.numberOfWorkersNeeded) -
															parseInt(shiftMetadata?.slotsFilled) >
														1
															? "s"
															: ""
												  } required`}
										</Typography>
									</Box>
								</Box>
							</Box>
						)}
					</DialogTitle>
					<DialogContent sx={{ minWidth: "1155px", minHeight: 250 }}>
						<>
							{/* Render search only when we have some rows in table */}
							{((activeStep === ASSIGN_SHIFT &&
								availableWorkersForShiftRow !== undefined &&
								availableWorkersForShiftRow.length > 0) ||
								(activeStep === SHIFT_DETAILS &&
									rowsV2 !== undefined &&
									rowsV2.length > 0)) && (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										height: "57px",
										width: "100%",
										paddingLeft: "21px",
										paddingRight: "21px",
										gap: 2,
									}}
								>
									{/* Currently Rendering filter only when we are in shift details step */}
									{activeStep === SHIFT_DETAILS && (
										<>
											<Box
												sx={{
													flex: 0,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													p: 0.5,
													borderRadius: "4px",
													background: "#0000000A",
													":hover": {
														cursor: "pointer",
														background: "#0000001F",
													},
												}}
												onClick={(e) => {
													setFilterAnchorEl(e.currentTarget);
												}}
											>
												<FilterListIcon />
											</Box>
											<CheckboxPopover
												anchorElement={filterAnchorEl}
												options={statusOptions}
												setOptions={setStatusOptions}
												onClose={() => {
													setFilterAnchorEl(null);
												}}
												selectAllCheckboxOption={false}
												minWidth={220}
												showCount
												disableMaxHeight
											/>
										</>
									)}
									<Box 
									sx={{
										flex: 0,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										p: 0.5,
										borderRadius: "4px",
										background: "#0000000A",
										":hover": {
											cursor: "pointer",
											background: "#0000001F",
										},
									}}
									onClick={(e) => {
										setColumnAnchorEl(e.currentTarget);
									}}
									>
										<ViewColumnOutlinedIcon />
									</Box>
									<CheckboxPopover
										anchorElement={columnAnchorEl}
										options={columns}
										setOptions={setColumns}
										onClose={() => {
											setColumnAnchorEl(null);
										}}
										selectAllCheckboxOption={false}
										minWidth={220}
										disableMaxHeight
									/>
									<Box sx={{ flex: 1 }}>
										<TextField
											hiddenLabel
											value={search}
											onChange={(e) => {
												try {
													const text = e.target.value;
													setSearch(text);
												} catch (error) {
													// Do nothing
												}
											}}
											placeholder={"Search a Worker"}
											variant='filled'
											size='small'
											InputProps={{
												disableUnderline: true,
												startAdornment: (
													<SearchIcon
														style={{
															height: "24px",
															width: "24px",
															color: "rgba(0,0,0,0.56)",
														}}
													/>
												),
												endAdornment:
													search !== "" ? (
														<ClearIcon
															onClick={() => {
																setSearch("");
															}}
															style={{
																height: "24px",
																width: "24px",
																color: "rgba(0,0,0,0.56)",
																cursor: "pointer",
															}}
														/>
													) : (
														<></>
													),
											}}
											sx={{
												width: "100%",
												border: "1px solid rgba(0,0,0,0.12)",
												borderRadius: "4px",
												input: {
													paddingLeft: "8px",
													color: "rgba(0,0,0,0.87)",
													fontFamily: "Roboto",
													fontSize: "12px",
													fontWeight: "400",
													lineHeight: "20px",
													letterSpacing: "0.4px",
												},
											}}
										/>
									</Box>
								</Box>
							)}
							{activeStep === ASSIGN_SHIFT && (
								<AssignShiftTable
									rows={availableWorkersForShiftRow ?? []}
									columns={columns}
									setWorkersSelected={setWorkersSelectedForAssignShift}
									readOnly={
										Array.isArray(userRoles)
											? userRoles.includes("ReadOnly")
											: false
									}
									searchValue={search}
									detailPanelExpandedRowIds={detailPanelExpandedRowIds}
									workersSelected={workersSelectedForAssignShift}
									totalWorkersRequired={
										confirmedWorkersCount !== undefined
											? parseInt(shiftMetadata.numberOfWorkersNeeded) -
											  confirmedWorkersCount
											: parseInt(shiftMetadata.numberOfWorkersNeeded) -
											  parseInt(shiftMetadata.slotsFilled)
									}
								/>
							)}
							{activeStep === SHIFT_DETAILS && (
								<ShiftDetailsTable
									rows={rowsV2}
									columns={columns.filter((column: any) => column.checked)}
									setWorkersSelected={setWorkersSelected}
									isShiftPast={isPast(new Date(shiftMetadata?.endDateTime))}
									readOnly={
										Array.isArray(userRoles)
											? userRoles.includes("ReadOnly")
											: false
									}
									searchValue={search}
									totalWorkersRequired={
										confirmedWorkersCount !== undefined
											? parseInt(shiftMetadata.numberOfWorkersNeeded) -
											  confirmedWorkersCount
											: parseInt(shiftMetadata?.numberOfWorkersNeeded) -
											  parseInt(shiftMetadata?.slotsFilled)
									}
									statusOptions={statusOptions}
								/>
							)}
						</>
					</DialogContent>
					<Box
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						pt={4}
						pb={4}
						pl={5.5}
						pr={5.5}
						sx={{
							borderTop: 1,
							borderColor: "rgba(0, 0, 0, 0.12)",
						}}
						minWidth={"1155px"}
					>
						{activeStep === SHIFT_DETAILS && (
							<Box style={{ marginRight: "24px" }}>
								<Typography className={classes.createdMetaData}>
									Published by {shiftMetadata?.createdByName} on{" "}
									{shiftMetadata?.createdDate &&
										`
											${formatDate({
												dateStr: publishedDateISOString,
												formatType: "SHORT_DATE",
											})} ${formatDate({
											dateStr: publishedDateISOString,
											formatType: "TIME",
										})}
										`}
								</Typography>
							</Box>
						)}
						{activeStep === ASSIGN_SHIFT && (
							<ReviewWorkerAssignShiftCheckbox
								iAmSure={iAmSure}
								setIAmSure={setIAmSure}
							/>
						)}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							{activeStep === SHIFT_DETAILS && (
								<>
									<Button variant='text' onClick={() => handleClose()}>
										Cancel
									</Button>
									<Button
										autoFocus
										color='primary'
										variant='contained'
										sx={{
											marginLeft: "8px",
										}}
										disabled={
											(Array.isArray(userRoles)
												? userRoles.includes("ReadOnly")
												: false) ||
											isPast(new Date(shiftMetadata?.startDateTime))
												? true
												: workersSelected.length === 0 ||
												  renotifyWorkersStatus === "pending" ||
												  renotifyWorkersStatus === "error"
										}
										onClick={() =>
											dispatch(
												renotifyWorkers({ workers: workersSelected, shiftId })
											)
										}
									>
										{renotifyWorkersStatus === "pending" ? (
											<Box position='relative'>
												<CircularProgress
													size={25}
													style={{ position: "absolute", left: "50%" }}
												/>
												Renotifying
											</Box>
										) : (
											"Resend Notification"
										)}
									</Button>
								</>
							)}
							{activeStep === ASSIGN_SHIFT && (
								<LoadingButton
									fullWidth
									disabled={
										(Array.isArray(userRoles)
											? userRoles.includes("ReadOnly")
											: false) || isPast(new Date(shiftMetadata?.startDateTime))
											? true
											: workersSelectedForAssignShift.length === 0 ||
											  assignWorkersStatus === "pending" ||
											  assignWorkersStatus === "error" ||
											  !iAmSure
									}
									variant='contained'
									loading={assignWorkersStatus === "pending"}
									onClick={() => {
										dispatch(
											assignWorkers({
												workers: workersSelectedForAssignShift,
												shiftId: shiftId,
											})
										);
									}}
									loadingPosition='start'
									loadingIndicator={
										<CircularProgress
											size={16}
											sx={{
												color: "rgba(0, 0, 0, 0.38)",
												marginLeft: "12px",
											}}
										/>
									}
								>
									{assignWorkersStatus === "pending" ? "ASSIGNING" : "ASSIGN"}
								</LoadingButton>
							)}
						</Box>
					</Box>
				</>
			)}
			{assignWorkersStatus === "fulfilled" &&
			getAssignedWorkerSuccessMessage() !== "" ? (
				<Snackbar
					open={assignWorkersStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => {
						resetAssignWorkers();
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						{getAssignedWorkerSuccessMessage()}
					</MuiAlert>
				</Snackbar>
			) : null}
			{status === "error" ? (
					<Snackbar
						open={status === "error"}
						autoHideDuration={5000}
						onClose={() => dispatch(resetShiftDetails())}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
					>
						<MuiAlert elevation={6} variant="filled" severity="error" onClose={handleClose}>
							<Box display="flex" flexDirection="column" alignItems="center">
								<Box mb={1}>
									{errorMessage ?? "Error"}
								</Box>
								{/*<Button*/}
								{/*	variant="contained"*/}
								{/*	color="primary"*/}
								{/*	onClick={() => history.push("/login")}*/}
								{/*>*/}
								{/*	Try Login Again*/}
								{/*</Button>*/}
							</Box>
						</MuiAlert>
					</Snackbar>
			) : null}
		</Dialog>
	);
};

export default ShiftDetailsDialog;
