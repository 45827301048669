import { useState } from "react";

const useSchedulerDialog = () => {
	/**
	 * State for new shift dialog modal
	 */
	const [isCreateNewShiftDialogOpen, setIsCreateNewShiftDialogOpen] =
		useState(false);
	const [isCreateWeekShiftDialogOpen, setIsCreateWeekShiftDialogOpen] =
		useState(false);

	/**
	 * State for the Shift Details modal
	 */
	const [isShiftDetailsDialogOpen, setIsShiftDetailsDialogOpen] =
		useState(false);

	const [isShiftAttendanceDialogOpen, setIsShiftAttendanceDialogOpen] =
		useState(false);

	const [shiftIdForShiftDetailsDialog, setShiftIdForShiftDetailsDialog] =
		useState(null);

	// dialog for past shift

	const [isPastShiftDialogOpen, setIsPastShiftDialogOpen] = useState(false);

	// markattendance dialog

	const [isMarkAttendanceDialogOpen, setIsMarkAttendanceDialogOpen] =
		useState(false);

	return [
		{
			isShiftDetailsDialogOpen,
			isCreateNewShiftDialogOpen,
			isCreateWeekShiftDialogOpen,
			isShiftAttendanceDialogOpen,
			shiftIdForShiftDetailsDialog,
			isPastShiftDialogOpen,
			isMarkAttendanceDialogOpen,
		},
		{
			setIsCreateNewShiftDialogOpen,
			setIsCreateWeekShiftDialogOpen,
			setIsShiftDetailsDialogOpen,
			setIsShiftAttendanceDialogOpen,
			setShiftIdForShiftDetailsDialog,
			setIsPastShiftDialogOpen,
			setIsMarkAttendanceDialogOpen,
		},
	];
};

export default useSchedulerDialog;
