/* eslint-disable no-mixed-spaces-and-tabs */
import { addHours, format } from "date-fns";
import addDays from "date-fns/addDays";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { createNewTeam, editTeam } from "../../../../../store/gat-admin-slice";
import { Schedule } from "../../../../../store/gat-admin-slice-types";
import {
	Schedule as LocalSchedule,
	ShiftPatternAPIProps,
} from "../../../../teams/types";
import {
	MAXIMUM_SHIFT_PATTERN_DAYS,
	MINIMUM_SHIFT_PATTERN_DAYS,
} from "../../../constants";
import useTeamsData from "../../../controllers/use-teams-data";
import ShiftPattern from "../../shift-pattern";

interface TeamFormProps {
	closeTeamsDialog: () => void;
	isEdit: boolean;
	teamData: any;
	setIsValid: (isValid: boolean) => void;
	setIsSubmitting: (isSubmitting: boolean) => void;
	setDirty: (dirty: boolean) => void;
	setStatus: (status: any) => void;
	teamFormRef: any;
	setScheduleEnabledAndEmpty: (value: boolean) => void;
}

interface TeamPayloadProps {
	id?: null | number;
	name: string;
}

interface TeamFormValuesProps {
	name: string;
	numberOfDays: number;
	startDate: undefined | Date;
	endDate: undefined | Date;
	schedule: LocalSchedule[];
	isActive: boolean;
	shiftPatternId: number;
	isWorkerAssigned: boolean;
}

const TeamForm: React.FC<TeamFormProps> = ({
	isEdit,
	teamData,
	setIsValid,
	setIsSubmitting,
	setDirty,
	setStatus,
	teamFormRef,
	setScheduleEnabledAndEmpty,
}) => {
	const dispatch = useDispatch();
	const { locationId, companyId, createNewTeamStatus, editTeamStatus } =
		useTeamsData();

	const convertToSchedule = (schedules: Schedule[]): LocalSchedule[] => {
		const returnValue: LocalSchedule[] = [];
		try {
			schedules.forEach((sType: Schedule) => {
				returnValue.push({
					dayNo: sType.dayNo,
					shiftTypeId: sType.shiftType.id,
					colorCode: sType.shiftType.colorCode,
				});
			});
			return returnValue;
		} catch (e) {
			return returnValue;
		}
	};

	let initialValues: TeamFormValuesProps = {
		name: "",
		numberOfDays: 7,
		startDate: new Date(),
		endDate: undefined,
		schedule: [],
		isActive: true,
		shiftPatternId: 0,
		isWorkerAssigned: false,
	};

	if (isEdit && teamData) {
		initialValues = {
			name: teamData.name,
			numberOfDays: teamData.numberOfDays ? Number(teamData.numberOfDays) : 7,
			startDate: teamData
				? teamData.startDate
					? new Date(teamData.startDate)
					: new Date(addHours(new Date(), 0))
				: new Date(addHours(new Date(), 0)),

			endDate: teamData
				? teamData.endDate
					? new Date(teamData.endDate)
					: undefined
				: undefined,
			schedule: teamData.schedule ? convertToSchedule(teamData.schedule) : [],
			isActive: !!teamData.isActive,
			shiftPatternId: teamData.id,
			isWorkerAssigned: !!teamData.hasWorkersAssigned,
		};
	}

	return (
		<Formik
			innerRef={teamFormRef}
			initialValues={initialValues}
			validationSchema={Yup.object({
				name: Yup.string().required("Required"),
				startDate: Yup.date()
					.nullable()
					.required("Required")
					.test("is-not-cleared", "Required", (value) => value !== null)
					.test(
						"is-not-past-date",
						"Past date cannot be entered. Choose another start date.",
						function (value) {
							if (!isEdit && value) {
								return value >= new Date(format(new Date(), "yyyy-MM-dd"));
							}
							return true;
						}
					),

				endDate: Yup.date()
					.nullable()
					.test("is-after-start-date", function (endDate) {
						const { startDate, numberOfDays } = this.parent;
						if (startDate && endDate) {
							const minEndDate = addDays(new Date(startDate), numberOfDays);
							if (new Date(endDate) <= new Date(startDate)) {
								return this.createError({
									path: this.path,
									message: "End date must be after start date.",
								});
							}
							if (new Date(endDate) < minEndDate) {
								return this.createError({
									path: this.path,
									message: `End date must be at least ${numberOfDays} days after start date.`,
								});
							}
						}
						return true;
					}),
				numberOfDays: Yup.number()
					.required("Required")
					.min(
						MINIMUM_SHIFT_PATTERN_DAYS,
						`Minimum ${MINIMUM_SHIFT_PATTERN_DAYS} days`
					)
					.max(
						MAXIMUM_SHIFT_PATTERN_DAYS,
						`Maximum ${MAXIMUM_SHIFT_PATTERN_DAYS} days`
					),
			})}
			onSubmit={(values: any, { setSubmitting }) => {
				let shiftPatternObject: ShiftPatternAPIProps = {
					companyId: companyId,
					locationId: locationId,
					numberOfDays: values.numberOfDays,
					startDate: format(new Date(values.startDate), "yyyy-MM-dd"),
					endDate: values.endDate
						? format(new Date(values.endDate), "yyyy-MM-dd")
						: null,
					schedule: values.schedule.filter((s: Schedule) => {
						return s.shiftTypeId !== null;
					}),
					isActive: values.isActive,
					name: values.name,
					shiftPatternId: values.id ?? values.shiftPatternId ?? 0,
				};
				if (isEdit) {
					if (teamData.shiftPattern) {
						shiftPatternObject = {
							...shiftPatternObject,
							id: teamData.shiftPattern.id ? teamData.shiftPattern.id : 0,
						};
					}
				}
				let payload: TeamPayloadProps = shiftPatternObject;
				if (isEdit) {
					payload = { ...payload, id: teamData.id };
					dispatch(editTeam(payload));
				} else dispatch(createNewTeam(payload));
				setSubmitting(false);
			}}
		>
			{({ isSubmitting, values, dirty, isValid, setFieldValue, errors }) => {
				setIsSubmitting(isSubmitting);
				setDirty(dirty);
				setIsValid(isValid);
				setStatus(isEdit ? editTeamStatus : createNewTeamStatus);
				return (
					<Form>
						<ShiftPattern
							values={values}
							setFieldValue={setFieldValue}
							errors={errors}
							setScheduleEnabledAndEmpty={setScheduleEnabledAndEmpty}
							isEdit={isEdit}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};

export default TeamForm;
