// TODO - Type 'any' needs to be fixed.
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch } from "react-redux";

import { publishDraftShiftsAsync } from "../../../../../../export/shift-scheduler";
import Button from "../../../../../design/button";
import DialogWrapper from "../../../../../design/dialog-wrapper";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import useReviewPublishShiftDialog from "./controllers/use-review-publish-shift-dialog";
import ReviewCheckbox from "./review-checkbox";
import ReviewPublishingShiftsTable from "./review-publish-shifts-table";
import { columns, useStyles } from "./utils";

type ReviewPublishingShiftsDialogProps = {
	open: boolean;
	handleClose: () => void;
};

export default function ReviewPublishingShiftsDialog({
	open,
	handleClose,
}: ReviewPublishingShiftsDialogProps) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const {
		publishDraftShiftsAsyncErrorMessage,
		publishDraftShiftsAsyncStatus,
		checkAllowedToPublishData,
		checkAllowedToPublishStatus,
	} = useSchedulerData();

	const [
		{ state, rows, amISure, timeLeftByJobs },
		{ handleShiftSelection, setAmISure },
	] = useReviewPublishShiftDialog();

	const isStateEmpty = state ? state.length < 1 : true;
	const tableData = rows ? rows : [];
	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth={false}
		>
			<DialogWrapper
				dialogTitle='Review Publishing Shifts'
				dialogSubTitle='Select which shifts should be published. Workers will be notified.'
				dialogContent={
					<ReviewPublishingShiftsTable
						rows={tableData}
						columns={columns}
						handleShiftSelection={handleShiftSelection}
						checkAllowedToPublishData={checkAllowedToPublishData}
						checkAllowedToPublishStatus={checkAllowedToPublishStatus}
						selectedShifts={state}
						timeLeftByJobs={timeLeftByJobs}
					/>
				}
				actionButtons={
					<>
						<ReviewCheckbox amISure={amISure} setAmISure={setAmISure} />
						{publishDraftShiftsAsyncErrorMessage ? (
							<Typography color='secondary'>
								{publishDraftShiftsAsyncErrorMessage}.
							</Typography>
						) : null}
						<Button
							color='primary'
							variant='text'
							onClick={handleClose}
							customStyles={{ marginRight: "16px" }}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								dispatch(publishDraftShiftsAsync(state));
							}}
							color='primary'
							variant='contained'
							disabled={
								publishDraftShiftsAsyncStatus === "pending" ||
								publishDraftShiftsAsyncStatus === "error" ||
								amISure === false ||
								isStateEmpty
							}
						>
							{publishDraftShiftsAsyncStatus === "pending" ? (
								<div className={classes.btnLabel}>
									<CircularProgress
										size={25}
										className={classes.progressIndicator}
									/>
									Publishing Shifts
								</div>
							) : (
								"Publish & Notify"
							)}
						</Button>
					</>
				}
			/>
		</Dialog>
	);
}
