export const SENIORITY = "SENIORITY";
export const FASTEST = "FASTEST";
export const JOB = "JOB ROLES";
export const SKILLS = "SKILLS";
export const WORKCENTER = "WORKCENTER";
export const FULL_TIME_WORKER = "FullTime";
export const PART_TIME_WORKER = "PartTime";
export const FLEX_WORKER = "FlexWorker";
export const SHIFT_DETAILS = "Shift Details";
export const ASSIGN_SHIFT = "Assign Shift";
export const ERROR = "Error";
export const WARNING = "Warning";
export const SUCCESS = "Success";

export const DUMMY_PRIORITIES_ARRAY = [
	{
		id: 0,
		code: "A",
		name: "Default",
		description: "Default Priority",
		multiplier: 1,
		hexColor: "",
		isDefault: true,
	},
];

export const DEFAULT_COLORS = {
	"A": "F2FCF5",
	"B": "FFFADE",
	"C": "FFF6F6",
	"D": "F3F9FD",
	"Z": "F2EFFF",
};