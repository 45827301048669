import { connectProps } from "@devexpress/dx-react-core";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Appointments,
  // eslint-disable-next-line sort-imports
  AppointmentTooltip,
  DayView,
  Scheduler,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { isPast } from "date-fns";
import isToday from "date-fns/isToday";
import { useState } from "react";
import React from "react";
import { useSelector } from "react-redux";

import {
  AppointmentContainer,
  AppointmentContent,
} from "../../../export/shift-scheduler";
import TooltipContent from "../../../ts/components/scheduler/ui/tooltip/tooltip-content";
import useViewport from "../../../ts/utils/use-viewport";

const useStyles = makeStyles((theme: any) => ({
  todayCell: {
    // backgroundColor: alpha(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      icon: {
        visibility: "visible",
      },
    },
    "&:focus": {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
}));

const TimeTableCell = ({
  openCreateNewShiftDialog,
  openPastShiftDialog,
  ...props
}: any) => {
  const classes = useStyles();

  const { startDate } = props;
  const date = new Date(startDate);
  const [style, setStyle] = useState<any>({ display: "none" });
  const userRoles = useSelector((state: any) => state.user.userData.userRoles);
  const fetchFilterAndShiftData = useSelector(
    (state: any) => state.supervisor.fetchFilterAndShiftData.status
  );
  const canCreateOrEditShift = fetchFilterAndShiftData === "fulfilled";

  if (isPast(date)) {
    return (
      <WeekView.TimeTableCell
        {...props}
        onClick={() => {
          if (!userRoles.includes("ReadOnly")) {
            openPastShiftDialog();
          }
        }}
        // className={classes.pastCell}
      />
    );
  }

  if (isToday(date)) {
    return (
      <WeekView.TimeTableCell
        {...props}
        className={classes.todayCell}
        onClick={() => {
          if (!userRoles.includes("ReadOnly") && canCreateOrEditShift) {
            openCreateNewShiftDialog(startDate);
          }
        }}
        onMouseEnter={(e: any) => {
          setStyle({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          });
        }}
        onMouseLeave={(e: any) => {
          setStyle({ display: "none" });
        }}
      >
        {!userRoles.includes("ReadOnly") && canCreateOrEditShift && (
          <div style={style}>
            <AddIcon />
          </div>
        )}
      </WeekView.TimeTableCell>
    );
  }
  return (
    <WeekView.TimeTableCell
      {...props}
      onClick={() => {
        if (!userRoles.includes("ReadOnly") && canCreateOrEditShift) {
          openCreateNewShiftDialog(startDate);
        }
      }}
      onMouseEnter={(e: any) => {
        setStyle({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        });
      }}
      onMouseLeave={(e: any) => {
        setStyle({ display: "none" });
      }}
    >
      {!userRoles.includes("ReadOnly") && canCreateOrEditShift && (
        <div style={style}>
          <AddIcon />
        </div>
      )}
    </WeekView.TimeTableCell>
  );
};

const DayScaleCell = (props: any) => {
  const classes = useStyles();
  const { today } = props;

  if (today) {
    return <WeekView.DayScaleCell {...props} className={classes.today} />;
  }
  return <WeekView.DayScaleCell {...props} />;
};

export default function MyScheduler({
  openCreateNewShiftDialog,
  openPastShiftDialog,
  openShiftDetailsDialog,
  setShiftIdForShiftDetailsDialog,
  openShiftAttendanceDialog,
  changeWeekAsyncStatus,
  changeDayAsyncStatus,
  height,
  filterAccordionHeight,
}: any) {
  const { width } = useViewport();
  const appointments = useSelector(
    (state: any) => state.supervisor?.schedulerData?.filteredShiftsData
  );

  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );

  const isWeekView = useSelector((state: any) => state.supervisor.weekView);

  const [visible, setVisible] = useState(false);

  const toggleVisibility = (val: any) => {
    setVisible(val);
  };

  // console.log("isAppointmentTooltipOpen",visible)

  const TimeTableCellWithProps = connectProps(TimeTableCell, () => {
    return {
      openCreateNewShiftDialog,
      openPastShiftDialog,
    };
  });

  const TooltipContentWithProps = connectProps(TooltipContent, () => {
    return {
      setShiftIdForShiftDetailsDialog,
      openShiftDetailsDialog,
      openShiftAttendanceDialog,
      toggleVisibility,
    };
  });

  if (
    changeWeekAsyncStatus === "pending" ||
    changeDayAsyncStatus === "pending"
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight:
            filterAccordionHeight > height
              ? `${filterAccordionHeight}px`
              : `${height - 12}px`,
          flex: 1,
        }}
      >
        <CircularProgress />
        <Box mt={1}>
          <Typography>Loading...</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: `${filterAccordionHeight}px`,
        flex: 1,
      }}
    >
      {appointments && currentDate && (
        <Scheduler
          data={appointments}
          firstDayOfWeek={1}
          height={filterAccordionHeight - 12}
        >
          <ViewState currentDate={currentDate} />
          {width < 600 ? (
            <DayView
              timeTableCellComponent={TimeTableCellWithProps}
              dayScaleCellComponent={DayScaleCell}
              cellDuration={60}
            />
          ) : isWeekView ? (
            <WeekView
              timeTableCellComponent={TimeTableCellWithProps}
              dayScaleCellComponent={DayScaleCell}
              cellDuration={60}
            />
          ) : (
            <DayView
              timeTableCellComponent={TimeTableCellWithProps}
              dayScaleCellComponent={DayScaleCell}
              cellDuration={60}
            />
          )}

          <Appointments
            appointmentComponent={AppointmentContainer}
            appointmentContentComponent={AppointmentContent}
          />
          <AppointmentTooltip
            contentComponent={TooltipContentWithProps}
            visible={visible}
            onVisibilityChange={toggleVisibility}
          />
        </Scheduler>
      )}
    </Box>
  );
}
