/* eslint-disable no-mixed-spaces-and-tabs */
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

import Button from "../../../../../design/button";
import useSchedulerData from "../../../controllers/use-scheduler-data";

type ActionButtonsProps = {
	handleClose: (() => void) | undefined;
	isValid: boolean;
	dirty: boolean;
	isSubmitting: boolean;
	isShiftSwap?: boolean;
	isDisabled?: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
	handleClose,
	isValid,
	dirty,
	isSubmitting,
	isShiftSwap,
	isDisabled,
}) => {
	const { createDraftShiftAsyncErrorMessage, userRoles } = useSchedulerData();

	return (
		<Box
			display='flex'
			mt={isShiftSwap ? 4 : 6}
			width='100%'
			justifyContent='flex-end'
			alignItems='center'
		>
			{createDraftShiftAsyncErrorMessage ? (
				<Typography color='secondary'>
					{createDraftShiftAsyncErrorMessage}
				</Typography>
			) : null}
			<Box ml={2} display='flex' justifyContent='space-between' width={"220px"}>
				<Button color='primary' variant='text' onClick={handleClose}>
					Cancel
				</Button>
				<Button
					color='primary'
					type='submit'
					disabled={
						(Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false)
							? true
							: !isValid || !dirty || isSubmitting || !isDisabled
					}
				>
					{isSubmitting ? (
						<div style={{ position: "relative" }}>
							<CircularProgress
								size={25}
								style={{ position: "absolute", left: "50%" }}
							/>{" "}
							{isShiftSwap ? "Approving" : "Creating Shift"}
						</div>
					) : isShiftSwap ? (
						"Approve"
					) : (
						"Create Shift"
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default ActionButtons;
