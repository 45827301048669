// TODO - Type 'any' needs to be fixed.
import { addDays } from "date-fns";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";

import {WorkCenter} from "../../../utils/types";
import {JOB_ROLES, LOCATIONS, PUBLISHED_BY, SHIFT_BY, SKILLS, STATUS, WORK_CENTERS} from "../../filters/constants";
import {AppliedFilter} from "../../filters/filter-context";
import { resetMaxWorkersByLocationJob } from "../../jobs/store/jobs-slice";
import {ICompanyUser} from "../../workcenters/types";
import { jobReducer, skillReducer } from "../reducer";
import {
	fetchFilterAndShiftData,
	// fetchInitialData,
	resetAssignWorkers,
	resetBroadcastMessageState,
	resetCreateDraftShiftAsyncState,
	resetDeleteDraftShiftAsync,
	resetRenotifyWorkers,
} from "../store/scheduler-slice";
import {
	Job,
	// Shift,
	SkillData
} from "../store/types";
import useSelectorData from "./use-scheduler-data";
import useSchedulerDialog from "./use-scheduler-dialog";

type Filter = {
	id: number;
	name: string;
	data: any[];
	reducedData: any[];
	searchedData: any[];
};

const UseSchedulerController = (companyId: number) => {
	const dispatch = useDispatch();
	const [state, dispatchLocal] = useReducer(jobReducer, {});
	const [filters, setFilters] = useState<Filter[]>([]);
	const [skillState, dispatchLocalSkill] = useReducer(skillReducer, {});
	const [defaultAppliedFilters, setDefaultAppliedFilters] = useState<AppliedFilter[]>([]);
	const {
		shiftsData,
		filterAllLocations,
		filterAllWorkcenters,
		filterJobRoles,
		filterAllSkills,
		filterAllManagers,
		fetchFilterAndShiftDataStatus,
	} = useSelectorData();
	const [
		{
			isShiftDetailsDialogOpen,
			isCreateNewShiftDialogOpen,
			isCreateWeekShiftDialogOpen,
			isShiftAttendanceDialogOpen,
			shiftIdForShiftDetailsDialog,
			isPastShiftDialogOpen,
			isMarkAttendanceDialogOpen,
		},
		{
			setIsCreateNewShiftDialogOpen,
			setIsCreateWeekShiftDialogOpen,
			setIsShiftDetailsDialogOpen,
			setIsShiftAttendanceDialogOpen,
			setShiftIdForShiftDetailsDialog,
			setIsPastShiftDialogOpen,
			setIsMarkAttendanceDialogOpen,
		},
	]: any = useSchedulerDialog();

	/**
	 * Initial state
	 */
	const [isInitSet, setIsInitSet] = useState(false);
	const [isSkillInitSet, setIsSkillInitSet] = useState(false);
	const [weekPasteDate, setWeekPasteDate] = useState<any>(null);
	const [draftShiftDate, setDraftShiftDate] = useState<any>(null);

	useEffect(() => {
		if (fetchFilterAndShiftDataStatus === "fulfilled") {
			const newFilters: Filter[] = [];
			if (filterAllLocations && filterAllLocations.length > 0) {
				newFilters.push({
					id: 1,
					name: LOCATIONS,
					data: filterAllLocations,
					reducedData: filterAllLocations,
					searchedData: filterAllLocations,
				});
			}
			if (filterAllWorkcenters && filterAllWorkcenters.length > 0) {
				newFilters.push({
					id: 2,
					name: WORK_CENTERS,
					data: filterAllWorkcenters,
					reducedData: filterAllWorkcenters,
					searchedData: filterAllWorkcenters,
				});
			}
			if (filterJobRoles && filterJobRoles.length > 0) {
				newFilters.push({
					id: 3,
					name: JOB_ROLES,
					data: filterJobRoles,
					reducedData: filterJobRoles,
					searchedData: filterJobRoles,
				});
			}
			if (filterAllSkills && filterAllSkills.length > 0) {
				newFilters.push({
					id: 4,
					name: SKILLS,
					data: filterAllSkills,
					reducedData: filterAllSkills,
					searchedData: filterAllSkills,
				});
			}
			// Shift status
			newFilters.push(
				{
					id: 5,
					name: STATUS,
					data: [
						{ id: 1, name: "Draft" },
						{ id: 2, name: "Published" },
					],
					reducedData: [
						{ id: 1, name: "Draft" },
						{ id: 2, name: "Published" },
					],
					searchedData: [
						{ id: 1, name: "Draft" },
						{ id: 2, name: "Published" },
					],
				},
			);
			// Shift By Job or Skills
			newFilters.push(
				{
					id: 6,
					name: SHIFT_BY,
					data: [
						{ id: 1, name: "Job" },
						{ id: 2, name: "Skills" },
					],
					reducedData: [
						{ id: 1, name: "Job" },
						{ id: 2, name: "Skills" },
					],
					searchedData: [
						{ id: 1, name: "Job" },
						{ id: 2, name: "Skills" },
					],
				},
			);

			const filterUsersByWorkCenters = (
				users: ICompanyUser[],
				workCenters: WorkCenter[]
			): ICompanyUser[] => {
				const workCenterIds = new Set(workCenters.map((wc) => wc.id));

				// TODO: API not returning workcenter or location of user, update when data is available via API
				// Do not filter if userWorkCenters do not exists
				return users.filter((user) =>
					user.userWorkCenters ? user.userWorkCenters.some((wc) => workCenterIds.has(wc.id)) : true
				);
			};

			const managers = filterUsersByWorkCenters(filterAllManagers, filterAllWorkcenters);
			// Shift created by
			newFilters.push(
				{
					id: 7,
					name: PUBLISHED_BY,
					data: managers,
					reducedData: managers,
					searchedData: managers,
				},
			);
			setFilters(newFilters);
		}
	},[
		fetchFilterAndShiftDataStatus,
		filterAllLocations,
		filterAllWorkcenters,
		filterJobRoles,
		filterAllManagers,
		filterAllSkills,
	]);

	useEffect(() => {
		if (filterAllSkills?.length > 0) {
			const cb: any = {};
			filterAllSkills.forEach((skill: SkillData) => {
				cb[skill.id] = false;
			});
			if (!isSkillInitSet) {
				dispatchLocalSkill({ type: "setInit", payload: cb });
				setIsSkillInitSet(true);
			}
		}
	}, [filterAllSkills, isSkillInitSet]);

	useEffect(() => {
		if (filterJobRoles?.length > 0) {
			const cb: any = {};
			filterJobRoles.forEach((job: Job) => {
				cb[job.id] = false;
			});
			if (!isInitSet) {
				dispatchLocal({ type: "setInit", payload: cb });
				setIsInitSet(true);
			}
		}
	}, [filterJobRoles, isInitSet]);

	useEffect(() => {
		if (dispatch && fetchFilterAndShiftDataStatus === "idle") {
			dispatch(fetchFilterAndShiftData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (dispatch && fetchFilterAndShiftDataStatus === "fulfilled") {
	// 		dispatch(fetchInitialData());
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// },[fetchFilterAndShiftDataStatus]);

	/**
	 * Toggle functions for the Create New Shift modal
	 * */
	const openCreateNewShiftDialog = (date: any) => {
		setDraftShiftDate(date);
		dispatch(resetCreateDraftShiftAsyncState());
		dispatch(resetMaxWorkersByLocationJob());
		setIsCreateNewShiftDialogOpen(true);
	};

	const closeCreateNewShiftDialog = () => {
		setIsCreateNewShiftDialogOpen(false);
	};

	/**
	 *  Toggle functions for the Create new week shift modal
	 */
	const openCreateWeekShiftDialog = (date: any) => {
		const start = addDays(startOfWeek(date), 1);
		const end = addDays(endOfWeek(date), 1);
		setDraftShiftDate(start);
		setWeekPasteDate({ startDate: start, endDate: end });
		setIsCreateWeekShiftDialogOpen(true);
	};

	const closeCreateWeekShiftDialog = () => {
		setIsCreateWeekShiftDialogOpen(false);
	};

	const openPastShiftDialog = () => {
		setIsPastShiftDialogOpen(true);
	};

	const closePastShiftDialog = () => {
		setIsPastShiftDialogOpen(false);
	};

	const openMarkAttendanceDialog = () => {
		setIsMarkAttendanceDialogOpen(true);
	};

	const closeMarkAttendanceDialog = () => {
		setIsMarkAttendanceDialogOpen(false);
	};

	return [
		{
			isShiftDetailsDialogOpen,
			isShiftAttendanceDialogOpen,
			isCreateWeekShiftDialogOpen,
			shiftIdForShiftDetailsDialog,
			isCreateNewShiftDialogOpen,
			isPastShiftDialogOpen,
			isMarkAttendanceDialogOpen,
			weekPasteDate,
			draftShiftDate,
			state,
			skillState,
			isInitSet,
			isSkillInitSet,
			filters,
			defaultAppliedFilters,
		},
		{
			closeCreateWeekShiftDialog,
			openCreateWeekShiftDialog,
			openPastShiftDialog,
			closePastShiftDialog,
			openCreateNewShiftDialog,
			openMarkAttendanceDialog,
			closeMarkAttendanceDialog,
			setShiftIdForShiftDetailsDialog,
			setIsShiftDetailsDialogOpen,
			setIsShiftAttendanceDialogOpen,
			closeCreateNewShiftDialog,
			resetDeleteDraftShiftAsync,
			resetBroadcastMessageState,
			resetRenotifyWorkers,
			resetAssignWorkers,
			dispatchLocal,
			dispatchLocalSkill,
			setFilters,
		},
	];
};

export default UseSchedulerController;
