import axios from "axios";
import jwt_decode from "jwt-decode";

axios.interceptors.request.use(function (config) {
  // Adding authorization headers
  if (localStorage.token) {
    config.headers.Authorization = localStorage.token;
  }
  return config;
});

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest.url ===
          `${process.env.REACT_APP_API_END_POINT}/Users/Refresh`) {

        window.location.reload();
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return axios
            .post(`${process.env.REACT_APP_API_END_POINT}/Users/Refresh`, {
              refreshToken: localStorage.getItem("refreshToken"),
              token: localStorage.getItem("token"),
            })
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("refreshToken", res.data.refreshToken);
                localStorage.setItem("token", res.data.token);

                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + localStorage.getItem("token");

                return axios(originalRequest);
              }
            })
            .catch((error) => {
              console.error(error);
              if (
                  localStorage.getItem("refreshToken") &&
                  localStorage.getItem("token")
              ) {

                const token = localStorage.getItem("token");
                const { exp } = jwt_decode(token);

                if (Date.now() / 1000 < exp) {
                  // console.log("EXISTING TOKEN VALID POST TEAPOT");

                  axios.defaults.headers.common["Authorization"] =
                      "Bearer " + token;
                  return axios(originalRequest);
                } else {
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("token");
                  return Promise.reject(error);
                }
              } else {
                Promise.reject(error).then(r => {console.log(r)});
              }
              // TODO: Uncomment to patch the 500 issue
              // localStorage.removeItem("refreshToken");
              // localStorage.removeItem("token");
            });
      }
      return Promise.reject(error);
    }
);

export default axios;
