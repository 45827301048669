/* eslint-disable no-mixed-spaces-and-tabs */
import { tr } from "date-fns/locale";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";

import { mergeObjects } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import { AllocationSettings } from "../../../../store/gat-admin-slice-types";

type Rule = {
	shiftAllocationTypeId: number;
	isDefault: boolean;
	jobIds: string;
};

interface ShiftAllocationRulesProps {
	locationData: any;
	setLocationData: any;
	creatingNewLocation: boolean;
}

const useShiftAllocationConfigController = ({
	locationData,
	setLocationData,
	creatingNewLocation,
}: ShiftAllocationRulesProps) => {
	const confirm = useConfirm();
	const { shiftAllocationTypes, allJobsByLocation, allJobsByLocationObject } =
		useGatAdminConfigData();

	const [jobDialogOpen, setJobDialogOpen] = useState(-1);
	const [seniorityDialogOpen, setSeniorityDialogOpen] =
		useState<boolean>(false);
	const [workcenterDialogOpen, setWorkcenterDialogOpen] =
		useState<boolean>(false);
	const { allocationTypes, status, errorMessage } = shiftAllocationTypes;

	const [shiftAllocationRules, setShiftAllocationRules] = useState<
		AllocationSettings[]
	>(
		creatingNewLocation
			? allocationTypes.map((type: AllocationSettings) => ({
					...type,
					shiftAllocationTypeId: type.shiftAllocationTypeId,
					jobIds: null,
					locationId: locationData.id,
					defaultWindowSizeMins: 0,
					urgentWindowSizeMins: 0,
					tieBreakerProp: null,
					id: 0,
			  }))
			: allocationTypes.map((type: AllocationSettings) => {
					const { allocationSettings } = locationData ?? [];
					const allocationSetting = allocationSettings?.find(
						(setting: { shiftAllocationTypeId: number }) =>
							setting.shiftAllocationTypeId === type.shiftAllocationTypeId
					);
					if (!allocationSetting)
						return {
							...type,
							shiftAllocationTypeId: type.shiftAllocationTypeId,
							jobIds: null,
							locationId: locationData.id,
							defaultWindowSizeMins: 0,
							urgentWindowSizeMins: 0,
							tieBreakerProp: null,
							id: 0,
						};
					const val = mergeObjects(type, {
						...allocationSetting,
						id: allocationSetting.id,
					});
					return { ...val, locationId: locationData.id };
			  })
	);

	const openWorkcenterDialog = () => {
		setWorkcenterDialogOpen(true);
	};

	const closeWorkcenterDialog = () => {
		setWorkcenterDialogOpen(false);
	};

	const openSeniorityDialog = () => {
		setSeniorityDialogOpen(true);
	};

	const closeSeniorityDialog = () => {
		setSeniorityDialogOpen(false);
	};

	const handleDefaultChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		name: string
	) => {
		const { value } = event.target;
		confirm({
			title: "Are you sure?",
			description: "",
			content: `Are you sure you want to set the default shift allocation rules to ${name} ?`,
		})
			.then(() => {
				const newRules = shiftAllocationRules.map(
					(rule: AllocationSettings) => {
						if (rule.shiftAllocationTypeId === Number(value)) {
							return {
								...rule,
								isDefault: true,
							};
						}
						return {
							...rule,
							isDefault: false,
						};
					}
				);
				setShiftAllocationRules(newRules);
				setLocationData({
					...locationData,
					allocationSettings: newRules,
				});
			})
			.catch(() => {
				/* ... */
			});
	};

	const handleJobRemove = (shiftAllocationTypeId: number, jobId: string) => {
		const newRules = shiftAllocationRules.map((rule: AllocationSettings) => {
			if (rule.shiftAllocationTypeId === shiftAllocationTypeId) {
				return {
					...rule,
					jobIds: rule.jobIds
						.split(",")
						.filter((id) => id !== jobId)
						.join(","),
				};
			}
			return rule;
		});
		setShiftAllocationRules(newRules);
		setLocationData({
			...locationData,
			allocationSettings: newRules,
		});
	};

	return [
		{
			allocationTypes,
			jobDialogOpen,
			seniorityDialogOpen,
			status,
			errorMessage,
			shiftAllocationRules,
			allJobsByLocation,
			allJobsByLocationObject,
			workcenterDialogOpen,
		},
		{
			handleDefaultChange,
			setJobDialogOpen,
			setSeniorityDialogOpen,
			setShiftAllocationRules,
			handleJobRemove,
			openWorkcenterDialog,
			closeWorkcenterDialog,
			openSeniorityDialog,
			closeSeniorityDialog,
		},
	];
};

export default useShiftAllocationConfigController;
