import { differenceInHours, format, parseISO } from "date-fns";
import addDays from "date-fns/addDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import startOfWeek from "date-fns/startOfWeek";
import dayjs from "dayjs";
import { parsePhoneNumber } from "libphonenumber-js";
import { CountryCode, PhoneNumber } from "libphonenumber-js/types"; // Type definitions for libphonenumber-js
import { useEffect, useRef } from "react";

import { DUMMY_PRIORITIES_ARRAY } from "../assets/constants";
import { SkillLevel } from "../components/cross-skilling/types";
import { Job } from "../components/scheduler/store/types";
import { Skill } from "../components/workers/types";
import {
	ALPHABET,
	BOX,
	CIRCLE,
	DONUT,
	EMPTY,
	FULL,
	HALF,
	NUMBER,
	QUARTER,
	THREE_QUARTER,
} from "../design/skill-representaion-cell/constants/constants";
import { CompanyLocation, Location, Priority, WorkCenter } from "./types";

export const calculateShiftHoursAndMinutes = (
	startTime: string,
	endTime: string,
	numberOfWorkersNeeded: string | number
) => {
	let diffInMins = differenceInMinutes(new Date(endTime), new Date(startTime));

	diffInMins *= Number(numberOfWorkersNeeded);

	const hours = Math.floor(diffInMins / 60);

	const minutes = diffInMins % 60;

	return { hours, minutes };
};

export const mergeObjects = (obj1: any, obj2: any) => {
	const merged = { ...obj1 };
	// Loop through the keys of the second object
	for (const key in obj2) {
		if (Object.prototype.hasOwnProperty.call(obj2, key)) {
			if (Object.prototype.hasOwnProperty.call(merged, key)) {
				merged[key] = obj2[key];
			} else {
				merged[key] = obj2[key];
			}
		}
	}

	return merged;
};

export const getDuration = (
	startTime: string,
	endTime: string,
	unpaidBreakMins: number
): string => {
	const [hours1, minutes1] = startTime.split(":").map(Number);
	const [hours2, minutes2] = endTime.split(":").map(Number);

	const date1 = new Date(0, 0, 0, hours1, minutes1);
	const date2 = new Date(0, 0, 0, hours2, minutes2);

	if (date2 < date1) {
		date2.setDate(date2.getDate() + 1);
	}

	const differenceInMinutes = (date2.getTime() - date1.getTime()) / (1000 * 60);

	const hours = (differenceInMinutes - unpaidBreakMins) / 60;
	const finalHours = hours.toFixed(2);

	return finalHours;
};

export const createArrayUpToNumber = (n: number): number[] => {
	return Array.from({ length: n }, (_, index) => index + 1);
};

export const isTooDark = (color: string): boolean => {
	let r: number, g: number, b: number;
	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		const match = color.match(
			/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
		);

		if (match) {
			r = parseInt(match[1]);
			g = parseInt(match[2]);
			b = parseInt(match[3]);
		} else {
			throw new Error("Invalid RGB color format");
		}
	} else {
		// If RGB --> Convert it to HEX
		const shouldReplace = color.length < 5;
		let hexColor = 0;

		if (shouldReplace) {
			hexColor = +("0x" + color.slice(1).replace(/./g, "$&$&"));
		} else {
			hexColor = +("0x" + color.slice(1));
		}

		r = hexColor >> 16;
		g = (hexColor >> 8) & 255;
		b = hexColor & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	const hsp: number = Math.sqrt(
		0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
	);

	// Using the HSP value, determine whether the color is light or dark
	return hsp < 139.5;
};

export const getRepType = (code: string | undefined) => {
	try {
		const codeArr = code ? code.split("-") : [];
		let returnValue = DONUT;
		if (codeArr.length > 0) {
			switch (codeArr[0]) {
				case CIRCLE:
					returnValue = CIRCLE;
					break;
				case BOX:
					returnValue = BOX;
					break;
				case ALPHABET:
					returnValue = ALPHABET;
					break;
				case NUMBER:
					returnValue = NUMBER;
					break;
				default:
					break;
			}
			return returnValue;
		} else {
			return DONUT;
		}
	} catch (e) {
		return DONUT;
	}
};
export const getRepLevel = (code: string | undefined, level = 1) => {
	try {
		let checkLevel: string | number = level;
		let returnValue = EMPTY;
		if (code) {
			const codeArr = code ? code.split("-") : [];
			if (codeArr.length > 2) {
				// To handle three-quarter string
				checkLevel = `${codeArr[1]}-${codeArr[2]}`;
			} else if (codeArr.length > 0) {
				checkLevel = codeArr[1];
			}
		}
		switch (checkLevel) {
			case 1:
			case QUARTER:
				returnValue = QUARTER;
				break;
			case 2:
			case HALF:
				returnValue = HALF;
				break;
			case 3:
			case THREE_QUARTER:
				returnValue = THREE_QUARTER;
				break;
			case 4:
			case FULL:
				returnValue = FULL;
				break;
			default:
				break;
		}
		return returnValue;
	} catch (e) {
		return EMPTY;
	}
};

export const getTotalWorkersCount = (
	workersCount: number,
	outOfWorkersCount: number
): number => {
	let returnValue = outOfWorkersCount ?? 0;
	try {
		if (workersCount) {
			if (workersCount > outOfWorkersCount) {
				returnValue = workersCount;
			}
		}
		return returnValue;
	} catch (e) {
		return returnValue;
	}
};

export const getHourMinuteDifference = (
	startDate: Date,
	endDate: Date
): {
	differenceInText: string;
	differenceInMinutes: number;
} => {
	const returnValue = {
		differenceInText: "0 hour",
		differenceInMinutes: 0,
	};
	if (startDate.getTime() - endDate.getTime() <= 0) {
		return returnValue;
	}
	const timeDifference = Math.abs(startDate.getTime() - endDate.getTime());
	returnValue.differenceInMinutes = Math.ceil(timeDifference / (1000 * 60));

	const hours = Math.floor(timeDifference / (1000 * 60 * 60));
	const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

	if (hours === 0) {
		returnValue.differenceInText = `${minutes} minute${
			minutes !== 1 ? "s" : ""
		}`;
	} else {
		if (minutes === 0) {
			returnValue.differenceInText = `${hours} hour${hours !== 1 ? "s" : ""}`;
		} else {
			returnValue.differenceInText = `${hours} hour${
				hours !== 1 ? "s" : ""
			} ${minutes} minute${minutes !== 1 ? "s" : ""}`;
		}
	}

	return returnValue;
};

export default function isValidPhoneNumberForCountry(
	phoneNumberString: string,
	country: string
): boolean {
	try {
		if (phoneNumberString.length > 2) {
			const phoneNumber: PhoneNumber = parsePhoneNumber(phoneNumberString, {
				defaultCountry: country as CountryCode,
				extract: false,
			});
			if (!phoneNumber) {
				return false;
			}
			if (phoneNumber.country !== country) {
				return false;
			}
			return phoneNumber.isValid();
		} else {
			return true;
		}
	} catch (error) {
		return true;
	}
}

export const deCamelCase = (text: string): string => {
	try {
		const result = text.replace(/([A-Z])/g, " $1");
		return result.charAt(0).toUpperCase() + result.slice(1);
	} catch (e) {
		return text;
	}
};

export const capitalizeFirstLetter = (
	value: string,
	ofFullSentence = false
) => {
	let returnValue = value;
	if (ofFullSentence) {
		const valueArray = value.toLowerCase().split(" ");
		const newValueArray = valueArray.map((word) => {
			return word.charAt(0).toUpperCase() + word.slice(1);
		});
		returnValue = newValueArray.join(" ");
	} else {
		returnValue = value.charAt(0).toUpperCase() + value.slice(1);
	}
	return returnValue;
};

export const getLevelObj = (value = "0", allSkillLevels: SkillLevel[] = []) => {
	// Based on skill levels provided return info about level based on level value
	const returnValue = {
		repType: DONUT,
		repLevel: EMPTY,
		levelValue: value,
		backgroundColor: "",
		foregroundColor: "",
		name: "",
	};

	const level = allSkillLevels.find(
		(sLevel) => sLevel.level === parseInt(value)
	);

	if (level) {
		returnValue.repType = getRepType(level.repCode);
		returnValue.repLevel = getRepLevel(level.repCode, level.level);
		returnValue.levelValue = String(level.value);
		returnValue.backgroundColor = level.backColorCode;
		returnValue.foregroundColor = level.foreColorCode;
		returnValue.name = level.name;
	}

	return returnValue;
};

export const getTimeForTimePicker = (newValue: any) => {
	try {
		let UTCDateString = JSON.stringify(newValue);
		UTCDateString = UTCDateString.slice(1, UTCDateString.length - 1);
		const newLocalDate = new Date(UTCDateString);
		let hours = `${newLocalDate.getHours()}`;
		let minutes = `${newLocalDate.getMinutes()}`;
		let seconds = `${newLocalDate.getSeconds()}`;
		if (hours.length === 1) {
			hours = `0${hours}`;
		}
		if (minutes.length === 1) {
			minutes = `0${minutes}`;
		}
		if (seconds.length === 1) {
			seconds = `0${seconds}`;
		}
		return `${hours}:${minutes}`;
	} catch (e) {
		// Do nothing
		return "08:00";
	}
};

export const useIsComponentMounted = () => {
	const isComponentMounted = useRef(false);

	useEffect(() => {
		isComponentMounted.current = true;
		return () => {
			isComponentMounted.current = false;
		};
	}, []);

	return isComponentMounted;
};

export const addId = (arr: any) =>
	arr.map((obj: any, index: number) => ({ ...obj, idx: index + 1 }));

export const locationOfWorkcenter = (
	workcenterId: number,
	locations: CompanyLocation[]
) => {
	try {
		const location = locations.find((location) =>
			location.workCenters.some((wc) => wc.id === workcenterId)
		);
		return location ? location.name : "";
	} catch (e) {
		return "";
	}
};

export const addSkillsToShifts = (allShiftData: any) => {
	try {
		// Shift by skills related changes
		// appending skills array object to shift
		const shiftsData: any = [];
		const shiftsBySkills = allShiftData.skills ?? [];
		const allShiftsArray = allShiftData.signupTracking ?? [];
		allShiftsArray.forEach((shiftObject: any) => {
			shiftsData.push({
				...shiftObject,
				skills: shiftsBySkills.filter(
					(shiftBySkill: any) => shiftBySkill.shiftId === shiftObject.id
				),
				shiftSkills: shiftsBySkills.filter(
					(shiftBySkill: any) => shiftBySkill.shiftId === shiftObject.id
				),
			});
		});
		return shiftsData;
	} catch (e) {
		return [];
	}
};

//Add date time as per the api specification to a bunch of shifts in an array
export const addStartDateEndDate = (allShiftsData: any) => {
	if (allShiftsData) {
		return allShiftsData.map((shift: any) => {
			/*
      TODO: To activate timezones
      let startDate = `${shift.startDateTime}.000Z`;
      let endDate = `${shift.endDateTime}.000Z`;
      */
			const startDate = shift.startDateTime;
			const endDate = shift.endDateTime;
			shift.startDate = startDate;
			shift.endDate = endDate;
			return shift;
		});
	} else {
		return [];
	}
};

// Add date time as per the api specification to a single shifts
export const addStartDateEndDateToAShift = (shift: any) => {
	const startDate = shift.startDateTime;
	const endDate = shift.endDateTime;
	shift.startDate = startDate;
	shift.endDate = endDate;
	return shift;
};

export const generateDaysOfTheWeek = (seedDate: any) => {
	const monday: any = startOfWeek(seedDate, {
		weekStartsOn: 1,
	});
	const tuesday = addDays(monday, 1);
	const wednesday = addDays(monday, 2);
	const thursday = addDays(monday, 3);
	const friday = addDays(monday, 4);
	const saturday = addDays(monday, 5);
	const sunday = addDays(monday, 6);

	return {
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
		sunday,
	};
};

export const generateDaysOfTheWeekSerialized = (seedDate: any) => {
	const daysOfTheWeek: any = generateDaysOfTheWeek(seedDate);
	return Object.keys(daysOfTheWeek).reduce((acc: any, key) => {
		acc[key] = daysOfTheWeek[key].toISOString();
		return acc;
	}, {});
};

type DateFormat =
	| "DATE_RANGE"
	| "SHORT_DATE"
	| "NUMERIC_DATE"
	| "TIME"
	| "TIME_RANGE"
	| "DATE_WITH_TIME";

export const formatDate = ({
	dateStr,
	endDateStr,
	formatType,
	ISOString = true,
}: {
	dateStr: string;
	endDateStr?: string;
	formatType: DateFormat;
	ISOString?: boolean;
}): string => {
	let formattedDate = "";

	if (!ISOString) {
		// Using dayjs for date formatting
		switch (formatType) {
			case "NUMERIC_DATE": {
				formattedDate = dayjs(dateStr).format("MM/DD/YYYY");
				break;
			}
		}
	} else {
		switch (formatType) {
			case "DATE_RANGE": {
				if (!endDateStr) {
					throw new Error("End date is required for DATE_RANGE format");
				}
				formattedDate = `${format(new Date(dateStr), "d MMM yyyy")} - ${format(
					new Date(endDateStr),
					"d MMM yyyy"
				)}`;
				break;
			}
			case "SHORT_DATE": {
				formattedDate = format(new Date(dateStr), "EEE, d MMM");
				break;
			}

			case "NUMERIC_DATE": {
				formattedDate = format(new Date(dateStr), "MM/dd/yyyy");
				break;
			}
			case "TIME_RANGE": {
				if (!endDateStr) {
					throw new Error("End date is required for DATE_RANGE format");
				}
				const startTime = format(new Date(dateStr), "h:mm a");
				const endTime = format(new Date(endDateStr), "h:mm a");
				formattedDate = `${startTime} - ${endTime}`;
				break;
			}

			case "TIME": {
				formattedDate = format(new Date(dateStr), "h:mm a");
				break;
			}

			case "DATE_WITH_TIME": {
				formattedDate = `${format(new Date(dateStr), "EEE, d MMM h:mm a")}`;
				break;
			}

			default:
				throw new Error("Invalid format type");
		}
	}
	return formattedDate;
};

export const appendPriorityToSkills = (skills: any, priorities: Priority[]) => {
	return skills.map((skill: any) => {
		let selectedPriority = priorities.find(
			(priority) => priority.id === skill.priorityId
		);
		if (selectedPriority === undefined) {
			if (priorities.length > 0) {
				selectedPriority =
					priorities.find((priority) => priority.isDefault) ?? priorities[0];
			} else {
				selectedPriority = DUMMY_PRIORITIES_ARRAY[0];
			}
		}
		return { ...skill, priority: selectedPriority };
	});
};
export const formatSkillsByJobs = (
	allJobs: Job[],
	jobIdsSkillsArr: Skill[],
	jobIds: number[],
	filteredSkills: Skill[] = []
): void => {
	allJobs.forEach((job) => {
		if (jobIds.includes(job.id)) {
			job.skills.forEach((sk: Skill) => {
				try {
					if (filteredSkills.length > 0) {
						filteredSkills.forEach((filteredSkill) => {
							const filteredSkillId = filteredSkill.skillId ?? filteredSkill.id;
							const jobSkillId = sk.skillId ?? sk.id;
							if (filteredSkillId === jobSkillId) {
								jobIdsSkillsArr.push({
									...sk,
									jobName: job.name,
									jobColor: job.hexColor,
									jobId: job.id,
								});
							}
						});
					} else {
						jobIdsSkillsArr.push({
							...sk,
							jobName: job.name,
							jobColor: job.hexColor,
							jobId: job.id,
						});
					}
				} catch (e) {
					jobIdsSkillsArr.push({
						...sk,
						jobName: job.name,
						jobColor: job.hexColor,
						jobId: job.id,
					});
				}
			});
		}
	});
};

export const parseCalcExpression = (expression: string): number => {
	// Use a regular expression to extract values
	const regex = /(\d+)(vh|px)/g;
	let match;
	let totalPixels = 0;

	while ((match = regex.exec(expression)) !== null) {
		const value = parseFloat(match[1]);
		const unit = match[2];

		if (unit === "vh") {
			// Convert vh to pixels (assuming 100vh = window.innerHeight)
			totalPixels += (value / 100) * window.innerHeight;
		} else if (unit === "px") {
			totalPixels += value;
		}
	}

	return totalPixels;
};

export const parseNumberFromPx = (value: string): number => {
	return parseInt(value.replace("px", ""));
};

export function truncateStringToFitBox(str: string, maxWidth: number) {
	if (str.length <= maxWidth) {
		return str;
	}

	const ellipsis = "...";
	const charsToShow = maxWidth - ellipsis.length;
	const frontChars = Math.ceil(charsToShow / 2);
	const backChars = Math.floor(charsToShow / 2);

	const truncatedString =
		str.slice(0, frontChars) + ellipsis + str.slice(str.length - backChars);

	return truncatedString;
}

export const calculateLeaveHours = (
	startDateTime: string,
	endDateTime: string
) => {
	const start = parseISO(startDateTime);
	const end = parseISO(endDateTime);

	return differenceInHours(end, start);
};
