// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";

import useWorkersData from "../../../../../controllers/use-workers-data";
import { truncateFileName } from "../../../../helper";

interface workerCertFileProps {
	fileName: string;
	fileSizeKB: number;
	workerCertId: number;
	workerCertFileId: number;
}
interface FileUploadProps {
	workerCertFiles: workerCertFileProps[];
	files: UploadedFile[];
	setFiles: any;
	setFieldValue: any;
	fileUploadErrors: any;
	setFilesToDelete: any;
}

interface UploadedFile extends FileWithPath {
	id: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
	workerCertFiles,
	files,
	setFiles,
	setFieldValue,
	fileUploadErrors,
	setFilesToDelete,
}) => {
	const [rejectedFiles, setRejectedFiles] = useState<any>([]);
	const [idProvider, setIdProvider] = useState(0);
	const [maxFiles, setMaxFiles] = useState(3);
	const { postUploadFilesStatus } = useWorkersData();

	useEffect(() => {
		const maxFilesLength = 3 - (workerCertFiles.length + files.length);
		if (maxFilesLength < 0) {
			setMaxFiles(0);
		} else {
			setMaxFiles(maxFilesLength);
		}
	}, [files.length, workerCertFiles.length]);

	const getFileErrorText = (error: string) => {
		switch (error) {
			case "file-too-large":
				return "File size over 3 MB";
			case "file-invalid-type":
				return "Invalid file type";
			case "too-many-files":
				return "Maximum 3 Files";
			default:
				return "Unknown error";
		}
	};

	const onDrop = (acceptedFiles: FileWithPath[], rejectedFiles: any) => {
		setFiles((prevFiles: any) => [
			...prevFiles,
			...acceptedFiles.map((file, index) =>
				Object.assign(file, { id: `${index} ${idProvider}` })
			),
		]);
		setRejectedFiles((prevFiles: any) => [
			...prevFiles,
			...rejectedFiles.map((file: any, index: number) =>
				Object.assign(file, { id: `${index} ${idProvider}` })
			),
		]);
		setIdProvider((prev) => prev + 1);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png", ".jpeg", ".jpg"],
			"application/pdf": [".pdf", ".doc", ".docx"],
		},
		maxSize: 1024 * 3000,
		multiple: true,
		maxFiles,
		disabled: maxFiles === 0,
	});
	const removeAcceptedFile = (id: string) => {
		setFiles(files.filter((file) => file.id !== id));
	};
	const removeRejectedFile = (id: string) => {
		setRejectedFiles(rejectedFiles.filter((file: any) => file.id !== id));
	};
	const formatFileSize = (size: number) => {
		return size > 1024 * 1024
			? `${(size / (1024 * 1024)).toFixed(2)} MB`
			: `${(size / 1024).toFixed(2)} KB`;
	};

	return (
		<Box>
			<Tooltip
      title={maxFiles === 0 ? "Maximum 3 files can be uploaded" : ""}
      arrow
      disableHoverListener={maxFiles !== 0}
    >
			<Box
				{...getRootProps()}
				sx={{
					border: "1px dashed #0000001F",
					padding: "16px 0",
					textAlign: "center",
					cursor: maxFiles===0 ? "auto" :"pointer",
					marginBottom: 1,
					borderRadius: "4px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					gap: 1,
					opacity: maxFiles === 0 ? 0.5 : 1,
				}}
			>
				<input {...getInputProps()} />
				<UploadFileOutlinedIcon sx={{color: "#2F4D8B"}} />
				<Typography
					sx={{
						display: "flex",
						gap: 0.5,
						justifyContent: "center",
						alignItems: "center",
						fontFamily: "Roboto",
						fontSize: "16px",
						fontWeight: 400,
						lineHeight: "28px",
						letterSpacing: "0.15px",
					}}
				>
					<Typography
						component={"span"}
						sx={{ 
							color: "#2F4D8B",
							textDecoration: "underline",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "28px",
							letterSpacing: "0.15px",
						 }}
					>
						Click to upload
					</Typography>
					or drag and drop
				</Typography>
				<Typography variant='caption'>
					PNG, JPG, JPEG, WORD or PDF (max. 3MB)
				</Typography>
			</Box>
			</Tooltip>

			<List disablePadding sx={{
				display: "flex",
				gap: 1,
				flexDirection: "column",
			}}>
				{/* -------------------------- showing files to upload -------------------------- */}
				{files.map((file: UploadedFile) => (
					<ListItem
						key={file?.id}
						sx={{
							boxShadow:
								"0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
							borderRadius: "4px",
						}}
					>
						<UploadFileOutlinedIcon sx={{ mr: 2, color: "#2F4D8B" }} />
						<ListItemText
							primary={
								<>
								<Tooltip title={file?.name} placement='bottom-start'>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontSize: "16px",
											fontWeight: 400,
											lineHeight: "28px",
											letterSpacing: "0.15px",
										}}
									>
										{truncateFileName(file?.name, 40)}
									</Typography>
								</Tooltip>
								</>
							}
							secondary={
								<>
									<Typography
										component={"span"}
										sx={{
											fontSize: "14px",
											fontFamily: "Roboto",
											fontWeight: 400,
											lineHeight: "20px",
											letterSpacing: "0.15px",
											color: "#00000099",
											display: "flex",
											gap: 1,
										}}
									>
										<span>{formatFileSize(file?.size)}</span>
										<span>•</span>
										<span>Complete</span>
									</Typography>
									{fileUploadErrors
										? fileUploadErrors[file.name] && (
												<Typography
													component={"span"}
													sx={{ color: "#D32F2F" }}
												>
													{fileUploadErrors[file.name]}
												</Typography>
										  )
										: null}
								</>
							}
						/>
						<ListItemSecondaryAction>
							{postUploadFilesStatus === "pending" ? (
								<Typography sx={{ display: "flex" }}>
									<CircularProgress size={25} />
								</Typography>
							) : (
								<IconButton
									edge='end'
									aria-label='delete'
									onClick={() => removeAcceptedFile(file.id)}
								>
									<CloseOutlinedIcon />
								</IconButton>
							)}
						</ListItemSecondaryAction>
					</ListItem>
				))}

				{/* -------------------------- showing files already uploaded -------------------------- */}
				{workerCertFiles.map((file: workerCertFileProps) => (
					<ListItem
						key={file.workerCertFileId}
						sx={{
							boxShadow:
								"0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
							borderRadius: "4px",
						}}
					>
						<UploadFileOutlinedIcon sx={{ mr: 2, color: "#2F4D8B" }} />
						<ListItemText
							primary={
								<Tooltip title={file.fileName} placement='bottom-start'>
									<Typography
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											fontFamily: "Roboto",
											fontSize: "16px",
											fontWeight: 400,
											lineHeight: "28px",
											letterSpacing: "0.15px",
										}}
									>
										{truncateFileName(file.fileName, 40)}
									</Typography>
								</Tooltip>
							}
							secondary={
								<Typography
									sx={{
										fontSize: "14px",
										color: "#00000099",
									}}
								>
									{formatFileSize(file.fileSizeKB * 1024)}
								</Typography>
							}
						/>
						<ListItemSecondaryAction>
							<IconButton
								edge='end'
								aria-label='delete'
								onClick={(e) => {
									e.preventDefault();
									setFilesToDelete((prev: any) => [
										...prev,
										file.workerCertFileId,
									]);
									setFieldValue(
										"workerCertFiles",
										workerCertFiles.filter(
											(f) => f.workerCertFileId !== file.workerCertFileId
										)
									);
								}}
							>
								<CloseOutlinedIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}

				{/* --------------------------- Rejected files -------------------------- */}
				{rejectedFiles.map((file: any) => (
					<ListItem
						key={file.id}
						sx={{
							boxShadow:
								"0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
							borderRadius: "4px",
						}}
					>
						<UploadFileOutlinedIcon sx={{ mr: 2, color: "#D32F2F" }} />
						<ListItemText
							primary={
								<Tooltip title={file.file.name} placement='bottom-start'>
									<Typography
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											color: "#D32F2F",
											fontFamily: "Roboto",
											fontSize: "16px",
											fontWeight: 400,
											lineHeight: "28px",
											letterSpacing: "0.15px",
										}}
									>
										{truncateFileName(file.file.name, 40)}
									</Typography>
								</Tooltip>
							}
							secondary={
								<Typography
									sx={{
										fontSize: "14px",
										display: "flex",
										gap: 1,
										color: "#D32F2F",
									}}
								>
									{getFileErrorText(file.errors[0].code)}
									<Typography
										component={"span"}
										sx={{
											fontSize: "14px",
											fontWeight: 400,
											lineHeight: "20px",
										}}
									>
										•
									</Typography>
									<Typography
										component={"span"}
										sx={{
											fontSize: "14px",
											fontWeight: 400,
											lineHeight: "20px",
										}}
									>
										Failed
									</Typography>
								</Typography>
							}
						/>
						<ListItemSecondaryAction>
							<IconButton
								edge='end'
								aria-label='delete'
								onClick={() => removeRejectedFile(file.id)}
							>
								<CloseOutlinedIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default FileUpload;
