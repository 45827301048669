import Box from "@mui/material/Box";
import React from "react";

import {
	ALPHABET,
	BOX,
	CIRCLE,
	DONUT,
	NUMBER,
} from "../../../constants/constants";
import AbbreviationSkillRepresentation from "../../cross-skill-representations/ui/abbreviation-skill-representation";
import BoxSkillRepresentation from "../../cross-skill-representations/ui/box-skill-representation";
import CircleSkillRepresentation from "../../cross-skill-representations/ui/circle-skill-representation";
import SkillDonutRepresentation from "../../cross-skill-representations/ui/donut-skill-representation";

function SkillRepresentationCell({
	representationType = DONUT,
	representationLevel,
	level = 0,
	value = "",
	backgroundColor = "",
	height = 20,
	width = 20,
	backgroundHeight = "35px",
	backgroundWidth = "35px",
	border = 0,
	foregroundColor = "rgba(0,0,0,0.87)",
	inSelect = false, // To handle wierd select css behavior
}) {
	let showBorder = false;
	let repLevel;
	if (!representationLevel) {
		repLevel = level;
	} else {
		repLevel = representationLevel;
	}
	let ReturnSkillRepresentationCell = (
		<SkillDonutRepresentation
			representationLevel={repLevel}
			width={width}
			height={height}
		/>
	);
	switch (representationType) {
		case ALPHABET:
		case NUMBER:
			showBorder = backgroundColor === "" || backgroundColor === null;
			ReturnSkillRepresentationCell = (
				<AbbreviationSkillRepresentation text={value} foregroundColor={foregroundColor} inSelect={inSelect} />
			);
			break;
		case BOX:
			ReturnSkillRepresentationCell = (
				<BoxSkillRepresentation representationLevel={repLevel} />
			);
			break;
		case CIRCLE:
			ReturnSkillRepresentationCell = (
				<CircleSkillRepresentation representationLevel={repLevel} />
			);
			break;
		default:
			break;
	}
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				backgroundColor:
					backgroundColor === null || backgroundColor === ""
						? "rgba(0,0,0,0)"
						: `#${backgroundColor}`,
				width: backgroundWidth,
				height: backgroundHeight,
				borderRadius: backgroundWidth !== "100%" ? "3px": 0,
				border: showBorder ? border : 0,
			}}
		>
			{ReturnSkillRepresentationCell}
		</Box>
	);
}

export default SkillRepresentationCell;
