// TODO - Type 'any' needs to be fixed.
import React from "react";
import { useSelector } from "react-redux";

import { StoreType } from "../../../utils/types"

const useSchedulerData = () => {
  const createDraftShiftAsyncErrorMessage = useSelector(
    (state: StoreType) => state.supervisor?.createDraftShiftAsync?.errorMessage
  );

  const requestActionStatus = useSelector(
    (state: StoreType) => state.workerRequests.requestShiftAction.status
  );
  const requestActionErrorMessage = useSelector(
    (state: StoreType) => state.workerRequests.requestShiftAction.errorMessage
  );

  const formData = useSelector(
    (state: StoreType) => state.workerRequests.shiftFormData
  );
  const userRoles = useSelector((state: StoreType) => state.user.userData.userRoles);

  const fetchInitialDataStatus = useSelector(
    (state: StoreType) => state.supervisor?.fetchInitialData?.status
  );

  const fetchFilterAndShiftDataStatus = useSelector(
    (state: StoreType) => state.supervisor?.fetchFilterAndShiftData?.status
  );

  const fetchInitialDataErrorMessage = useSelector(
    (state: StoreType) => state.supervisor?.fetchInitialData?.errorMessage
  );

  const shiftsData = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.shiftsData
  );

  const schedulerJobRoles = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.jobRoles
  );

  const schedulerWorkCenters = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.workcenters
  );

  const schedulerLocations = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.locations
  );

  const schedulerManagers = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.allManagers
  );

  const filterAllManagers = useSelector(
    (state: StoreType) => state.supervisor?.filterData?.allManagers
  );

  const shiftId = useSelector(
    (state: StoreType) => state.supervisor.editDraftShiftDialog.shiftId
  );

  const broadcastMessageStatus = useSelector(
    (state: StoreType) => state.supervisor.broadcastMessage.status
  );

  const currentDate = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.currentDate
  );

  const deleteDraftShiftAsyncStatus = useSelector(
    (state: StoreType) => state.supervisor?.deleteDraftShiftAsync?.status
  );

  const createDraftShiftAsyncStatus = useSelector(
    (state: StoreType) => state.supervisor?.createDraftShiftAsync?.status
  );

  const createDraftShiftAsyncError = useSelector(
    (state: StoreType) => state.supervisor?.createDraftShiftAsync?.errorMessage
  );

  const publishDraftShiftsAsyncStatus = useSelector(
    (state: StoreType) => state.supervisor?.publishDraftShiftsAsync?.status
  );

  const checkAllowedToPublishStatus = useSelector(
    (state: StoreType) => state.supervisor?.checkAllowedToPublish?.status
  );

  const checkAllowedToPublishErrorMessage = useSelector(
    (state: StoreType) => state.supervisor?.checkAllowedToPublish?.errorMessage
  );

  const checkAllowedToPublishData = useSelector(
    (state: StoreType) => state.supervisor?.checkAllowedToPublish?.data
  );

  const changeWeekAsyncStatus = useSelector(
    (state: StoreType) => state.supervisor?.changeWeekNoCache?.status
  );

  const changeDayAsyncStatus = useSelector(
    (state: StoreType) => state.supervisor?.changeDayNoCache?.status
  );

  const changeWeekAvailabilityStatus = useSelector(
    (state: StoreType) => state.supervisor?.changeWeeklyAvailability.status
  );

  const isEditShiftDialogOpen = useSelector(
    (state: StoreType) => state.supervisor.editDraftShiftDialog.isOpen
  );

  const shiftIdForEditShift = useSelector(
    (state: StoreType) => state.supervisor.editDraftShiftDialog.shiftId
  );

  const renotifyWorkersStatus = useSelector(
    (state: StoreType) => state.supervisor.renotifyWorkers.status
  );
  const renotifyWorkersErrorMessage = useSelector(
    (state: StoreType) => state.supervisor.renotifyWorkers.errorMessage
  );

  const editDraftShiftStatus = useSelector(
    (state: StoreType) => state.supervisor.editDraftShift.status
  );

  const jobRoles = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allJobRoles
  );

  const filterJobRoles = useSelector(
    (state: StoreType) => state.supervisor?.filterData?.allJobRoles
  );

  const filterAllSkills = useSelector(
    (state: StoreType) => state.supervisor?.filterData?.allSkills
  );

  const defaultDurationHrs = useSelector(
    (state: StoreType) => state?.user?.userData?.defaultDurationHrs
  );
  const minDurationHrs = useSelector(
    (state: StoreType) => state?.user?.userData?.minDurationHrs
  );
  const maxDurationHrs = useSelector(
    (state: StoreType) => state?.user?.userData?.maxDurationHrs
  );

  const allJobRoles = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allJobRoles
  );

  const publishDraftShiftsAsyncErrorMessage = useSelector(
    (state: StoreType) => state.supervisor?.publishDraftShiftsAsync?.errorMessage
  );
  const broadcastHistoryStatus = useSelector(
    (state: StoreType) => state.supervisor.broadcastHistory.status
  );

  const broadcastHistoryData = useSelector(
    (state: StoreType) => state.supervisor.broadcastHistory.data
  );
  const broadcastMessageError = useSelector(
    (state: StoreType) => state.supervisor.broadcastMessage.errorMessage
  );

  const userData = useSelector((state: StoreType) => state.user.userData);

  const allWorkcenters = useSelector(
    (state: StoreType) => state.supervisor.masterData.allWorkcenters
  );

  const fetchAllWorkcentersStatus = useSelector(
      (state: StoreType) => state.supervisor.fetchAllWorkcenters.status
  );

  const filterAllWorkcenters = useSelector(
    (state: StoreType) => state.supervisor.filterData.allWorkcenters
  );

  const allWeekShifts = useSelector(
    (state: StoreType) => state.supervisor.schedulerData.weekShiftsData
  );

  const datesOfTheWeek = useSelector(
    (state: StoreType) => state.supervisor.schedulerData.datesOfTheWeek
  );

  const allShiftsMetadata = useSelector(
    (state: StoreType) => state.supervisor?.schedulerData?.shiftsData
  );

  const shiftDetails = useSelector(
    (state: StoreType) => state.supervisor?.shiftDetails?.data
  );

  const shiftsAttendanceData = useSelector(
    (state: StoreType) => state.supervisor?.shiftAttendanceDetails?.data
  );

  const shiftAttendanceDetailsStatus = useSelector(
    (state: StoreType) => state.supervisor?.shiftAttendanceDetails?.status
  );

  const markAttendanceStatus = useSelector(
    (state: StoreType) => state.supervisor?.markAttendance.status
  );

  const markAttendanceError = useSelector(
    (state: StoreType) => state.supervisor?.markAttendance.errorMessage
  );

  const weekShiftsStatus = useSelector(
    (state: StoreType) => state.supervisor?.weekShifts.status
  );
  const weekShiftsError = useSelector(
    (state: StoreType) => state.supervisor?.weekShifts.errorMessage
  );

  const isWeekView = useSelector((state: StoreType) => state.supervisor?.weekView);

  const userId = useSelector((state: StoreType) => state.user.userData.id);

  const allSkills = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allSkills
  );

  const allSkillLevels = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allSkillLevels
  );
  const defaultShiftsSkillLevel = useSelector(
    (state: StoreType) => state.user.userData.defaultShiftSkillLevel
  );

  const isShiftBySkillEnabled = useSelector(
    (state: StoreType) => state.user.userData.shiftBySkills
  );

  const workcenters = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allWorkcenters
  );

  const maxWorkersByJobLocation = useSelector(
    (state: StoreType) => state.manageJobs.fetchMaxWorkersByLocationJob
  );

  const shiftDetailsMetaData = useSelector(
    (state: StoreType) => state?.workerRequests?.getShiftMetaData?.data as any
  );

  const allWorkCenters = useSelector(
    (state: StoreType) => state.supervisor?.masterData?.allWorkcenters
  );

  const locationsWithCluster = useSelector(
    (state: StoreType) => state.supervisor?.locationsWithCluster
  );
  const companyId = useSelector(
    (state: StoreType) => state?.user?.userData?.companyId
  );
  const allLocations = useSelector(
    (state: StoreType) => state?.supervisor?.masterData?.allLocations
  );
  const filterAllLocations = useSelector(
    (state: StoreType) => state?.supervisor?.filterData?.allLocations
  );
  const fetchAllLocations = useSelector(
    (state: StoreType) => state?.supervisor?.fetchAllLocations
  );
  const locationsWithClusterAsync = useSelector(
    (state: StoreType) => state?.supervisor?.locationsWithClusterAsync
  );
  const broadcastWorkerCount = useSelector(
    (state: StoreType) => state?.supervisor?.broadcastWorkers?.workers
  );
  const broadcastWorkerCountStatus = useSelector(
    (state: StoreType) => state?.supervisor?.broadcastWorkers?.status
  );
  const shiftsByFilter = useSelector(
    (state: StoreType) => state.supervisor?.shiftsByFilter
  );

  const shiftBySkills = useSelector(
    (state: StoreType) => state.user?.userData.shiftBySkills
  );

  const isHeatMapVisible = useSelector(
    (state: StoreType) => state.user.userData.showHeatMap
  );

  const assignWorkersStatus = useSelector(
    (state: StoreType) => state.supervisor.assignWorkers.status
  );

  const assignWorkersErrorMessage = useSelector(
    (state: StoreType) => state.supervisor.assignWorkers.errorMessage
  );

  return {
    schedulerJobRoles,
    workcenters,
    maxWorkersByJobLocation,
    allSkills,
    allSkillLevels,
    createDraftShiftAsyncErrorMessage,
    defaultShiftsSkillLevel,
    requestActionStatus,
    requestActionErrorMessage,
    formData,
    userRoles,
    fetchInitialDataStatus,
    fetchInitialDataErrorMessage,
    shiftsData,
    allShiftsData: shiftsData,
    shiftId,
    broadcastMessageStatus,
    currentDate,
    deleteDraftShiftAsyncStatus,
    createDraftShiftAsyncStatus,
    createDraftShiftAsyncError,
    publishDraftShiftsAsyncStatus,
    checkAllowedToPublishStatus,
    checkAllowedToPublishErrorMessage,
    checkAllowedToPublishData,
    changeWeekAsyncStatus,
    changeDayAsyncStatus,
    changeWeekAvailabilityStatus,
    isEditShiftDialogOpen,
    shiftIdForEditShift,
    renotifyWorkersStatus,
    renotifyWorkersErrorMessage,
    editDraftShiftStatus,
    jobRoles,
    filterJobRoles,
    filterAllSkills,
    defaultDurationHrs,
    minDurationHrs,
    maxDurationHrs,
    allJobRoles,
    publishDraftShiftsAsyncErrorMessage,
    broadcastHistoryStatus,
    broadcastHistoryData,
    broadcastMessageError,
    userData,
    allWorkcenters,
    allWeekShifts,
    datesOfTheWeek,
    allShiftsMetadata,
    shiftDetails,
    shiftsAttendanceData,
    shiftAttendanceDetailsStatus,
    markAttendanceStatus,
    markAttendanceError,
    weekShiftsStatus,
    weekShiftsError,
    isWeekView,
    userId,
    isShiftBySkillEnabled,
    shiftDetailsMetaData,
    allWorkCenters,
    locationsWithCluster,
    companyId,
    allLocations,
    fetchAllLocations,
    locationsWithClusterAsync,
    broadcastWorkerCount,
    broadcastWorkerCountStatus,
    shiftsByFilter,
    shiftBySkills,
    isHeatMapVisible,
    assignWorkersStatus,
    assignWorkersErrorMessage,
    schedulerWorkCenters,
    schedulerLocations,
    schedulerManagers,
    fetchFilterAndShiftDataStatus,
    fetchAllWorkcentersStatus,
    filterAllWorkcenters,
    filterAllManagers,
    filterAllLocations,
  };
};

export default useSchedulerData;
