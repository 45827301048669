// TODO - Type 'any' needs to be fixed.

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React, { useRef, useState } from "react";

import { markAttendance } from "../../../../../../export/scheduler";
import CommentDialog from "./comment-dialog";
import useMarkAttendanceColumns from "./controllers/use-mark-attendance-columns";

interface MarkAttendanceTableProps {
  rows: any;
  isLoading: boolean | undefined;
}

const MarkAttendanceTable: React.FC<MarkAttendanceTableProps> = ({
  rows,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [worker, setWorker] = useState({});

  const columns: any = useMarkAttendanceColumns({ setOpen, setWorker });
  const dataGridRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      width={700}
      minHeight={158}
    >
      <DataGridPro
        ref={dataGridRef}
        loading={isLoading}
        slots={{
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnSelector
        disableDensitySelector
        disableColumnReorder
        disableColumnMenu
        sx={{
          position: "sticky",
          "& .MuiDataGrid-columnHeader": {
            "&.hover-column-header": {
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-sortIcon": {
                display: "none",
              },
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
          },
          ".MuiDataGrid-columnHeader:hover": {
            "& .MuiDataGrid-columnSeparator": {
              display: "flex",
            },
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-root": {
            "& .MuiDataGrid-viewport": {
              overflowX: "hidden !important",
            },
          },
          "& .MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            minHeight: "158px !important", // Height of two rows and header
          },
          minHeight: "158px", // Height of two rows and header
        }}
      />
      <CommentDialog
        markAttendance={markAttendance}
        open={open}
        setOpen={setOpen}
        worker={worker}
        setWorker={setWorker}
      />
    </Box>
  );
};

export default MarkAttendanceTable;
