import { Grid,Typography } from "@mui/material";
import React from "react";

const CrossSkillingLevelsTableHeader: React.FC<Record<string, never>> = () => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
            }}
        >
            <Grid
                item
                xs={0.75}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Level
                </Typography>
            </Grid>
            <Grid
                item
                xs={1.25}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Icon
                </Typography>
            </Grid>
            <Grid
                item
                xs={1.25}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Color
                </Typography>
            </Grid>
            <Grid
                item
                xs={1.25}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Level code
                </Typography>
            </Grid>
            <Grid
                item
                xs={2.5}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                        marginLeft: "12px",
                    }}
                >
                    Level name
                </Typography>
            </Grid>
            <Grid
                item
                xs={4}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Description
                </Typography>
            </Grid>
            <Grid
                item
                xs={1}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <Typography
                    sx={{
                        fontFamily: "Roboto",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.87)",
                    }}
                >
                    Preview
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CrossSkillingLevelsTableHeader;