// TODO - Type 'any' needs to be fixed.
import LoadingButton from "@mui/lab/LoadingButton";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import React, { forwardRef, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useDispatch, useSelector } from "react-redux";

import { colors } from "../../../../../../export/config";
import {
	closeAddWorkerDialog,
	// eslint-disable-next-line sort-imports
	JobStatusSwitch,
	SearchJobInput,
} from "../../../../../../export/workers";
import useWorkersData from "../../../controllers/use-workers-data";

interface Job {
	id: number;
	name: string;
	hexColor: string;
	skills: any[]; // You might want to define a type for skills
}

interface Props {
	allJobRoles: Job[];
	allSkillLevels: any[];
	values: any;
	setFieldValue: (field: string, value: any) => void;
	isActive: boolean;
	handleNext: () => void;
	handlePrevious: () => void;
	isCrossSkillingOpen: boolean;
	setActiveStep: (step: number) => void;
	initialData: any[];
}

const JobSectionLoaderWithCheckBox = () => (
	<ContentLoader
		speed={0}
		width={200}
		height={32}
		viewBox='0 0 200 32'
		backgroundColor={colors.grey}
		foregroundColor={colors.greyLight}
	>
		<rect x='0' y='6' rx='1' ry='1' width='20' height='20' />
		<rect x='30' y='6' rx='3' ry='3' width='160' height='20' />
	</ContentLoader>
);

const JobStatusSectionLoader = () => (
	<ContentLoader
		speed={0}
		width={160}
		height={32}
		viewBox='0 0 160 32'
		backgroundColor={colors.grey}
		foregroundColor={colors.greyLight}
	>
		<rect x='30' y='6' rx='10' ry='10' width='40' height='20' />
		<rect x='90' y='6' rx='3' ry='3' width='60' height='20' />
	</ContentLoader>
);

const useStyles = makeStyles(() => ({
	formRoot: {
		width: "100%",
	},
	formControlRoot: {
		width: "100%",
		padding: 10,
		backgroundColor: colors.light,
	},
	container: {
		overflow: "auto",
		"&::-webkit-scrollbar": {
			width: "0px",
			height: "0px",
		},
		"-ms-overflow-style": "none",
		scrollbarWidth: "none",
	},
}));

const AssignJobsForm = forwardRef<HTMLDivElement, Props>(
	function AssignJobsForm(props, ref) {
		const {
			allJobRoles,
			allSkillLevels,
			values,
			setFieldValue,
			isActive,
			handleNext,
			handlePrevious,
			isCrossSkillingOpen,
			setActiveStep,
			initialData,
		} = props;

		const classes = useStyles();
		const dispatch = useDispatch();

		const { editWorkerStatus } = useWorkersData();

		const [jobs, setJobs] = useState<Job[]>([]);
		const [searchJob, setSearchJob] = useState<string>("");

		const isShowJobExpiry = useSelector(
			(state: any) => state.user.userData.showJobExpiry
		);

		const getLevelId = (level: number) => {
			try {
				let returnValue = 1;
				if (allSkillLevels) {
					for (let i = 0; i < allSkillLevels.length; i++) {
						if (allSkillLevels[i].level === level) {
							returnValue = allSkillLevels[i].levelId;
							break;
						}
					}
				}
				return returnValue;
			} catch (e) {
				return 1;
			}
		};

		const jobAndStatusSection = (times = 1) => {
			const returnValue: JSX.Element[] = [];
			for (let i = 0; i < times; i++) {
				const jobAndSkillsSectionBody = (
					<Box key={`job-section-${i}`}>
						<Divider />
						<Box
							display='flex'
							width='100%'
							flexDirection='row'
							justifyContent='space-between'
						>
							<Box display='flex' alignItems='center' height={49}>
								<JobSectionLoaderWithCheckBox />
							</Box>
							<Box display='flex' width={160} mt={1}>
								<JobStatusSectionLoader />
							</Box>
						</Box>
					</Box>
				);
				returnValue.push(jobAndSkillsSectionBody);
			}
			return returnValue;
		};

		const handleStatusChange = (status: boolean, jobId: number) => {
			const updatedWorkerJobs = values.workerJobs.map((job: any) => {
				if (job.jobId === jobId) return { ...job, isExpired: !status };
				return job;
			});

			const updatedJobsAndSkills = values.jobsAndSkills.map((job: any) => {
				if (job.jobId === jobId) return { ...job, isExpired: !status };
				return job;
			});

			setFieldValue("jobsAndSkills", updatedJobsAndSkills);
			setFieldValue("workerJobs", updatedWorkerJobs);
		};

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			setSearchJob(e.target.value);
		};

		useEffect(() => {
			if (allJobRoles) {
				const result = allJobRoles.filter((x) =>
					x.name.toLowerCase().includes(searchJob.toLowerCase())
				);
				setJobs(result);
			}
		}, [allJobRoles, searchJob]);

		return (
			<Box
				sx={{
					borderRadius: "10px",
					border: isActive ? "2px solid #2F4D8B" : "3px solid #f4f4f4",
					pointerEvents: isActive ? "auto" : "none",
					// maxHeight: 500,
					// overflowY: "auto",
				}}
				mt={5}
				mb={isCrossSkillingOpen ? 0 : 15}
				ref={ref}
			>
				<Box
					p={2}
					// sx={{
					// 	position: "sticky",
					// 	top: 0,
					// 	backgroundColor: "white",
					// 	zIndex: 1,
					// }}
				>
					<Typography fontWeight='bold'>Assign Job roles (optional)</Typography>
					<Typography variant='caption'>
						Select which job roles this worker will need to work.
					</Typography>
					<SearchJobInput
						handleChange={handleChange}
						searchJob={searchJob}
						setSearchJob={(value: string) => setSearchJob(value)}
					/>
				</Box>
				<Box
					p={2}
					maxWidth={900}
					display='flex'
					flexDirection='column'
					alignItems='flex-start'
					sx={{
						overflowY: "auto",
						maxHeight: 400,
					}}
				>
					{jobs.length !== 0 ? (
						<>
							<Box
								display='flex'
								width='100%'
								flexDirection='row'
								justifyContent='space-between'
							>
								<Box width={182} ml={5.5}>
									<Typography fontWeight='bold' fontSize={14}>
										Job Roles
									</Typography>
								</Box>

								{isShowJobExpiry ? (
									<Typography
										fontWeight='bold'
										mr={2}
										width={120}
										fontSize={14}
									>
										Job Status
									</Typography>
								) : null}
							</Box>

							<Box
								display='flex'
								width='100%'
								flexDirection='column'
								className={classes.container}
							>
								<FormControl
									variant='filled'
									className={classes.formControlRoot}
								>
									{!isActive && jobAndStatusSection(4)}
									{isActive && (
										<>
											{jobs?.map((role, index) => (
												<Box key={role.id.toString() + index.toString()}>
													{index === 0 && <Divider />}
													<Box
														width='100%'
														display='flex'
														justifyContent='space-between'
														alignItems='center'
														height={49}
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={values?.jobs[role.id]}
																	onChange={(e) => {
																		const checkJobs = allJobRoles.reduce(
																			(acc: any, key) => {
																				if (key.id === role?.id) {
																					acc[key.id] = e.target.checked;
																				} else {
																					acc[key.id] = values.jobs[key.id];
																				}
																				return acc;
																			},
																			{}
																		);
																		if (e.target.checked) {
																			const temp = [
																				...values.workerJobs,
																				{
																					jobId: role.id,
																					isExpired: false,
																				},
																			];
																			setFieldValue("workerJobs", temp);
																			setFieldValue("jobsAndSkills", [
																				...values.jobsAndSkills,
																				{
																					jobId: role.id,
																					isExpired: false,
																					skills: role.skills.map(
																						(sk: any) => ({
																							...sk,
																							level: 0,
																							levelId: getLevelId(0),
																						})
																					),
																					jobName: role.name,
																					color: role.hexColor,
																				},
																			]);
																		} else {
																			const temp = [
																				...values.workerJobs,
																			].filter(
																				(job: any) => job.jobId !== role.id
																			);
																			setFieldValue("workerJobs", temp);
																			setFieldValue(
																				"jobsAndSkills",
																				[...values.jobsAndSkills].filter(
																					(job: any) => job.jobId !== role.id
																				)
																			);
																		}
																		setFieldValue("jobs", checkJobs);
																	}}
																	name={role.name}
																/>
															}
															label={role.name}
														/>
														{isShowJobExpiry ? (
															<JobStatusSwitch
																workerId={values.id}
																disabled={!values.jobs[role.id]}
																isExpired={
																	!!values.workerJobs.find(
																		(job: any) => job.jobId === role.id
																	)?.isExpired
																}
																jobId={role.id}
																onChange={handleStatusChange}
															/>
														) : null}
													</Box>
													<Divider />
												</Box>
											))}
										</>
									)}
								</FormControl>
							</Box>
						</>
					) : null}
					{jobs.length === 0 && (
						<Typography align='center'>
							There are no job roles to select.
						</Typography>
					)}
				</Box>
				{isActive ? (
					<Box
						display='flex'
						alignItems='center'
						justifyContent='flex-end'
						p={2}
						// sx={{
						// 	position: "sticky",
						// 	bottom: 0,
						// 	backgroundColor: "white",
						// 	zIndex: 1,
						// }}
					>
						<Box m={1}>
							<Button onClick={() => setActiveStep(0)}>Cancel</Button>
						</Box>
						{/* <Box m={1}>
							<Button variant='contained' type='submit'>
								Save
							</Button>
						</Box> */}
						<LoadingButton
							variant='contained'
							type='submit'
							sx={{ marginLeft: "16px" }}
							loading={editWorkerStatus === "pending"}
							loadingIndicator={
								<CircularProgress
									size={16}
									sx={{ color: "rgba(0, 0, 0, 0.38)" }}
								/>
							}
							disabled={
								!values.workerJobs.length ||
								editWorkerStatus === "pending" ||
								// compare only the length of the arrays and one property called "isExpired"
								values.workerJobs.length === initialData.length &&
								values.workerJobs.every((job: any) =>
									initialData.find(
										(initialJob: any) =>
											initialJob.jobId === job.jobId &&
											initialJob.isExpired === job.isExpired
									)
								)
							}
						>
							SAVE
						</LoadingButton>
					</Box>
				) : null}
			</Box>
		);
	}
);

export default AssignJobsForm;
