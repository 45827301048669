import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

import { AllocationSettings } from "../../../../../store/gat-admin-slice-types";
import InputButtonGroup from "./input-button-group";

const defaultWindowTooltip =
	"Time frame for workers to mark interest in a published shift. After this window,  shift will be assigned based on allocation rule.";
const urgentWindowTooltip =
	"An urgent shift is when the default application window is more than the time between shift publish and shift start time. The window for an urgent shift has to be shorter than the default application window. Recommended - 15minutes.";
const tieBreakerTooltip =
	"If there are 2 or more eligible workers who have marked interest below order decides which worker will be assigned the shift.";

const Title: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<Typography
		sx={{
			fontFamily: "Roboto",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "32px",
			letterSpacing: "1px",
			textAlign: "left",
		}}
	>
		{children}
	</Typography>
);

type ConfigurationFormProps = {
	shiftAllocationRules: any;
	setShiftAllocationRules: any;
	id?: number;
};

const ConfigurationForm = ({
	shiftAllocationRules,
	setShiftAllocationRules,
	id,
}: ConfigurationFormProps) => {
	const config = shiftAllocationRules.find(
		(rule: { shiftAllocationTypeId: number }) =>
			rule.shiftAllocationTypeId === id
	);

	return (
		<Box width={900}>
			<Divider />
			<Box my={2}>
				<Box display='flex' alignItems='center'>
					<Title>DEFAULT APPLICATION WINDOW</Title>
					<Tooltip title={defaultWindowTooltip}>
						<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
					</Tooltip>
				</Box>

				<Box my={2} display='flex'>
					<Box display='flex' alignItems='center' mr={2}>
						<InputButtonGroup
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins
													? rule.defaultWindowSizeMins + 60
													: 60,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins - 60,
											};
										}
										return rule;
									})
								)
							}
							value={
								config?.defaultWindowSizeMins
									? Math.floor(config?.defaultWindowSizeMins / 60)
									: 0
							}
						/>
						<Typography ml={1}>hours</Typography>
					</Box>
					<Box display='flex' alignItems='center' ml={2}>
						<InputButtonGroup
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins
													? rule.defaultWindowSizeMins + 15
													: 15,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												defaultWindowSizeMins: rule.defaultWindowSizeMins - 15,
											};
										}
										return rule;
									})
								)
							}
							value={
								config?.defaultWindowSizeMins
									? config.defaultWindowSizeMins % 60
									: 0
							}
						/>
						<Typography ml={1}>minutes</Typography>
					</Box>
				</Box>
			</Box>
			<Box>
				<Box display='flex' alignItems='center'>
					<Title>APPLICATION WINDOW - URGENT SHIFT</Title>
					<Tooltip title={urgentWindowTooltip}>
						<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
					</Tooltip>
				</Box>

				<Box my={2} display='flex'>
					<Box display='flex' alignItems='center'>
						<InputButtonGroup
							handleIncrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												urgentWindowSizeMins: rule.urgentWindowSizeMins
													? rule.urgentWindowSizeMins + 1
													: 1,
											};
										}
										return rule;
									})
								)
							}
							handleDecrement={() =>
								setShiftAllocationRules(
									shiftAllocationRules.map((rule: AllocationSettings) => {
										if (rule.shiftAllocationTypeId === id) {
											return {
												...rule,
												urgentWindowSizeMins: rule.urgentWindowSizeMins - 1,
											};
										}
										return rule;
									})
								)
							}
							value={config?.urgentWindowSizeMins ?? 0}
						/>
						<Typography ml={1}>minutes</Typography>
					</Box>
				</Box>
			</Box>
			<Box>
				<Box display='flex' alignItems='center'>
					<Title>TIE BREAKER RULES</Title>
					<Tooltip title={tieBreakerTooltip}>
						<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
					</Tooltip>
				</Box>

				<Stack direction='column' alignItems='start'>
					<Chip
						avatar={<Avatar>1</Avatar>}
						label="Worker's Workcenter"
						sx={{ marginTop: "8px" }}
					/>

					<Chip
						avatar={<Avatar>2</Avatar>}
						label="Worker's Location"
						sx={{ marginTop: "8px" }}
					/>
					<Chip
						avatar={<Avatar>3</Avatar>}
						label='Overtime hours(Lowest first)'
						sx={{ marginTop: "8px" }}
					/>
					<Chip
						avatar={<Avatar>4</Avatar>}
						label='Seniority (Highest first)'
						sx={{ marginTop: "8px" }}
					/>
				</Stack>
			</Box>
		</Box>
	);
};

export default ConfigurationForm;
