// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import { Label } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
	fetchAllLocations,
	fetchBroadcastHistory,
	locationsWithClusterAsync,
	resetBroadcastWorkerCount,
	sendBroadcastMessage,
} from "../../../../../../../export/scheduler";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { JobsState, LocationOptions, WorkCenter } from "../types";
import { checkboxes, checkboxesAllTrue } from "../utils";

const useBmessageDialog = () => {
	const dispatch = useDispatch();
	const {
		allJobRoles,
		locationsWithCluster,
		companyId,
		allLocations,
		broadcastWorkerCount,
	} = useSchedulerData();
	const [options, setOptions] = useState<LocationOptions[]>(
		locationsWithCluster.map((location: LocationOptions) => ({
			label: location.name,
			checked: false,
			id: location.id,
		}))
	);

	const [message, setMessage] = useState<string>("");
	const [jobs, setJobs] = useState<JobsState>({});
	const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
	const [filteredJobs, setFilteredJobs] = useState(allJobRoles);
	const [filteredWorkcenters, setFilteredWorkcenters] = useState<WorkCenter[]>(
		[]
	);
	const [changedBy, setChangedBy] = useState<string>("");

	const optionsRef = useRef(options);

	// Filter Jobs and Workcenters based on Locations
	useEffect(() => {
		if (changedBy === "location") {
			const selectedLocationIds = options
				.filter((option) => option.checked)
				.map((option) => option.id);

			const workCenters = [];
			for (const id of selectedLocationIds) {
				const location = allLocations?.find(
					(obj: { id: number }) => obj.id === id
				);
				if (location) {
					const locationWorkcenters = location.workCenters;
					const wcs = locationWorkcenters.map((wc: { name: string }) => ({
						...wc,
						label: wc.name,
						checked: false,
					}));
					workCenters.push(...wcs);
				}
			}
			const jobIds =
				workCenters
					.map((workCenter: { jobIds: string }) =>
						workCenter.jobIds?.split(",")
					)
					.flat() ?? [];

			const finalJobs =
				allJobRoles.length > 0
					? allJobRoles.filter((job: { id: number }) =>
							jobIds.includes(job.id.toString())
					  )
					: [];
			const jobs = finalJobs.map((job: any) => ({
				...job,
				label: job.name,
				checked: false,
			}));
			setJobs(checkboxes({ allJobRoles: finalJobs }));
			setFilteredJobs(jobs);
			setFilteredWorkcenters(workCenters);
		}
	}, [changedBy, allJobRoles, allLocations, options]);

	useEffect(() => {
		if (changedBy === "workcenter") {
			const selectedWorkcenter = filteredWorkcenters.filter(
				(workcenter) => workcenter.checked
			);

			const jobIds =
				selectedWorkcenter
					.map((workCenter: { jobIds: string }) =>
						workCenter.jobIds?.split(",")
					)
					.flat() ?? [];
			const uniqueArray = jobIds.filter(
				(value, index, self) => self.indexOf(value) === index
			);
			const finalJobs =
				allJobRoles.length > 0
					? allJobRoles.filter((job: { id: number }) =>
							uniqueArray.includes(job.id.toString())
					  )
					: [];
			const jobs = finalJobs.map((job: { name: string }) => ({
				...job,
				label: job.name,
				checked: false,
			}));
			setFilteredJobs(jobs);
			//setOptions(locs);
		}
	}, [
		changedBy,
		filteredWorkcenters,
		allLocations,
		locationsWithCluster,
		allJobRoles,
	]);

	useEffect(() => {
		setOptions(
			locationsWithCluster.map((location: LocationOptions) => ({
				label: location.name,
				checked: false,
				id: location.id,
			}))
		);
	}, [locationsWithCluster]);

	useEffect(() => {
		dispatch(locationsWithClusterAsync());
		dispatch(fetchAllLocations(companyId));
	}, [companyId, dispatch]);

	const handleClick = ({ id }: { id: number }) => {
		setJobs({ ...jobs, [id]: !jobs[id] });
	};

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			setJobs(checkboxesAllTrue({ allJobRoles: filteredJobs }));
		} else {
			setJobs(checkboxes({ allJobRoles: filteredJobs }));
		}
	};

	useEffect(() => {
		const anyJobChecked =
			filteredJobs.length > 0
				? filteredJobs.some((job: { checked: boolean }) => job.checked)
				: false;
		const anyLocationSelected = options?.some((option) => option.checked);
		const anyWorkcenterSelected = filteredWorkcenters.some(
			(workcenter) => workcenter.checked
		);

		if (
			(anyLocationSelected &&
				(changedBy === "location" || changedBy === "workcenter")) ||
			(anyWorkcenterSelected &&
				(changedBy === "workcenter" || changedBy === "job")) ||
			(anyJobChecked && changedBy === "job")
		) {
			dispatch(
				sendBroadcastMessage({
					jobs:
						filteredJobs
							.filter((job: { checked: boolean }) => job.checked)
							.map((job: { id: number }) => job.id)
							.join(",") ?? "",
					locationIds:
						options
							?.filter((option) => option.checked)
							?.map((option) => option.id)
							.join(",") ?? "",
					workCenterIds:
						filteredWorkcenters
							.filter((workcenter) => workcenter.checked)
							.map((workcenter) => workcenter.id)
							.join(",") ?? "",
					reqType: "workercount",
				})
			);
		} else dispatch(resetBroadcastWorkerCount({}));
	}, [dispatch, changedBy, options, filteredJobs, filteredWorkcenters]);

	useEffect(() => {
		optionsRef.current = options;
	}, [options]);

	const handleBroadcastHistory = () => {
		dispatch(fetchBroadcastHistory());
		setIsHistoryOpen((prev) => !prev);
	};

	return [
		{
			message,
			jobs,
			isHistoryOpen,
			options,
			filteredJobs,
			filteredWorkcenters,
		},
		{
			setMessage,
			setJobs,
			setIsHistoryOpen,
			setFilteredWorkcenters,
			handleClick,
			handleSelectAll,
			handleBroadcastHistory,
			setOptions,
			setFilteredJobs,
			setChangedBy,
		},
	];
};

export default useBmessageDialog;
