import { DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React from "react";

import Button from "../design/button";

export default function ConfirmDialog({
	open,
	onClose,
	action,
	text,
	title,
}: {
	open: boolean;
	onClose: () => void;
	action: () => void;
	text: string;
	title?: string;
}) {
	return (
		<Dialog
			open={open}
			// TransitionComponent={Transition}
			keepMounted
			onClose={() => onClose()}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-slide-description'>
					{text}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} variant='outlined'>
					No
				</Button>
				<Button onClick={() => action()} color='error' variant='outlined'>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
}
