// TODO - Type 'any' needs to be fixed.
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {getShiftAttendanceDetails} from "../../../../../../../export/scheduler";
import useSchedulerData from "../../../../controllers/use-scheduler-data";


const useMarkAttendanceDialog = (shiftId: number) => {
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const [shiftMetadata, setShiftMetadata] = useState({
		startDate : "",
		endDate: "",
	});
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const {
		allShiftsMetadata,
		shiftsAttendanceData,
		shiftAttendanceDetailsStatus,
		markAttendanceStatus,
		markAttendanceError,
	} = useSchedulerData();

	const shiftData = useSelector(
		(state: any) => state.supervisor?.shiftDetailsV2?.data
	);
	const shiftAttendanceData = shiftsAttendanceData?.[shiftId];

	useEffect(() => {
		// fetch the data
		if (shiftId) {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
		}
	}, [dispatch, shiftId]);

	useEffect(() => {
		if (markAttendanceStatus === "fulfilled") {
			dispatch(getShiftAttendanceDetails({ shiftid: shiftId }));
		}
	}, [dispatch, markAttendanceStatus, shiftId]);

	useEffect(() => {
		if (shiftData && shiftAttendanceData) {
			const rowData: any = [];

			const accepted = shiftData.workers && shiftData.workers.filter((data: { status: string; }) => data.status === "Accepted");
			accepted && accepted.forEach((worker: any) => {
				const workerData = shiftAttendanceData.find(
					(w:any) => w.workerId === worker.workerId
				);
				rowData.push({
					id: worker.workerId,
					workerName: worker.workerName,
					status: workerData?.status,
					comment: workerData?.comment,
					shiftId: shiftId,
				});
			});
			setRows(rowData);
		}
	}, [shiftData, shiftId, shiftAttendanceData]);

	useEffect(() => {
		// getting shift details from shiftId
		if (allShiftsMetadata) {
			const currentShiftData = allShiftsMetadata.filter(
				(shift:any) => shift.id === shiftId
			);
			setShiftMetadata(currentShiftData[0]);
		}
	}, [allShiftsMetadata, shiftId]);

	useEffect(() => {
		if (
			((shiftAttendanceDetailsStatus === "fulfilled" && rows.length > 0) ||
				shiftAttendanceDetailsStatus === "error"
			) &&
			(markAttendanceStatus !== "pending")
		) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	},[
		shiftAttendanceDetailsStatus,
		markAttendanceStatus,
		rows,
	]);

	return [
		{
			rows,
			shiftMetadata,
			shiftAttendanceDetailsStatus,
			markAttendanceStatus,
			markAttendanceError,
			isLoading,
		},
		{ setRows, setShiftMetadata },
	];
};

export default useMarkAttendanceDialog;
