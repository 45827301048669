import { Box, Typography } from "@mui/material";
import React from "react";

const ColumnHeader = ({ text }) => {
    return (
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "8px",
            height: "60px",
        }}>
            <Typography
                sx={{wordBreak: "break-word"}}
                whiteSpace='normal'
                //textAlign='center'
                style={{
                    width: "120px",
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "4px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    marginLeft: "10px",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default ColumnHeader;
