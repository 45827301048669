// TODO - Type 'any' needs to be fixed.
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button } from "@mui/material";
import isAfter from "date-fns/isAfter";
import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmDialog from "../../ConfirmDialog";
import { deleteDraftShiftAsync, editDraftShiftDialogClose } from "../store/scheduler-slice";


interface IDeleteShiftProps {
	shiftDate: any,
	shiftId: any,
	toggleVisibility: (val: any) => void,
	isDraftShift?: boolean,
}

function DeleteShift({
	shiftDate,
	shiftId,
	toggleVisibility,
	isDraftShift = false,
}: IDeleteShiftProps) {

	const dispatch = useDispatch();
	const userRoles = useSelector((state: any) => state.user.userData.userRoles);

	const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
		useState(false);

	const isPastShift = (shiftDateTime: any) => {
		return isAfter(new Date(), new Date(shiftDateTime));
	};

	const confirmDeleteShift = () => {
		toggleVisibility(false);
		dispatch(deleteDraftShiftAsync(shiftId));
		dispatch(editDraftShiftDialogClose());
	};

	return (
		<>
			{!isPastShift(shiftDate) ? (
				<Button
					color='primary'
					size='large'
					variant='text'
					startIcon={<CancelOutlinedIcon />}
					onClick={() => setIsDeleteConfirmationDialogOpen(true)}
					sx={{
						textTransform: "none",
						width: "100%",
						justifyContent: "flex-start",
						paddingLeft: "24px"
					}}
					disabled={
						Array.isArray(userRoles) ? userRoles.includes("ReadOnly") : false
					}
				>
					{isDraftShift ? "Delete Draft Shift" : "Cancel shift"}
				</Button>
			) : null}
			{isDeleteConfirmationDialogOpen ? (
				<ConfirmDialog
					open={isDeleteConfirmationDialogOpen}
					onClose={() => setIsDeleteConfirmationDialogOpen(false)}
					action={confirmDeleteShift}
					text={
						isDraftShift
							? "Are you sure you want to delete the draft shift?"
							: "Are you sure you want to cancel the shift?"
					}
				/>
			) : null}
		</>
	);
}

export default DeleteShift;
