import makeStyles from "@mui/styles/makeStyles";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import * as Yup from "yup";

export const useStyles = makeStyles(() => ({
	cancelButton: {
		marginRight: "auto",
	},
	dialogContentRoot: {
		maxWidth: "70%",
	},
	formControlRoot: {
		width: "100%",
		"& .MuiFilledInput-root": {
			background: "#F8F8F8 !important",
		},
	},
	btnLabel: {
		position: "relative",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	formRoot: {
		width: "100%",
	},
}));

const toLocalISOString = (date: Date) => {
	const off = date.getTimezoneOffset();
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes() - off,
		date.getSeconds(),
		date.getMilliseconds()
	).toISOString();
};

export const setToNearestTime = (dateString: string) => {
	const date = new Date(dateString);
	return toLocalISOString(date).slice(0, 16);

	/* In future if we need 15min round off */
	// const minutes = 15;
	// const ms = 1000 * 60 * minutes;
	// return toLocalISOString(new Date(Math.ceil(date.getTime() / ms) * ms)).slice(0,16);
};

export const configureDatesOfTheWeek = (date: Date): Record<string, Date> => {
	let returnValue: Record<string, Date> = {};
	const daysArray: string[] = [
		"", // To move sunday from 0 to 7 position
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday",
	];
	// Set current day to 7 if its sunday
	const currentDay: number = date.getDay() === 0 ? 7 : date.getDay();
	for (let i = 1; i < 8; i++) {
		let tempDate: Date = new Date();
		let dayModification = 0;
		if (currentDay > i) {
			// Deduct number of hours based on how off the day is
			dayModification = -1 * (currentDay - i) * 24;
		} else if (i > currentDay) {
			// Add number of hours based on how off the day is
			dayModification = (i - currentDay) * 24;
		}
		tempDate = new Date(date.getTime() + dayModification * 60 * 60 * 1000);
		returnValue[daysArray[i]] = tempDate;
	}
	// Positioning days
	returnValue = {
		monday: returnValue.monday!,
		tuesday: returnValue.tuesday!,
		wednesday: returnValue.wednesday!,
		thursday: returnValue.thursday!,
		friday: returnValue.friday!,
		saturday: returnValue.saturday!,
		sunday: returnValue.sunday!,
	};
	return returnValue;
};

export const yupInitObject = {
	startTime: Yup.string()
		.required("Start Time cannot be empty")
		.test(
			"is-greater",
			"End Time should be greater than Start Time",
			function (value: string | undefined) {
				const { endTime } = this.parent || {};
				return value && endTime
					? isAfter(new Date(endTime), new Date(value))
					: !!value;
			}
		)
		.test(
			"is-greater",
			"Start Time should be greater than current time",
			function (value: string | undefined) {
				return value ? isAfter(new Date(value), new Date()) : !!value;
			}
		),
	endTime: Yup.string()
		.required("End Time cannot be empty")
		.test(
			"is-greater",
			"End Time should be greater than Start Time",
			function (value: string | undefined) {
				const { startTime } = this.parent || {};
				return value ? isAfter(new Date(value), new Date(startTime)) : !!value;
			}
		),
	workCenterId: Yup.string().required("Workcenter is required"),
	//jobId: Yup.string().required("Job Role is required"),
	numberOfWorkersNeeded: Yup.number()
		.moreThan(0, "Minimum worker required is 1")
		.lessThan(100000, "Maximum worker required should be less than 100,000")
		.required("Number of Staff is required"),
	shiftPremium: Yup.string(),
	repeatShift: Yup.boolean(),
	shiftNote: Yup.string().max(250, "Note should be less than 250 characters"),
	repeatOn: Yup.object({
		0: Yup.boolean(),
		1: Yup.boolean(),
		2: Yup.boolean(),
		3: Yup.boolean(),
		4: Yup.boolean(),
		5: Yup.boolean(),
		6: Yup.boolean(),
	}).test(
		"at-least-one-day-selected",
		"At least select one day",
		function (value: any) {
			const { repeatShift } = this.parent || {};
			// If repeatShift toggle is false, we do not validate days at all
			if (!repeatShift) return true;
			// If repeatShift toggle is true then we validate that at least one day is true
			else {
				const flag = !!(
					value &&
					(value[0] ||
						value[1] ||
						value[2] ||
						value[3] ||
						value[4] ||
						value[5] ||
						value[6])
				);
				return flag;
			}
		}
	),
};

type Skill = {
	id: string;
	name: string;
};
interface InitValues {
	startTime: string;
	endTime: string;
	jobId: string;
	workCenterId: string;
	numberOfWorkersNeeded: string;
	shiftPremium: string;
	repeatShift: boolean;
	repeatOn: Record<number, boolean>;
	locationId: string;
	shiftSkills: Skill[] | [];
	hexColor: string;
	shiftNote: string;
}

export const getInitValues = ({
	draftShiftDate,
	defaultDurationHrs,
}: {
	draftShiftDate: Date;
	defaultDurationHrs?: number;
}): InitValues => {
	const shiftDay: number = draftShiftDate.getDay();
	const initDays: Record<number, boolean> = {
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		[shiftDay]: true,
	};

	return {
		startTime:
			format(draftShiftDate, "yyyy-MM-dd") +
			"T" +
			format(draftShiftDate, "HH:mm"),
		endTime:
			format(addHours(draftShiftDate, defaultDurationHrs || 2), "yyyy-MM-dd") +
			"T" +
			format(addHours(draftShiftDate, defaultDurationHrs || 2), "HH:mm"),
		jobId: "",
		workCenterId: "",
		locationId: "",
		numberOfWorkersNeeded: "",
		shiftPremium: "",
		repeatShift: false,
		shiftSkills: [],
		repeatOn: { ...initDays },
		hexColor: "",
		shiftNote: "",
	};
};
