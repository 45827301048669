import TextField from "@gigandtake/textfield";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const Searchbar = ({
	width,
	placeholder = "Search",
	variant = "filled",
	value,
	setSearch,
	debounce = false,
	debTime = 500,
	label = true,
	...props
}) => {
	const [displayText, setDisplayText] = useState(value);
	const [debouncedText] = useDebounce(displayText, debounce ? debTime : 0);
	useEffect(() => {
		setSearch(debouncedText);
	}, [debouncedText, setSearch]);
	return (
		<Box
			sx={{
				"& .MuiTextField-root": {
					m: 1,
					width: width ? width : "100%",
				},
				flex: !width ? 1 : "initial",
			}}
		>
			<TextField
				label={label ? "Search" : null}
				hiddenLabel={false}
				placeholder={placeholder}
				variant={variant}
				value={displayText}
				size='small'
				InputProps={{
					endAdornment: displayText !== undefined && displayText.length > 0 && (
						<CancelOutlinedIcon
							sx={{ cursor: "pointer", color: "#92918f" }}
							onClick={() => setDisplayText("")}
						/>
					),
				}}
				onChange={(e) => {
					setDisplayText(e.target.value);
				}}
				sx={{
					"& .MuiFilledInput-underline:before": {
						borderBottom: "none",
					},
					"& .MuiFilledInput-underline:after": {
						borderBottom: "none",
					},
					paddingTop: !label && "4px",
				}}
				{...props}
			/>
		</Box>
	);
};

export default Searchbar;
