// TODO - Type 'any' needs to be fixed.
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import pluralize  from "pluralize";
import React, { useEffect, useState } from "react";

import {
  JobRoleList,
  SkillList,
  SwitchShiftsFilter,
} from "../../../../../../export/shift-scheduler";
import { JOB, SKILLS } from "../../../../../../ts/assets/constants";
import {capitalizeFirstLetter} from "../../../../../utils/helpers";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import {CopyShiftsDataBySkillsProps, CopyShiftsDataProps, Job, Skill} from "./types";
import ViewSummary from "./view-summary";
import WeekNavigator from "./week-navigator";

interface CopyShiftsDialogFormProps {
  checkAll: boolean;
  skillCheckAll: boolean;
  jobs: any;
  skills: any;
  copiedShiftData: CopyShiftsDataProps[];
  copiedShiftDataBySkills: CopyShiftsDataBySkillsProps[];
  setWeekCopyDate: any;
  handleCheckAll: any;
  handleSkillCheckAll: any;
  handleClick: any;
  handleSkillClick: any;
  getDateRangeText: any;
  weekCopyDate: any;
  dispatch: any;
  fetchWeekShifts: any;
}

const CopyShiftsDialogForm: React.FC<CopyShiftsDialogFormProps> = ({
  checkAll,
  skillCheckAll,
  jobs,
  skills,
  copiedShiftData,
  copiedShiftDataBySkills,
  setWeekCopyDate,
  handleCheckAll,
  handleSkillCheckAll,
  handleClick,
  handleSkillClick,
  getDateRangeText,
  weekCopyDate,
  dispatch,
  fetchWeekShifts,
}) => {
  const {
    filterJobRoles,
    filterAllSkills,
    weekShiftsStatus,
    shiftsByFilter,
    shiftBySkills,
  } = useSchedulerData();
  const [filteredJobRoles, setFilteredJobRoles] = useState<Job[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<Skill[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    try {
      if (filterJobRoles && filterJobRoles.length > 0) {
        if (search === "") {
          setFilteredJobRoles(filterJobRoles);
        } else {
          // Search in Job Name
          let result = [
            ...filterJobRoles.filter((x: { name: string }) =>
              x.name.toLowerCase().includes(search.toLowerCase())
            ),
          ];
          // Remove duplicate entries
          result = result.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setFilteredJobRoles(result);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [filterJobRoles, search]);

  useEffect(() => {
    try {
      if (filterAllSkills && filterAllSkills.length > 0) {
        if (search === "") {
          setFilteredSkills(filterAllSkills);
        } else {
          // Search in Job Name
          let result = [
            ...filterAllSkills.filter((x: { name: string }) =>
              x.name.toLowerCase().includes(search.toLowerCase())
            ),
          ];
          // Remove duplicate entries
          result = result.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          setFilteredSkills(result);
        }
      }
    } catch (e) {
      // Do nothing
    }
  }, [filterAllSkills, search]);

  useEffect(() => {
    // Reset search on tab switch
    setSearch("");
  }, [shiftsByFilter]);

  return (
    <Box
      width={1000}
      display="flex"
      flexDirection="column"
      mr="auto"
      ml="auto"
      mb={-5}
    >
      <Box width="100%" display="flex">
        <Box width={400} mr={2}>
          <Typography variant="body2">
            Select a past week with existing shifts
          </Typography>
          <Box mt={1} ml={-3}>
            {/* <WeekDatePicker setWeekCopyDate={setWeekCopyDate} /> */}
            <WeekNavigator
              date={new Date(weekCopyDate.start)}
              getDateRangeText={getDateRangeText}
              setWeekCopyDate={setWeekCopyDate}
              weekCopyDate={weekCopyDate}
              dispatch={dispatch}
              fetchWeekShifts={fetchWeekShifts}
            />
          </Box>
          {shiftBySkills && (
            <Box mt={2} ml={-3} mr={-2}>
              <SwitchShiftsFilter shiftsByFilter={shiftsByFilter} />
            </Box>
          )}
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              height: "57px",
              flex: 1,
            }}
          >
            <TextField
              hiddenLabel
              value={search}
              onChange={(e) => {
                const text = e.target.value;
                setSearch(text);
              }}
              placeholder={`Search ${pluralize.singular(capitalizeFirstLetter(shiftsByFilter, true))}`}
              variant="filled"
              size="small"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <SearchIcon
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "rgba(0,0,0,0.56)",
                    }}
                  />
                ),
                endAdornment:
                  search !== "" ? (
                    <ClearIcon
                      onClick={() => {
                        setSearch("");
                      }}
                      style={{
                        height: "24px",
                        width: "24px",
                        color: "rgba(0,0,0,0.56)",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <></>
                  ),
              }}
              sx={{
                width: "100%",
                border: "1px solid rgba(0,0,0,0.12)",
                borderRadius: "4px",
                input: {
                  paddingLeft: "8px",
                  color: "rgba(0,0,0,0.87)",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0.4px",
                },
              }}
            />
          </Box>
          <Box
            mt={2}
            ml={-3}
            mr={-2}
            height={250}
            sx={{ overflowY: "scroll", border: "none" }}
          >
            <Box display="flex" width="100%" height="auto" mb={2}>
              <Box
                display="flex"
                flexWrap="wrap"
                height="auto"
                borderRadius={2}
                width="100%"
              >
                {shiftsByFilter === JOB && (
                  <>
                    {filteredJobRoles.length > 3 && (
                      <Box width="100%" ml={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkAll}
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) =>
                                handleCheckAll(
                                  (e.target as HTMLInputElement).checked
                                )
                              }
                              inputProps={{ "aria-label": "uncontrolled" }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Roboto",
                                marginLeft: "3px",
                              }}
                            >
                              Select all
                            </Typography>
                          }
                        />
                      </Box>
                    )}
                    <JobRoleList
                      onSelectCheckbox={handleClick}
                      state={jobs}
                      jobRoles={filteredJobRoles}
                      search={search}
                    />
                  </>
                )}
                {shiftsByFilter === SKILLS && (
                  <>
                    {filteredSkills.length > 3 && (
                      <Box width="100%" ml={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={skillCheckAll}
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) =>
                                handleSkillCheckAll(
                                  (e.target as HTMLInputElement).checked
                                )
                              }
                              inputProps={{ "aria-label": "uncontrolled" }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Roboto",
                                marginLeft: "3px",
                              }}
                            >
                              Select all
                            </Typography>
                          }
                        />
                      </Box>
                    )}
                    <SkillList
                      search={search}
                      allSkills={filterAllSkills}
                      onSelectSkillCheckbox={handleSkillClick}
                      skillState={skills}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box ml={2} width="100%">
          <ViewSummary
            allWeekShifts={copiedShiftData}
            allWeekShiftsBySkills={copiedShiftDataBySkills}
            weekShiftsStatus={weekShiftsStatus}
            noJobSelected={Object.values(jobs).every((v) => v === false)}
            noSkillSelected={Object.values(skills).every((v) => v === false)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CopyShiftsDialogForm;
