import { Box, Switch, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Priority} from "../../../../../../utils/types";
import {
    getSkillPriorities,
    setCrossSkillingPrioritiesDialog
} from "../../../../store/gat-admin-slice";
import SkillPrioritiesPlaceholder
    from "./skill-priorities-placeholder";
import SkillPriorityConfigDialog
    from "./skill-priority-config-dialog";
import SkillPriorityTable
    from "./skill-priority-table";

const SkillPriorities = ({
                         skillLevels,
                         newAddOnSettings,
                         setNewAddOnSettings,
                             companyId,
                     }: any) => {
    const crossSkillingEnabled = newAddOnSettings.crossSkillingEnabled;
    const skillPriorityEnabled = newAddOnSettings.skillPriorityEnabled;

    const skillPriorities: Priority[] = useSelector(
        (state: any) => state.gatAdminConfiguration.skillPriorities
    );

    const getSkillPrioritiesStatus = useSelector(
        (state: any) => state.gatAdminConfiguration.getSkillPriorities.status
    );

    const dispatch = useDispatch();
    const openCrossSkillingPrioritiesEditDialog = (open: boolean) => {
        dispatch(setCrossSkillingPrioritiesDialog(open));
    };

    useEffect(() => {
        dispatch(getSkillPriorities(companyId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                marginTop: "20px",
                marginBottom: "8px",
                borderRadius: "8px",
                border: 1,
                borderWidth: "1px",
                borderColor: "rgba(0,0,0,0.12)",
                background: "#FFF",
                padding: "16px",
                opacity: !crossSkillingEnabled || skillLevels.length <= 0 ? 0.5 : 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 16px",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        Skill Priority
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: 400,
                            fontSize: "12px",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Configure Priority for Skills for the Company.
                    </Typography>
                </Box>
                <Switch
                    checked={skillPriorityEnabled ?? false}
                    disabled={!crossSkillingEnabled || skillLevels.length === 0}
                    onChange={(e) => {
                        setNewAddOnSettings((pre: any) => {
                            return {
                                ...pre,
                                skillPriorityEnabled: e.target.checked,
                            };
                        });
                    }}
                />
            </Box>
            {getSkillPrioritiesStatus === "pending" && (
                <LinearProgress />
            )}
            {getSkillPrioritiesStatus === "fulfilled" && (
                <>
                    {skillPriorityEnabled && (
                        <>{skillPriorities.length === 0 ? (
                            <SkillPrioritiesPlaceholder openCrossSkillingPrioritiesEditDialog={openCrossSkillingPrioritiesEditDialog} />
                        ) : (
                            <SkillPriorityTable openCrossSkillingPrioritiesEditDialog={openCrossSkillingPrioritiesEditDialog} skillPriorities={skillPriorities}/>
                        )}</>
                    )}
                </>
            )}
        </Box>
    );
};

export default SkillPriorities;