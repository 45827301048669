// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import DialogWrapper from "@gigandtake/dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { isSunday, isThisWeek } from "date-fns";
import addDays from "date-fns/addDays";
import isPast from "date-fns/isPast";
import startOfWeek from "date-fns/startOfWeek";
import { useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchWeekShifts } from "../../../../../../export/scheduler";
import { useScheduleController } from "../../../../../../export/scheduler";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../../../../utils/helpers";
import ActionButtons from "./action-buttons";
import useCopyShiftsDialog from "./controllers/use-copy-shifts-dialog";
import CopyShiftsDialogForm from "./copy-shifts-dialog-form";
interface CopyShiftDialogProps {
  handleClose: () => void;
  open: boolean;
  weekPasteDate: { startDate: Date; endDate: Date };
}
const CopyShiftDialog: React.FC<CopyShiftDialogProps> = ({
  handleClose,
  open,
  weekPasteDate,
}) => {
  const dispatch = useDispatch();
  const [
    {
      checkAll,
      skillCheckAll,
      jobs,
      skills,
      checkedUser,
      copiedShiftData,
      copiedShiftDataBySkills,
      datesOfTheWeek,
      weekCopyDate,
      shiftsByFilter,
    },
    {
      setWeekCopyDate,
      handleSkillCheckAll,
      handleCheckAll,
      handleClick,
      handleSkillClick,
      setCheckedUser,
    },
  ] = useCopyShiftsDialog({
    weekPasteDate,
  });

  const [, { getDateRangeText }] = useScheduleController();

  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );

  useEffect(() => {
    dispatch(
      fetchWeekShifts({
        startDate: addDays(startOfWeek(new Date()), 1),
        endDate: addDays(startOfWeek(new Date()), 7),
      })
    );
  }, [dispatch]);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && handleClose) {
          handleClose();
        }
      }}
      aria-labelledby="add-new-shift"
      open={open}
      maxWidth={false}
    >
      {isThisWeek(new Date(currentDate)) ||
      isPast(new Date(currentDate)) ||
      isSunday(new Date(currentDate)) ? (
        <DialogTitle id="cannot-copy-shifts">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width={800}
          >
            <Box>
              <Typography variant="h6">
                Sorry! Shifts can only be copied into future week.
              </Typography>
            </Box>
            <IconButton
              edge="start"
              // className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
      ) : (
        <DialogWrapper
          dialogTitle="Copy weekly shifts"
          customStyles={{}}
          dialogSubTitle={`Copy shifts from a past week to a future week - ${
            // getDateRangeText && capitalizeFirstLetter
            // 	? capitalizeFirstLetter(
            // 			getDateRangeText(datesOfTheWeek["monday"]),
            // 			true
            // 	  )
            // 	: ""
            formatDate({
              dateStr: datesOfTheWeek["monday"],
              formatType: "DATE_RANGE",
              endDateStr: datesOfTheWeek["sunday"],
            })
          }`}
          dialogContent={
            <CopyShiftsDialogForm
              setWeekCopyDate={setWeekCopyDate}
              handleCheckAll={handleCheckAll}
              handleClick={handleClick}
              checkAll={checkAll ?? true}
              jobs={jobs}
              skills={skills}
              copiedShiftData={copiedShiftData ?? []}
              copiedShiftDataBySkills={copiedShiftDataBySkills ?? []}
              getDateRangeText={getDateRangeText}
              weekCopyDate={weekCopyDate}
              fetchWeekShifts={fetchWeekShifts}
              dispatch={dispatch}
              handleSkillCheckAll={handleSkillCheckAll}
              handleSkillClick={handleSkillClick}
              skillCheckAll={skillCheckAll ?? true}
            />
          }
          actionButtons={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              height={20}
              mt={-3}
            >
              <Box display="flex" width="100%">
                <FormGroup>
                  <Box display="flex" flexWrap="wrap" height="auto">
                    <Box width="100%">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedUser}
                            onClick={() =>
                              setCheckedUser &&
                              setCheckedUser((prev: boolean) => !prev)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Shifts created by me"
                      />
                    </Box>
                  </Box>
                </FormGroup>
              </Box>
              <ActionButtons
                handleClose={handleClose}
                copiedShiftData={copiedShiftData ?? []}
                copiedShiftDataBySkills={copiedShiftDataBySkills ?? []}
                shiftsByFilter={shiftsByFilter}
              />
            </Box>
          }
        />
      )}
    </Dialog>
  );
};

export default CopyShiftDialog;
