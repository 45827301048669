/* eslint-disable no-mixed-spaces-and-tabs */
import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Highlight from "../../../../../Highlight";
import { openUserDeactivateDialog } from "../../../../store/gat-admin-slice";
import MyChip from "../my-chip";

const useSupervisorColumns = (searchText: string) => {
	const dispatch = useDispatch();
	const [expandWorkcenters, setExpandWorkcenters] = useState<number[]>([]);

	const onClickViewMoreWorkCenters = (id: number) => {
		const selectedWorkcentersList = expandWorkcenters.includes(id)
			? expandWorkcenters.filter((item) => item !== id)
			: [...expandWorkcenters, id];

		setExpandWorkcenters(selectedWorkcentersList);
	};

	const columns: GridColDef[] = [
		// {
		// 	field: "id", headerName: "ID", minWidth: 70, hide: true,
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		return (
		// 			<Tooltip title={params.row.id}>
		// 				<Typography
		// 					sx={{
		// 						fontFamily: "Roboto",
		// 						fontSize: "14px",
		// 						color: "rgba(0, 0, 0, 0.87)",
		// 					}}
		// 				>
		// 					<Highlight value={params.row.id} searchText={searchText} />
		// 				</Typography>
		// 			</Tooltip>
		// 		);
		// 	},
		// },
		{
			field: "name",
			flex: 1,
			headerName: "Full Name",
			minWidth: 200,
			valueGetter: (params) => {
				return `${params?.row?.firstName} ${params?.row?.lastName}`;
			},
			renderCell: (params) => {
				const fullName = `${params.row.firstName} ${params.row.lastName}`;
				return (
					<Tooltip title={fullName}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight value={fullName} searchText={searchText} />
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 250,
			flex: 1,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.email}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
								display: "flex",
								flexWrap: "wrap",
								maxWidth: "100%",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							<Highlight value={params.row.email} searchText={searchText} />
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "userRole",
			headerName: "User Role",
			minWidth: 80,
			flex: 1,
			renderCell: (params) => {
				// As per requirment - present its limit to show Readonly/Supervisor roles. The rest of the roles can come when we extend to Company Admin / Location Admin kind of roles.
				const role = params.row.roles.find(
					(x: { id: number; name: string }) => x.name === "ShiftSupervisor"
				)
					? "Supervisor"
					: params.row.roles.find(
							(x: { id: number; name: string }) => x.name === "ReadOnly"
					  )
					? "Read Only"
					: "";
				return (
					<Tooltip title={role}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight value={role} searchText={searchText} />
						</Typography>
					</Tooltip>
				);
			},
			valueGetter: (params) => {
				const role = params.row.roles.find(
					(x: { id: number; name: string }) => x.name === "ShiftSupervisor"
				)
					? "Supervisor"
					: params.row.roles.find(
							(x: { id: number; name: string }) => x.name === "ReadOnly"
					  )
					? "Read Only"
					: "";
				return role;
			},
		},
		{
			field: "workcenters",
			headerName: "Workcenters",
			minWidth: 100,
			headerAlign: "center",
			align: "center",
			flex: 1,
			valueGetter: (params) => {
				return `${params.row.userWorkCenters.length}`;
			},
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.userWorkCenters.length}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							<Highlight
								value={params.row.userWorkCenters.length}
								searchText={searchText}
							/>
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "userWorkCenters",
			headerName: "Workcenter Names",
			minWidth: 360,
			flex: 2,
			renderCell: (params) => {
				return (
					<Box>
						<MyChip
							searchText={searchText}
							userWorkCenters={params.row.userWorkCenters}
							rowId={params.row.id}
							expandWorkcenters={expandWorkcenters}
							onClickViewMoreWorkCenters={onClickViewMoreWorkCenters}
						/>
					</Box>
				);
			},
			valueGetter: (params) => params.row.userWorkCenters?.length,
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: 120,
			flex: 1,
			renderCell: (params) => {
				return (
					<Chip
						size='small'
						label={
							<Highlight
								value={params.row.isActive ? "Active" : "Inactive"}
								searchText={searchText}
							/>
						}
						variant='filled'
						style={{
							marginTop: "4px",
							marginBottom: "4px",
							marginRight: "4px",
						}}
						sx={{
							paddingRight: "8px",
							paddingLeft: "8px",
							background: params.row.isActive ? "#E8F5E9" : "#FEEBEE",
						}}
					/>
				);
			},
			valueGetter: (params) => params.row.isActive,
		},
		{
			field: "icon",
			headerName: "Actions",
			headerAlign: "right",
			align: "right",
			width: 70,
			cellClassName: "hover-column-cell",
			headerClassName: "hover-column-header",
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<span
					style={{
						fontWeight: "bold",
						width: "74px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{""}
				</span>
			),
			renderCell: (params) => (
				<Box
					className='hover-content'
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-around",
						width: "100px",
						padding: "12px 0px 2px 0px",
						zIndex: 1,
					}}
				>
					<Box
						sx={{
							color: "#6c6c6c",
							"&:hover": {
								color: "#000000",
								transform: "scale(1.2)",
							},
							cursor: "pointer",
						}}
					>
						<Tooltip title='Edit'>
							<CreateIcon
								id={`edit-job-${params?.row?.id}-enabled`}
								fontSize='small'
								sx={{ cursor: "pointer" }}
								onClick={(e) => {
									params.row.changeCompanyUserData(params.row);
									e.stopPropagation();
								}}
							/>
						</Tooltip>
					</Box>
					<Box
						sx={{
							color: "#6c6c6c",
							"&:hover": {
								color: "#000000",
								transform: "scale(1.2)",
							},
							cursor: "pointer",
						}}
					>
						<Tooltip title={params.row.isActive ? "Deactivate" : "Activate"}>
							{params.row.isActive ? (
								<PersonOffIcon
									id={`activate-job-${params?.row?.id}-enabled`}
									fontSize='small'
									sx={{ cursor: "pointer" }}
									onClick={(e) => {
										// handleStatus(params.row);

										dispatch(
											openUserDeactivateDialog({
												id: params.row.id,
												isActive: params.row.isActive,
												associatedUsers: params.row.associatedUsers ?? [],
												username: `${params.row.firstName} ${params.row.lastName}`,
												assignedWorkers: params.row.allAssignedWorkersCount,
											})
										);
										e.stopPropagation();
									}}
								/>
							) : (
								<PersonIcon
									id={`edit-job-${params?.row?.id}-enabled`}
									fontSize='small'
									sx={{ cursor: "pointer" }}
									onClick={(e) => {
										// handleStatus(params.row);
										dispatch(
											openUserDeactivateDialog({
												id: params.row.id,
												isActive: params.row.isActive,
												associatedUsers: params.row.associatedUsers ?? [],
												username: `${params.row.firstName} ${params.row.lastName}`,
												assignedWorkers: params.row.allAssignedWorkersCount,
											})
										);

										e.stopPropagation();
									}}
								/>
							)}
						</Tooltip>
					</Box>
				</Box>
			),
		},
	];

	return columns;
};

export default useSupervisorColumns;
