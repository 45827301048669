// TODO - Type 'any' needs to be fixed.
import {
	AssessmentOutlined as AssessmentOutlinedIcon,
	DateRange as DateRangeIcon,
	ExitToApp as ExitToAppIcon,
	HelpOutlineOutlined,
	LockReset as LockResetIcon,
	ManageAccountsOutlined as ManageAccountsOutlinedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	SwapHoriz as SwapHorizIcon,
	Today as TodayIcon,
	WorkOutlineOutlined as WorkOutlinedIcon,
} from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";

import { StoreType } from "../../../utils/types";
// Enable only for boeing
import powerBILogo from "../assets/dashboard.png";

const logOut = {
	id: 7,
	name: "Log Out",
	url: "/login",
	icon: <ExitToAppIcon />,
};

const skillsMatrix = {
	id: 99,
	name: "Skills Matrix (Beta)",
	url: "/skills-matrix",
	icon: <WorkOutlinedIcon />,
};

const shiftSwap = {
	id: 8,
	name: "Worker Requests",
	url: "/worker-requests",
	icon: <SwapHorizIcon />,
};

const crossSkilling = {
	id: 6,
	name: "Cross Skilling",
	url: "/cross-skilling",
	icon: <WorkOutlinedIcon />,
};

const adminConfiguration = {
	id: 5,
	name: "Admin Configuration",
	url: "/admin-configuration",
	icon: <ManageAccountsOutlinedIcon />,
};

const settings = {
	id: 3,
	name: "Settings",
	icon: <SettingsOutlinedIcon />,
	subMenu: [] as any[], // Fix the type if you have it
};

const flexScheduler = {
	id: 2,
	name: "Flex Schedule",
	url: "/flex-schedule",
	icon: <DateRangeIcon />,
};

const fullTimeScheduler = {
	id: 1,
	name: "Full-Time Schedule",
	url: "/full-time-schedule",
	icon: <TodayIcon />,
};

// Enable only for boeing
const powerBI = {
	id: 9,
	name: "Dashboard",
	url: "https://app.powerbi.com/groups/me/apps/29e3957d-de20-4d27-a712-eae041e0b6cf/reports/ba7a13f6-f26a-4052-9f79-880c789b48c6/ReportSection47e6b1cfff6775079a30?ctid=b73ab130-8864-4872-85ed-e9e022d489fd&experience=power-bi",
	icon: <img src={powerBILogo} alt='' height={24} />,
};

const workCenters = { id: 11, name: "Work Centers", url: "/workcenters" };
const skills = { id: 10, name: "Skills", url: "/manage-skills" };
const jobRoles = { id: 13, name: "Job Roles", url: "/manage-jobs" };
const workers = { id: 12, name: "Workers", url: "/manage-workers" };
const certifications = {
	id: 16,
	name: "Certifications",
	url: "/certifications",
};

const help = {
	id: 14,
	name: "Help",
	url: "mailto:help@wilya.com",
	icon: <HelpOutlineOutlined />,
};

const demoReset = {
	id: 15,
	name: "Demo Reset",
	url: "/demo-reset",
	icon: <LockResetIcon />,
};

const reports = {
	id: 4,
	name: "Reports",
	url: "/reports",
	icon: <AssessmentOutlinedIcon />,
};

const reportsMenu = {
	id: 19,
	name: "Reports",
	icon: <AssessmentOutlinedIcon />,
	subMenu: [] as any[], // Fix the type if you have it
};

const attendanceReports = {
	id: 17,
	name: "Attendance Reports",
	url: "/reports",
};

const skillInsights = {
	id: 18,
	name: "Skill Summary Reports",
	url: "/insight",
};

const absenceReports = {
	id: 20,
	name: "Absence Reports",
	url: "/absence-reports",
};

interface UseDrawerControllerResult {
	topMenuItems: any[]; // Fix the type if you have it
	bottomMenuItems: any[]; // Fix the type if you have it
	defaultSelectedMenuItem: number;
}

const useDrawerController = (): UseDrawerControllerResult => {
	const showCrossSkilling = useSelector(
		(state: StoreType) => state.user.userData.showCrossSkilling
	);
	const certificationsEnabled = useSelector(
		(state: StoreType) => state.user.userData.certificationsEnabled
	);
	const skillExpiryEnabled = useSelector(
		(state: StoreType) => state.user.userData.skillExpiryEnabled
	);
	const defaultUserView = useSelector(
		(state: StoreType) => state.user.userData.defaultUserView
	);
	const showShiftSwap = useSelector(
		(state: StoreType) => state.user.userData.showShiftSwap
	);
	const fullTimeSchedule = useSelector(
		(state: StoreType) => state.user.userData.fullTimeSchedule
	);
	const roles = useSelector((state: StoreType) => state.user?.userData?.userRoles);

	const userInfo = useSelector((state: StoreType) => state.user?.userData);

	const isSkillInsightsEnabled = useSelector(
		(state: StoreType) => state.user.userData.skillInsightsEnabled
	);

	const isAttendanceManagementEnabled = useSelector(
		(state: StoreType) => state.user.userData.attendanceManagement
	);

	const isSwapAndCancelShiftEnabled = useSelector(
		(state: StoreType) => state.user.userData.swapAndCancelShift
	);

	// Configuring menu items
	const topMenuItems: any[] = [];
	const bottomMenuItems: any[] = [];
	let defaultSelectedMenuItem = 2;

	if (fullTimeSchedule) {
		topMenuItems.push(fullTimeScheduler);
		defaultSelectedMenuItem = 1;
	}

	topMenuItems.push(flexScheduler);

	if (showCrossSkilling) {
		// PART OF HOTFIX - DEMO - GAT-3286
		// if (fullTimeSchedule) {
		// 	crossSkilling.name = "Certifications";
		// }
		if (defaultUserView === "CrossSkilling") {
			// If default view is cross-skilling and show cross-skilling is enabled
			defaultSelectedMenuItem = 6;
		}

		topMenuItems.push(crossSkilling);
		// login as bilal@gigandtake
		userInfo.id === 5 &&
			userInfo.username === "bilal@gigandtake.com" &&
			topMenuItems.push(skillsMatrix);
	}

	if (showShiftSwap) {
		topMenuItems.push(shiftSwap);
	}

	// Enable only for Boeing
	if (fullTimeSchedule) {
		topMenuItems.push(powerBI);
	}

	//Add Reports just before the settings menu
	if (showCrossSkilling && isSkillInsightsEnabled) {
		//Add Attendance reports and skill insights
		reportsMenu.subMenu = [attendanceReports, skillInsights];
		if(isSwapAndCancelShiftEnabled && isAttendanceManagementEnabled) {
			reportsMenu.subMenu.push(absenceReports);
		}
		topMenuItems.push(reportsMenu);
	} else {
		//Add only attendance reports
		if(!isAttendanceManagementEnabled || !isSwapAndCancelShiftEnabled) {
			reportsMenu.subMenu = [attendanceReports];
			topMenuItems.push(reportsMenu);
		} else {
			reportsMenu.subMenu = [attendanceReports, absenceReports];
			topMenuItems.push(reportsMenu);
		}
	}

	// Settings
	settings.subMenu = [workers, jobRoles];
	if (showCrossSkilling) {
		settings.subMenu.push(skills);
	}
	if (certificationsEnabled) {
		settings.subMenu.push(certifications);
	}
	settings.subMenu.push(workCenters);
	topMenuItems.push(settings);

	if (roles.includes("GATAdmin") || roles.includes("Admin")) {
		topMenuItems.push(adminConfiguration);
	}

	if (roles.includes("DemoResetRole")) {
		topMenuItems.push(demoReset);
	}

	bottomMenuItems.push(help);
	bottomMenuItems.push(logOut);

	return { topMenuItems, bottomMenuItems, defaultSelectedMenuItem };
};

export default useDrawerController;
