// TODO - Type 'any' needs to be fixed.
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";

const ContractuallyAllowedLocation = ({
	newCoreSettings,
	setNewCoreSettings,
	getCompanySettingsStatus,
}: any) => {
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Number of locations
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Maximum number of locations that can be created under this company.
					</Typography>
				</Box>
				{getCompanySettingsStatus === "pending" ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							ml: 2,
						}}
					>
						<CircularProgress size={25} />
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							mr={1}
							sx={{
								cursor: "pointer",
							}}
							//  disabled={Number(newCoreSettings.maxLocationsByContract) === 0} //disabled property is not there for Box
							onClick={() => {
								if (Number(newCoreSettings.maxLocationsByContract) === 0) {
									return;
								} else {
									setNewCoreSettings({
										...newCoreSettings,
										maxLocationsByContract:
											Number(newCoreSettings.maxLocationsByContract) - 1,
									});
								}
							}}
						>
							<RemoveIcon />
						</Box>
						<Box mr={1} ml={1}>
							<TextField
								id='number-of-minimum-hours'
								variant='filled'
								type='number'
								onFocus={(event) => {
									event.target.select();
								}}
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
										padding: "8px",
									},
									width: "40px",
								}}
								onBlur={() => {
									/* Do Nothing */
								}}
								onChange={(event) => {
									if (parseInt(event.target.value) < 0) {
										return;
									} else {
										setNewCoreSettings({
											...newCoreSettings,
											maxLocationsByContract: parseInt(event.target.value),
										});
									}
								}}
								value={newCoreSettings.maxLocationsByContract ?? 0}
								error={newCoreSettings.maxLocationsByContract < 0}
								helperText={""}
							/>
						</Box>
						<Box
							ml={1}
							sx={{
								cursor: "pointer",
							}}
							onClick={() =>
								setNewCoreSettings({
									...newCoreSettings,
									maxLocationsByContract:
										Number(newCoreSettings.maxLocationsByContract) + 1,
								})
							}
						>
							<AddIcon />
						</Box>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ContractuallyAllowedLocation;
