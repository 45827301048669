import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";

import {colors} from "../../../../../../config/colors";

interface CrossSkillingPrioritiesPlaceholderProps {
    openCrossSkillingPrioritiesEditDialog: (open: boolean) => void;
}

const CrossSkillingWeightsPlaceholder: FC<CrossSkillingPrioritiesPlaceholderProps> = ({ openCrossSkillingPrioritiesEditDialog }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "20px",
                marginBottom: "8px",
                borderRadius: "3px",
                border: 1,
                borderWidth: "1px",
                borderColor: colors.adminPlaceholderBorder,
                backgroundColor: colors.adminPlaceholderBackground,
                padding: "17px",
            }}
        >
            <Typography
                sx={{
                    color: "rgba(0, 0, 0, 0.60)",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: "400",
                }}
            >
                Skill Priority Levels missing. Click the button to setup Priority Levels.
            </Typography>
            <Button
                variant='contained'
                sx={{
                    marginLeft: "12px",
                }}
                onClick={() => openCrossSkillingPrioritiesEditDialog(true)}
            >
                Configure Skill Priority
            </Button>
        </Box>
    );
};

export default CrossSkillingWeightsPlaceholder;