import { Box, Menu, MenuItem, Typography } from "@mui/material";
import {useCallback, useMemo, useState} from "react";
import React from "react";

import { getRepLevel, getRepType } from "../../../../../utils/helpers";
import { MIN_CROSS_SKILL_ROW_HEIGHT } from "../../../constants/constants";
import SkillRepresentationCell from "./column-skill-representations-cell";

const ColumnSkillCell = ({
	params,
	skill,
	handleSkillLevels,
	allSkillLevels,
	handleAddSkillInterested,
	skillsUpdatedListInSession
}) => {
	const skillId = skill.skillId ?? skill.id;
	const workerSkill = params.row.skills.find((x) => x.skillId === skillId);

	const isInterested = params.row.skillInterests ? params.row.skillInterests.find(
		(x) => parseInt(x.skillId) === skillId
	) : false;

	const [state, setState] = useState(workerSkill);
	const [workerSkillsAnchorEl, setWorkerSkillsAnchorEl] = useState(null);
	const [otherSkillsAnchorEl, setOtherSkillsAnchorEl] = useState(null);
	const openWorkerSkills = Boolean(workerSkillsAnchorEl);
	const openOtherSkills = Boolean(otherSkillsAnchorEl);
	const handleWorkerSkillsClick = (event) => {
		setWorkerSkillsAnchorEl(event.currentTarget);
	};
	const handleWorkerSkillsClose = () => {
		setWorkerSkillsAnchorEl(null);
	};

	const updateSkillLevel = useCallback((params, skill, level, state, handleClose) => {
		handleSkillLevels(params, skill, level, setState, state);
		handleClose();
	},[handleSkillLevels]);

	const skillLevelMenu = useMemo(
		() => (
			<Menu
				id='worker-skills-menu'
				anchorEl={workerSkillsAnchorEl}
				open={openWorkerSkills}
				onClose={handleWorkerSkillsClose}
				MenuListProps={{
					"aria-labelledby": "worker-skills-button",
				}}
			>
				{allSkillLevels.map((level) => (
					<MenuItem
						value={level.repCode}
						key={level.id}
						onClick={() =>
							updateSkillLevel(
								params,
								skill,
								level,
								state,
								handleWorkerSkillsClose
							)
						}
					>
						<Box display='flex' flexDirection='row' alignItems='center'>
							<SkillRepresentationCell
								level={level.level}
								value={level.value}
								backgroundColor={level.backColorCode}
								foregroundColor={level.foreColorCode}
								representationType={getRepType(level.repCode)}
								representationLevel={getRepLevel(level.repCode, level.level)}
								border={1}
								backgroundWidth={"35px"}
								backgroundHeight={"35px"}
							/>
							<Typography ml={1}>{level.name}</Typography>
						</Box>
					</MenuItem>
				))}
			</Menu>
		),
		[workerSkillsAnchorEl, openWorkerSkills, allSkillLevels, updateSkillLevel, params, skill, state]
	);

	if (!state && isInterested) {
		return (
			<Box
				sx={{
					borderRadius: "3px",
					// paddingRight: "24px",
					// marginRight: "-34px", // Workaround to get Kabab menu in cell
					height: MIN_CROSS_SKILL_ROW_HEIGHT,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					width: "100%",
				}}
				key={`${skill.skillId}-interested`}
				onClick={() => handleAddSkillInterested(params, skill, setState)}
			>
				<Typography
					textAlign='center'
					sx={{ fontSize: "12px", color: "#49454F" }}
				>
					Interested
				</Typography>
			</Box>
		);
	}

	//when the skill level is changed for one job, the same skill in other jobs does not get updated immediately. This causes discrepancies in skill levels across different jobs that share the same skill.So this list is used to update the skills in other jobs too
	const existingSkillUPdated = skillsUpdatedListInSession.find((x)=> x.workerId === params.row.id && x.skillId === skill.skillId);

	if (existingSkillUPdated) {
		return (
			<>
				<Box
					aria-controls={openWorkerSkills ? "worker-skills-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={openWorkerSkills ? "true" : undefined}
					onClick={handleWorkerSkillsClick}
					sx={{
						cursor: "pointer",
						width: "100%",
						height: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							height: "100%",
						}}
					>
						<SkillRepresentationCell
							representationType={getRepType(existingSkillUPdated.repCode)}
							representationLevel={getRepLevel(existingSkillUPdated.repCode, existingSkillUPdated.level)}
							value={existingSkillUPdated.value}
							level={existingSkillUPdated.level}
							backgroundColor={existingSkillUPdated.backColorCode}
							foregroundColor={existingSkillUPdated.foreColorCode}
							height={20}
							width={20}
							backgroundWidth={"100%"}
							backgroundHeight={"100%"}
						/>
					</Box>
				</Box>
				{skillLevelMenu}
			</>
		);
	} 

	if (!state)
		return (
			<>
				<Box
					aria-controls={openOtherSkills ? "other-skills-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={openOtherSkills ? "true" : undefined}
					onClick={handleWorkerSkillsClick}
					sx={{
						cursor: "pointer",
						width: "100%",
						height: "100%",
					}}
				>
					<Box
						sx={{
							height: MIN_CROSS_SKILL_ROW_HEIGHT,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "14px",
								textTransform: "uppercase",
							}}
						>
							-
						</Typography>
					</Box>
				</Box>
				{skillLevelMenu}
			</>
		);
	else {
		return (
			<>
				<Box
					aria-controls={openWorkerSkills ? "worker-skills-menu" : undefined}
					aria-haspopup='true'
					aria-expanded={openWorkerSkills ? "true" : undefined}
					onClick={handleWorkerSkillsClick}
					sx={{
						cursor: "pointer",
						width: "100%",
						height: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							height: "100%",
						}}
					>
						<SkillRepresentationCell
							representationType={getRepType(state.repCode)}
							representationLevel={getRepLevel(state.repCode, state.level)}
							value={state.value}
							level={state.level}
							backgroundColor={state.backColorCode}
							foregroundColor={state.foreColorCode}
							height={20}
							width={20}
							backgroundWidth={"100%"}
							backgroundHeight={"100%"}
						/>
					</Box>
				</Box>
				{skillLevelMenu}
			</>
		);
	}
};

export default ColumnSkillCell;
