import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { getRepLevel, getRepType } from "../../../../../utils/helpers";
import { ALPHABET, NUMBER } from "../../../constants/constants";
import SkillRepresentationCell from "../../cross-skill-table/ui/column-skill-representations-cell";

const useStyles = makeStyles({
	input: {
		height: "30px",
		boxSizing: "border-box",
	},
});

const GATCheckbox = ({
	checked,
	onChange,
	level,
	onClick,
	repCode,
	backColorCode,
	foreColorCode,
	value,
}) => {
	const classes = useStyles();
	return (
		<Box display={"flex"} alignItems="center" pl={2} height={30}>
			{level >= 0 ? (
				<SkillRepresentationCell
					level={level}
					value={value}
					backgroundColor={backColorCode}
					foregroundColor={foreColorCode}
					representationType={getRepType(repCode)}
					representationLevel={getRepLevel(repCode, level)}
					border={1}
					width={
						getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
							? 10
							: 20
					}
					height={
						getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
							? 10
							: 20
					}
					backgroundWidth={
						getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
							? "25px"
							: "28px"
					}
					backgroundHeight={
						getRepType(repCode) === ALPHABET || getRepType(repCode) === NUMBER
							? "25px"
							: "28px"
					}
				/>
			) : null}
			<Checkbox
				classes={{ root: classes.input }}
				checked={checked}
				onChange={onChange}
				onClick={onClick}
			/>
		</Box>
	);
};

export default GATCheckbox;
