const useColumnDivider = (columns, fixedColumns, defaultCheckedColumns) => {
	const defaultColumns = columns
		.filter((column) => !fixedColumns.includes(column.field))
		.map((column) => {
			return {
				checked: true,
				...column,
			};
		});

	const restColumns = columns
		.filter((column) => fixedColumns.includes(column.field))
		.map((column) => {
			return {
				checked: defaultCheckedColumns.includes(column.field) ? true : false,
				...column,
			};
		});

	return {
		defaultColumns,
		restColumns,
	};
};

export default useColumnDivider;
