// TODO - Type 'any' needs to be fixed.
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import * as Yup from "yup";

export const useStyles = makeStyles(() => ({
  cancelButton: {
    marginRight: "auto",
  },
  dialogContentRoot: {
    maxWidth: "70%",
  },
  formControlRoot: {
    width: "100%",
    "& .MuiFilledInput-root": {
      background: "#F8F8F8 !important",
    },
  },
  btnLabel: {
    position: "relative",
  },
  progressIndicator: {
    position: "absolute",
    left: "50%",
  },
  formRoot: {
    width: "100%",
  },
}));

const toLocalISOString = (date: Date): string => {
  const off = date.getTimezoneOffset();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes() - off,
    date.getSeconds(),
    date.getMilliseconds()
  ).toISOString();
};

export const setToNearestTime = (dateString: string): string => {
  const date = new Date(dateString);
  return toLocalISOString(date).slice(0, 16);
};

export const configureDatesOfTheWeek = (date: Date): Record<string, Date> => {
  let returnValue: Record<string, Date> = {};
  const daysArray: string[] = [
    "", // To move sunday from 0 to 7 position
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const currentDay = date.getDay() === 0 ? 7 : date.getDay();
  for (let i = 1; i < 8; i++) {
    let tempDate = new Date();
    let dayModification = 0;
    if (currentDay > i) {
      dayModification = -1 * (currentDay - i) * 24;
    } else if (i > currentDay) {
      dayModification = (i - currentDay) * 24;
    }
    tempDate = new Date(date.getTime() + dayModification * 60 * 60 * 1000);
    returnValue[daysArray[i]] = tempDate;
  }
  returnValue = {
    monday: returnValue.monday,
    tuesday: returnValue.tuesday,
    wednesday: returnValue.wednesday,
    thursday: returnValue.thursday,
    friday: returnValue.friday,
    saturday: returnValue.saturday,
    sunday: returnValue.sunday,
  };
  return returnValue;
};

export const yupInitObject: Record<string, Yup.AnySchema> = {
  startTime: Yup.string()
    .required("Start Time cannot be empty")
    .test(
      "is-greater",
      "End Time should be greater than Start Time",
      function (value) {
        const { endTime } = this.parent;
        return value ? isAfter(new Date(endTime), new Date(value)) : !!value;
      }
    ),
  endTime: Yup.string()
    .required("End Time cannot be empty")
    .test(
      "is-greater",
      "End Time should be greater than Start Time",
      function (value) {
        const { startTime } = this.parent;
        return value ? isAfter(new Date(value), new Date(startTime)) : !!value;
      }
    ),
  workCenterId: Yup.string().required("Workcenter is required"),
  numberOfWorkersNeeded: Yup.number()
    .moreThan(0, "Minimum worker required is 1")
    .lessThan(100000, "Maximum worker required should be less than 100,000")
    .required("Number of Staff is required"),
  shiftPremium: Yup.string(),
  shiftNote: Yup.string().max(250, "Note should be less than 250 characters"),
};

interface ShiftData {
  startDate: string;
  endDate: string;
  workCenterId: string;
  jobId: string;
  numberOfWorkersNeeded: number;
  shiftPremium?: string;
  skills?: { skillId: string; skillName: string }[];
  shiftSkills?: { skillId: string; skillName: string }[];
  id: string | number;
  isPublished: boolean;
  startDateTime: string;
  locationId: string;
  shiftNote: string;
}

type Skills = { id: string; name: string };

interface GetInitValuesParams {
  allShiftsData: any; // Adjust the type accordingly
  shiftIdForEditShift: string;
  currentShiftDetails: (
    allShiftsData: any,
    shiftIdForEditShift: string
  ) => ShiftData;
  filterAllWorkcenters?: any;
}

export const getInitValues = ({
  allShiftsData,
  shiftIdForEditShift,
  currentShiftDetails,
  filterAllWorkcenters,
}: GetInitValuesParams): any => {
  if (allShiftsData) {
    const shiftData = currentShiftDetails(allShiftsData, shiftIdForEditShift);
    const mySkills = shiftData.skills || shiftData.shiftSkills;
    const shiftSkills = mySkills?.map((skill) => ({
      ...skill,
      id: skill.skillId,
      name: skill.skillName,
    }));

    const locationId =
      filterAllWorkcenters &&
      filterAllWorkcenters.find(
        (wc: any) => wc.id.toString() === shiftData.workCenterId.toString()
      )?.location?.id;

    const startTime = `${format(
      new Date(shiftData.startDate),
      "yyyy-MM-dd"
    )}T${format(new Date(shiftData.startDate), "HH:mm:ss")}`;
    const endTime = `${format(
      new Date(shiftData.endDate),
      "yyyy-MM-dd"
    )}T${format(new Date(shiftData.endDate), "HH:mm:ss")}`;
    const shiftNote = shiftData.shiftNote;

    return {
      startTime,
      endTime,
      workCenterId: shiftData.workCenterId.toString(),
      jobId: shiftData.jobId ? shiftData.jobId : null,
      numberOfWorkersNeeded: shiftData.numberOfWorkersNeeded,
      shiftPremium: shiftData?.shiftPremium ?? "",
      shiftData,
      shiftDate: shiftData.startDateTime,
      shiftPublished: shiftData?.isPublished,
      shiftId: shiftData.id,
      currentShift: shiftData,
      shiftSkills: shiftSkills ?? [],
      locationId: locationId && locationId,
      shiftNote,
    };
  }
};
