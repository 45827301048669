import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";

// Function to log out the user
const logout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    window.location.replace(`${window.location.origin}/login`);
};

// Request interceptor to add authorization headers
axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (localStorage.token) {
        config.headers.Authorization = `Bearer ${localStorage.token}`;
    }
    return config;
});

// Response interceptor to handle token refresh logic
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        // If the refresh token request itself failed, log the user out
        if (
            error.response.status === 401 &&
            originalRequest.url === `${process.env.REACT_APP_API_END_POINT}/Users/Refresh`
        ) {
            logout();
            return Promise.reject(error);
        }

        // If the original request failed due to 401, try to refresh the token
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axios
                .post(`${process.env.REACT_APP_API_END_POINT}/Users/Refresh`, {
                    refreshToken: localStorage.getItem("refreshToken"),
                    token: localStorage.getItem("token"),
                })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem("refreshToken", res.data.refreshToken);
                        localStorage.setItem("token", res.data.token);

                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + localStorage.getItem("token");

                        return axios(originalRequest);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    if (
                        localStorage.getItem("refreshToken") &&
                        localStorage.getItem("token")
                    ) {
                        const token = localStorage.getItem("token");
                        const jwt: { exp: number } = jwt_decode(token ?? "") ?? { exp: 0 };
                        const exp = jwt ? jwt.exp ?? 0 : 0;

                        if (Date.now() / 1000 < exp) {
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + token;
                            return axios(originalRequest);
                        } else {
                            logout();
                            return Promise.reject(error);
                        }
                    } else {
                        logout();
                        return Promise.reject(error);
                    }
                });
        }

        return Promise.reject(error);
    }
);

export default axios;
