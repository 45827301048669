// TODO - Type 'any' needs to be fixed.
import { Grid, TextField } from "@mui/material";
import React from "react";

import SectionWrapper from "../../../section-wrapper";
import { OneCompany } from "../../../store/gat-admin-slice-types";
import LocationCluster from "../../core/location-cluster";
import SectionActions from "../../section-actions";
import useCoreController from "./controllers/use-core-controller";
import ContractuallyAllowedLocation from "./ui/contractually-allowed-locations";
import CoreActions from "./ui/core-actions";

interface ICoreProps{
  companyData: OneCompany
  companyLocationClusters: any
  getCompanyLocationClustersStatus: string
  getCompanySettingsStatus: string
}

const Core:React.FC<ICoreProps> = ({
  companyData,
  companyLocationClusters,
  getCompanyLocationClustersStatus,
  getCompanySettingsStatus,
}) => {
  const [
    {
      company,
      newCompany,
      coreSettings,
      newCoreSettings,
      creatingNewCompany,
      loading,
      locations,
    },
    { setNewCompany, setNewCoreSettings, handleSaveCompany },
  ]:any = useCoreController(companyData);

  return (
    <SectionWrapper
      title="Core company configuration"
      subtitle="Configuration around worker shift and company"
    >
      <Grid container>
        <Grid item xs={4}>
          <TextField
            label="Company Name (required)"
            value={newCompany.name ?? ""}
            onChange={(e) =>
              setNewCompany({ ...newCompany, name: e.target.value })
            }
            variant="filled"
            sx={{
              marginTop: "36px",
            }}
            inputProps={{ maxLength: 150 }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg>
          <TextField
            label="Full address - Headquarters"
            value={newCompany.hqFullAddress ?? ""}
            onChange={(e) =>
              setNewCompany({ ...newCompany, hqFullAddress: e.target.value })
            }
            variant="filled"
            sx={{
              marginTop: "36px",
            }}
            inputProps={{ maxLength: 250 }}
            fullWidth
          />
        </Grid>
      </Grid>
      {!creatingNewCompany ? (
        <Grid container>
          <Grid item lg>
            <ContractuallyAllowedLocation
              newCoreSettings={newCoreSettings}
              setNewCoreSettings={setNewCoreSettings}
              getCompanySettingsStatus={getCompanySettingsStatus}
            />
          </Grid>
        </Grid>
      ) : null}
      {!creatingNewCompany ? (
        <Grid container>
          <Grid item lg>
            <LocationCluster
              coreSettings={coreSettings}
              newCoreSettings={newCoreSettings}
              setNewCoreSettings={setNewCoreSettings}
              companyLocationClusters={companyLocationClusters}
              getCompanyLocationClustersStatus={
                getCompanyLocationClustersStatus
              }
              companyId={companyData.id}
              locations={locations}
              getCompanySettingsStatus={getCompanySettingsStatus}
            />
          </Grid>
        </Grid>
      ) : null}
      {creatingNewCompany ? <CoreActions newCompany={newCompany} /> : null}
      {!creatingNewCompany ? (
        <SectionActions
          oldData={{ ...company, ...coreSettings }}
          newData={{ ...newCompany, ...newCoreSettings }}
          resetChanges={() => {
            setNewCompany(company);
            setNewCoreSettings(coreSettings);
          }}
          handleSave={handleSaveCompany}
          loading={loading}
        />
      ) : null}
    </SectionWrapper>
  );
};

export default Core;
