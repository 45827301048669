import WarningIcon from "@mui/icons-material/Warning";
import { Box, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import format from "date-fns/format";
import React from "react";

import { JobRole } from "../../../../../design/job-roles";
import { formatDate } from "../../../../../utils/helpers";
import {SkillInShift} from "../../../../scheduler/ui/dialog/copy-shifts-dialog/types";

export const columns:GridColDef[] = [
  {
    field: "jobRole",
    headerName: "Job role",
    width: 220,
    renderCell: (params) => {
      return (
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <JobRole jobRole={params.row.jobRole} />
        </Box>
      );
    },
  },
  {
    field: "shifts",
    headerName: "Shifts",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box display="flex" flexWrap="wrap">
          {params.row.shiftSortedDetails.map(
            ({
              shiftDateTime,
              shiftId,
              isValid,
              time: { start, end },
            }: {
              shiftDateTime: string;
              shiftId: any;
              isValid: boolean;
              time: { start: string; end: string };
            }) => (
              <Box key={shiftId} p={1} width={"170px"}>
                <Typography
                  variant="subtitle2"
                  color={isValid ? "#666666" : "#9e9e9e"}
                >
                  {
                    formatDate({
                      dateStr: shiftDateTime,
                      formatType: "SHORT_DATE",
                    })
                  }
                </Typography>

                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body2"
                    color={isValid ? "#666666" : "#9e9e9e"}
                    fontSize="14px"
                    sx={{ mr: 0.5 }}
                  >
                    {start} - {end}
                  </Typography>
                  {!isValid ? (
                    <WarningIcon
                      fontSize="small"
                      color="warning"
                      sx={{ borderRadius: "20px" }}
                    />
                  ) : null}
                </Box>
              </Box>
            )
          )}
        </Box>
      );
    },
  },
];

export const columnsBySkills:GridColDef[] = [
  {
    field: "skills",
    headerName: "Skills",
    width: 220,
    renderCell: (params) => {
      return (
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
          >
            {params.row.skills.map((skill: SkillInShift) => (
                <Typography key={`${params.row.id}-${skill.skillId}`}
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              color: "rgba(0,0,0,0.87)",
                              fontWeight: 400,
                              lineHeight: "20px",
                              letterSpacing: "0.17px"
                            }}
                >{skill.skillName}</Typography>
            ))}
          </Box>
      );
    },
  },
  {
    field: "shifts",
    headerName: "Shifts",
    flex: 1,
    renderCell: (params) => {
      return (
          <Box display="flex" flexWrap="wrap">
            {params.row.shiftSortedDetails.map(
                ({
                   shiftDateTime,
                   shiftId,
                   isValid,
                   time: { start, end },
                 }: {
                  shiftDateTime: string;
                  shiftId: any;
                  isValid: boolean;
                  time: { start: string; end: string };
                }) => (
                    <Box key={shiftId} p={1} width={"170px"}>
                      <Typography
                          variant="subtitle2"
                          color={isValid ? "#666666" : "#9e9e9e"}
                      >
                        {format(new Date(shiftDateTime), "d MMM, EEE")}
                      </Typography>

                      <Box display="flex" alignItems="center">
                        <Typography
                            variant="body2"
                            color={isValid ? "#666666" : "#9e9e9e"}
                            fontSize="14px"
                            sx={{ mr: 0.5 }}
                        >
                          {start} - {end}
                        </Typography>
                        {!isValid ? (
                            <WarningIcon
                                fontSize="small"
                                color="warning"
                                sx={{ borderRadius: "20px" }}
                            />
                        ) : null}
                      </Box>
                    </Box>
                )
            )}
          </Box>
      );
    },
  },
];
