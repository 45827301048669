// TODO - Type 'any' needs to be fixed.
import isAfter from "date-fns/isAfter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../export/scheduler";
import { calculateShiftHoursAndMinutes } from "../../../../../../utils/helpers";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import {SchedulerWorkCenter} from "../../../../store/types";
import {
  configureDatesOfTheWeek,
  getInitValues,
  yupInitObject,
} from "../utils";

type UseCnsDialog = {
  draftShiftDate: Date;
};

const useCnsDialog = ({ draftShiftDate }: UseCnsDialog) => {
  const dispatch = useDispatch();

  const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
  const { allJobRoles, filterJobRoles, filterAllWorkcenters, fetchFilterAndShiftDataStatus, defaultDurationHrs, minDurationHrs, maxDurationHrs } =
    useSchedulerData();
  const [initialValues, setInitialValues] = useState(() =>
    getInitValues({ draftShiftDate, defaultDurationHrs })
  );
  const [schema, setSchema] = useState({});

  const [totalHours, setTotalHours] = useState(0);
  const [totalMins, setTotalMins] = useState(0);
  const [initialShiftDateTimeValues, setInitialShiftDateTimeValue] = useState(
    () => getInitValues({ draftShiftDate, defaultDurationHrs })
  );
  const [selectedDatesOfTheWeek, setSelectedDatesOfTheWeek] = useState(() =>
    configureDatesOfTheWeek(
      new Date(getInitValues({ draftShiftDate, defaultDurationHrs }).startTime)
    )
  );

  useEffect(() => {
    const initValues = getInitValues({ draftShiftDate, defaultDurationHrs });
    /// console.log("initValues", initValues);
    const schema = Yup.object(yupInitObject);
    setInitialValues(initValues);
    setSchema(schema);
  }, [draftShiftDate, defaultDurationHrs, minDurationHrs, maxDurationHrs]);

  const handleWorkcenterChange = (workcenterId: string) => {
    const jobs: any[] = [];
    if (fetchFilterAndShiftDataStatus === "fulfilled") {
      filterAllWorkcenters.forEach((workcenter: SchedulerWorkCenter) => {
            const jobIds = workcenter.jobIds.split(",").map((id) => parseInt(id));
            if (workcenter.id === parseInt(workcenterId)) {
              filterJobRoles.forEach((job: { id: number }) => {
                  if (jobIds.includes(job.id)) {
                    jobs.push(job);
                  }
                });
            }
        });
    } else {
      allJobRoles.forEach((job: { workCenterIds: string }) => {
        if (job.workCenterIds.includes(workcenterId)) {
          jobs.push(job);
        }
      });
    }
    setFilteredJobs(jobs);
  };

  const getNumberOfStaff = (
    jobRole: string | null,
    locationId: string,
    startTime: string,
    endTime: string,
    shiftSkills?: any[]
  ) => {
    if (!locationId || !startTime || !endTime) return;
    if (jobRole === "" && shiftSkills?.length === 0) return;

    dispatch(
      fetchMaxWorkersByLocationJob({
        locationId,
        jobId: jobRole === "" ? null : jobRole,
        startDateTime: startTime,
        endDateTime: endTime,
        shiftSkills,
      })
    );
  };

  const calculateHours = (
    startTime: string,
    endTime: string,
    numberOfWorkersNeeded: string
  ) => {
    const { hours, minutes } = calculateShiftHoursAndMinutes(
      startTime,
      endTime,
      numberOfWorkersNeeded
    );
    setTotalHours(hours);
    setTotalMins(minutes);
  };

  const calculateTotalHoursFromWorkers = (
    formValues: {
      startTime: string;
      endTime: string;
    },
    numberOfWorkersNeeded: string
  ) => {
    // console.log(formValues);
    const { startTime, endTime } = formValues;
    if (startTime && endTime && numberOfWorkersNeeded) {
      calculateHours(startTime, endTime, numberOfWorkersNeeded);
    }
  };

  const calculateTotalHoursFromStartTime = (
    formValues: {
      numberOfWorkersNeeded: string;
      endTime: string;
    },
    startTime: string
  ) => {
    const { numberOfWorkersNeeded, endTime } = formValues;
    if (startTime && endTime && numberOfWorkersNeeded) {
      calculateHours(startTime, endTime, numberOfWorkersNeeded);
    }
  };

  const calculateTotalHoursFromEndTime = (
    formValues: {
      numberOfWorkersNeeded: string;
      startTime: string;
    },
    endTime: string
  ) => {
    const { numberOfWorkersNeeded, startTime } = formValues;
    if (startTime && endTime && numberOfWorkersNeeded) {
      calculateHours(startTime, endTime, numberOfWorkersNeeded);
    }
  };

  const isDisabled = (currentDate: Date, shiftStartDate: Date) => {
    return isAfter(shiftStartDate, currentDate) || false;
  };

  return [
    {
      initialValues,
      schema,
      initialShiftDateTimeValues,
      filteredJobs,
      selectedDatesOfTheWeek,
      totalHours,
      totalMins,
    },
    {
      isDisabled,
      calculateTotalHoursFromEndTime,
      calculateTotalHoursFromStartTime,
      calculateTotalHoursFromWorkers,
      handleWorkcenterChange,
      getNumberOfStaff,
      setInitialShiftDateTimeValue,
      setSelectedDatesOfTheWeek,
      setTotalHours,
    },
  ];
};

export default useCnsDialog;
