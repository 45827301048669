import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	updateCompanySettings,
	updateLocationData,
} from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import { findDifferentKeys } from "../../../../utils";

const shiftObj:any = {
	swapEnabled: "shift.swapEnabled",
	swapApprovalRequired: "shift.swapApprovalRequired",
	cancelEnabled: "shift.cancelEnabled",
	forceCancelEnabled: "shift.forceCancelEnabled",
	cancelWindowBeforeStartDays: "shift.cancelWindowBeforeStartDays",
	cancelWindowAfterAcceptHrs: "shift.cancelWindowAfterAcceptHrs",
	jobExpiryEnabled: "clientApp.jobExpiryEnabled",
	minWorkHoursRequired: "jobExpiry.minWorkHoursRequired",
	minWorkHoursDays: "jobExpiry.minWorkHoursDays",
};

const initialShiftSettings = {
	swapEnabled: false,
	swapApprovalRequired: false,
	cancelEnabled: false,
	forceCancelEnabled: false,
	cancelWindowBeforeStartDays: 0,
	cancelWindowAfterAcceptHrs: 0,
	jobExpiryEnabled: false,
	minWorkHoursRequired: 0,
	minWorkHoursDays: 0,
	forceAssignShift: false,
	forceAssignShiftBeforeStartHours: 0,
};

const useShiftSettingsController = (entity:any, locationData:any) => {
	const dispatch = useDispatch();
	const {
		updateCompanySettingsStatus,
		updateLocationDataStatus,
		companySettingsObj,
		locationSettingsObj,
		currentLocationData,
		allSettings,
	} = useGatAdminConfigData();

	const [loading, setLoading] = useState(false);

	const [shiftSettings, setShiftSettings] = useState<any>(initialShiftSettings);
	const [newShiftSettings, setNewShiftSettings] = useState<any>({});

	const [shiftSettingsLocationData, setShiftSettingsLocationData] =
		useState<any>(locationData);
	const [newShiftSettingsLocationData, setNewShiftSettingsLocationData] =
		useState<any>({});

	const settingsObj =
		entity === "location" ? locationSettingsObj : companySettingsObj;

	const handleSave = () => {
		setLoading(true);
		const changedSettings :any= findDifferentKeys(shiftSettings, newShiftSettings);
		const chnagedValues = changedSettings.map((key:any) => {
			const item = shiftObj[key];
			const obj = settingsObj[item] ? settingsObj[item] : allSettings[item];
			return {
				...obj,
				value: newShiftSettings[key],
			};
		});
		const changedRules:any = findDifferentKeys(
			shiftSettingsLocationData,
			newShiftSettingsLocationData
		);
		if (changedSettings.length > 0) {
			dispatch(updateCompanySettings(chnagedValues));
		} else if (
			changedRules.length > 0 ||
			JSON.stringify(newShiftSettingsLocationData) !==
				JSON.stringify(shiftSettingsLocationData)
		) {
			dispatch(updateLocationData(newShiftSettingsLocationData));
		}
	};

	useEffect(() => {
		if (Object.keys(settingsObj).length > 0) {
			const obj = {
				swapEnabled: settingsObj["shift.swapEnabled"].value,
				swapApprovalRequired:
					settingsObj["shift.swapApprovalRequired"]?.value ?? false,
				cancelEnabled: settingsObj["shift.cancelEnabled"].value,
				forceCancelEnabled:
					settingsObj["shift.forceCancelEnabled"]?.value ?? false,
				cancelWindowBeforeStartDays:
					settingsObj["shift.cancelWindowBeforeStartDays"]?.value ?? 0,
				cancelWindowAfterAcceptHrs:
					settingsObj["shift.cancelWindowAfterAcceptHrs"]?.value ?? 0,
				jobExpiryEnabled: settingsObj["clientApp.jobExpiryEnabled"].value,
				minWorkHoursRequired:
					settingsObj["jobExpiry.minWorkHoursRequired"]?.value ?? 0,
				minWorkHoursDays: settingsObj["jobExpiry.minWorkHoursDays"]?.value ?? 0,
				forceAssignShift:
					settingsObj["assignShiftToWorker.enabled"]?.value ?? false,
				forceAssignShiftBeforeStartHours:
					settingsObj["assignShiftToWorker.hrsBeforeShift"]?.value ?? 0,
			};
			setShiftSettings(obj);
			setNewShiftSettings(obj);
		}
	}, [settingsObj, entity]);

	useEffect(() => {
		if (updateCompanySettingsStatus === "fulfilled") {
			setShiftSettings(newShiftSettings);
			setLoading(false);
		}
	}, [updateCompanySettingsStatus, newShiftSettings]);

	useEffect(() => {
		if (updateLocationDataStatus === "fulfilled") {
			setNewShiftSettingsLocationData(shiftSettingsLocationData);
			setLoading(false);
		}
	}, [updateLocationDataStatus, shiftSettingsLocationData]);

	useEffect(() => {
		if (locationData) {
			setShiftSettingsLocationData(locationData);
			setNewShiftSettingsLocationData(locationData);
		}
	}, [locationData]);

	return [
		{
			shiftSettings,
			newShiftSettings,
			updateCompanySettingsStatus,
			shiftSettingsLocationData,
			newShiftSettingsLocationData,
			currentLocationData,
			loading,
		},
		{
			setNewShiftSettings,
			setShiftSettingsLocationData,
			setNewShiftSettingsLocationData,
			handleSave,
		},
	];
};

export default useShiftSettingsController;
