import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	getAllCompanies,
	getAllSettingsTypes,
} from "../../../../../export/gat-admin";
import { factories } from "../../constants/factories";
import {OneCompany,OneCompanyWithSearchField} from "../../store/gat-admin-slice-types"

const MAXIMUM_ALLOWED_RETRY = 2; // Retry two additional time

const useManageFactoriesController = () => {
	const factoriesFromAPI = useSelector(
		(state:any) => state.gatAdminConfiguration.allCompanies
	);
	const companiesLoadingStatus = useSelector(
		(state:any) => state.gatAdminConfiguration.getAllCompanies.status
	);
	const companiesLoadingError = useSelector(
		(state:any) => state.gatAdminConfiguration.getAllCompanies.errorMessage
	);
	const creatingNewCompany = useSelector(
		(state:any) => state.gatAdminConfiguration.creatingNewCompany
	);
	const roles = useSelector((state:any) => state.user.userData.userRoles);
	const dispatch = useDispatch();
	const [retried, setRetried] = useState(0);
	const [allCompaniesWithSearchValue, setAllCompaniesWithSearchValue] =
		useState<OneCompanyWithSearchField[]>([]);
	const [filteredCompanies, setFilteredCompanies] = useState([]);
	const [allCompanies, setAllCompanies] = useState<OneCompany[] | any>([]);
	const [searchQuery, setSearchQuery] = useState("");

	// Boeing related dummy data
	const [isBoeing, setIsBoeing] = useState(false);

	useEffect(() => {
		if (roles.includes("GATAdmin")) {
			if (factoriesFromAPI.length > 0) {
				const sortedFactoriesById = [...factoriesFromAPI];
				sortedFactoriesById.sort((a, b) => {
					return -(a.id - b.id); // Latest id first
				});
				setAllCompanies(sortedFactoriesById);
			}
		} else {
			setIsBoeing(true);
			setAllCompanies(factories);
		}
	}, [roles, factoriesFromAPI]);

	useEffect(() => {
		try {
			if (searchQuery === "") {
				setFilteredCompanies(allCompanies);
			} else {
				let result:any = [...allCompaniesWithSearchValue];
				result = result.filter((x:OneCompanyWithSearchField) =>
					x.searchValue?.toLowerCase().includes(searchQuery.toLowerCase())
				);
				setFilteredCompanies(result ?? []);
			}
		} catch (e) {
			// Do nothing
		}
	}, [allCompanies, allCompaniesWithSearchValue, searchQuery]);

	const getAllCompaniesErrorAlert = (
		<Snackbar
			open={
				companiesLoadingStatus === "error" && retried < MAXIMUM_ALLOWED_RETRY
			}
			autoHideDuration={3000}
			onClose={() => {
				if (retried < MAXIMUM_ALLOWED_RETRY) {
					dispatch(getAllCompanies());
					setRetried((prevState) => prevState + 1);
				}
			}}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='error'>
				{companiesLoadingError ?? "An Error Occurred. Please try again."}
			</MuiAlert>
		</Snackbar>
	);
	useEffect(() => {
		if (roles.includes("GATAdmin")) {
			dispatch(getAllCompanies());
			dispatch(getAllSettingsTypes());
		}
	}, [dispatch, roles]);
	useEffect(() => {
		try {
			// This code will add search value containing comma separated values of object
			if (allCompanies.length > 0) {
				const newAllCompanies:OneCompanyWithSearchField[] = [];
				allCompanies.forEach((company:any) => {
					const newCompanyObject:OneCompanyWithSearchField = { ...company };
					newCompanyObject.searchValue = Object.keys(company)
						.map((k) => {
							return company[k];
						})
						.join(",");
					newAllCompanies.push(newCompanyObject);
				});
				setAllCompaniesWithSearchValue(newAllCompanies);
			}
		} catch (e) {
			// Do nothing
		}
	}, [allCompanies]);
	return [
		{
			allCompanies,
			allCompaniesWithSearchValue,
			companiesLoadingStatus,
			companiesLoadingError,
			filteredCompanies,
			isBoeing,
			searchQuery,
			creatingNewCompany,
		},
		{
			getAllCompaniesErrorAlert,
			setSearchQuery,
		},
	];
};

export default useManageFactoriesController;
