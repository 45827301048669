import { Box, Switch, TextField, Typography } from "@mui/material";
import React from "react";

type ShiftSettingsProps = {
	newShiftSettings: {
		forceAssignShift: boolean;
		forceAssignShiftBeforeStartHours: number;
	};
};

const ShiftForceAssignment: React.FC<ShiftSettingsProps> = ({
	newShiftSettings,
}) => {
	if (Object.keys(newShiftSettings).length === 0) return null;
	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Allow shift force assignment
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Supervisors can manually assign a shift to workers before the shift
						starts.
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						checked={newShiftSettings.forceAssignShift ?? false}
						// onChange={(e) =>
						// 	setNewShiftSettings((prevSettings) => ({
						// 		...prevSettings,
						// 		forceAssignShift: e.target.checked,
						// 	}))
						// }
						disabled
					/>
				</Box>
			</Box>
			{newShiftSettings.forceAssignShift ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: "20px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Allowed shift assignment window - before the shift starts
						</Typography>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "12px",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							No. of hours prior to shift start time where a supervisor can do
							force-assignment.
						</Typography>
						<Box mt={2} width={130}>
							<TextField
								id='shift-cancellation-window-pre'
								variant='filled'
								type='number'
								sx={{
									"& .MuiInputBase-input": {
										background: "#FFF",
									},
								}}
								label=''
								value={newShiftSettings.forceAssignShiftBeforeStartHours ?? 0}
								helperText='hours'
								disabled
							/>
						</Box>
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default ShiftForceAssignment;
