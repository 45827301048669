import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../../js/utils/api-client"
import { WorkerInsightReportSliceStateTypes } from "./insight-report-types";


const initialState: WorkerInsightReportSliceStateTypes = {

    fetchWorkerInsightReport: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightReportData: [],

    fetchskilllevels: {
        status: "idle",
        errorMessage: ""
    },
    skillsLevelLegendsData: [],

    fetchWorkerInsightDetails: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightDetailsData: {
        interestedSkills: [],
        skills: [],
        workerId: ""
    },
    workerInsightPanelDetails: {
        isOpen: false,
        workerId: "",
        locations: []
    },

    departmentInsightPanelDetails: {
        isOpen: false,
        id: "",
        name: "",
    },
}

//Get all worker insight
export const getWorkerInsightReport: any = createAsyncThunk(
    "workerInsightReport/getWorkerInsightReport",
    async (payload, thunkAPI) => {
        try {

            const workerInsightResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightForUser/workerinsight`
            );

            return thunkAPI.fulfillWithValue(workerInsightResponse.data)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

//Get all skill levels
export const fetchskilllevels: any = createAsyncThunk(
    "workerInsightReport/fetchSkillsLevelLegend",
    async (payload, thunkAPI) => {
        try {

            const skillsLevelsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetCompanySkillLevels/skilllevels`
            );

            return thunkAPI.fulfillWithValue(skillsLevelsResponse.data)
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

//Get worker insight details by workerId
export const fetchWorkerInsightDetails: any = createAsyncThunk(
    "workerInsightReport/fetchWorkerInsight",
    async (payload, { fulfillWithValue, rejectWithValue }) => {
        try {
            const workerDetailsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightDetails/workerinsight/${payload}`
            );
            return fulfillWithValue({ ...workerDetailsResponse.data, workerId: payload });
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const WorkerInsightReportSlice = createSlice({
    name: "workerInsightReport",
    initialState,
    reducers: {
        openWorkerInsightDetails: (state, action) => {
            state.workerInsightPanelDetails.isOpen = true;
            state.workerInsightPanelDetails.workerId = action.payload.workerId;
            state.workerInsightPanelDetails.locations = action.payload.locations
        },
        closeWorkerInsightDetails: (state) => {
            state.workerInsightPanelDetails.isOpen = false;
            state.workerInsightPanelDetails.workerId = "";
        },
    },
    extraReducers: {
        [getWorkerInsightReport.fulfilled]: (state: any, action: any) => {
            state.fetchWorkerInsightReport.status = "fulfilled";
            state.workerInsightReportData = action.payload;

        },
        [getWorkerInsightReport.rejected]: (state: any, action: any) => {
            state.fetchWorkerInsightReport.status = "error";
            state.fetchWorkerInsightReport.errorMessage = action.payload ?? "An error occurred";

        },
        [getWorkerInsightReport.pending]: (state: any) => {
            state.fetchWorkerInsightReport.status = "pending";
        },

        [fetchskilllevels.fulfilled]: (state: any, action: any) => {
            state.fetchskilllevels.status = "fulfilled";
            state.skillsLevelLegendsData = action.payload;

        },
        [fetchskilllevels.rejected]: (state: any, action: any) => {
            state.fetchskilllevels.status = "error";
            state.fetchskilllevels.errorMessage = action.payload ?? "An error occurred";

        },
        [fetchskilllevels.pending]: (state: any) => {
            state.fetchskilllevels.status = "pending";
        },

        [fetchWorkerInsightDetails.fulfilled]: (state: any, action: any) => {
            state.fetchWorkerInsightDetails.status = "fulfilled";
            state.workerInsightDetailsData = action.payload;

        },
        [fetchWorkerInsightDetails.rejected]: (state: any, action: any) => {
            state.fetchWorkerInsightDetails.status = "error";
            state.fetchWorkerInsightDetails.errorMessage = action.payload ?? "An error occurred";

        },
        [fetchWorkerInsightDetails.pending]: (state: any) => {
            state.fetchWorkerInsightDetails.status = "pending";
        },

    }
})

export const {
    openWorkerInsightDetails,
    closeWorkerInsightDetails
} = WorkerInsightReportSlice.actions;

export default WorkerInsightReportSlice.reducer;