import React from "react";

import { formatSkillsByJobs } from "../../../utils/helpers";
import ColumnCell from "../ui/cross-skill-table/ui/column-cell";
import ColumnEmployeeCell from "../ui/cross-skill-table/ui/column-employee-cell";
import ColumnHeader from "../ui/cross-skill-table/ui/column-header";
import ColumnSkillCell from "../ui/cross-skill-table/ui/column-skill-cell";
import ColumnSkillHeader from "../ui/cross-skill-table/ui/column-skill-header";

const useCrossSkillColumn = (
	filteredJobs,
	allSkills,
	allSkillLevels,
	handleSkillLevels,
	handleAddSkillInterested,
	searchText,
	getFilter,
) => {
	const allJobs = filteredJobs;
	const isJobFilter = getFilter("job") ? getFilter("job").jobs.length > 0 : false;
	const isSkillFilter = getFilter("skill") ? getFilter("skill").skills.length > 0 : false;

	const skillIds = isSkillFilter
		? getFilter("skill").skills.map((skill) => skill.id)
		: [];

	const filteredSkills = allSkills.filter((skill) =>
		skillIds.includes(skill.id)
	);

	const jobIdsSkillsArr = isJobFilter
		? []
		: isSkillFilter
		? filteredSkills
		: allSkills;

	const jobIds = isJobFilter ? getFilter("job").jobs.map((job) => job.id) : [];

	isJobFilter && formatSkillsByJobs(allJobs, jobIdsSkillsArr, jobIds);

	let col = [];

	// Add worker employee id column
	col.push({
		field: "companyEmployeeId",
		headerName: "Worker ID",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Worker ID"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.companyEmployeeId ?? "-"}
				searchText={searchText}
			/>
		),
		valueGetter: (params) => params.row.companyEmployeeId,
		order: 4,
	});

	// Add worker job title column
	col.push({
		field: "jobTitle",
		headerName: "Job Title",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Job Title"} />,
		renderCell: (params) => (
			<ColumnCell text={params.row.jobTitle ?? "-"} searchText={searchText} />
		),
		valueGetter: (params) => params.row.jobTitle,
		order: 5,
	});
	// Add worker name column
	col.push({
		field: "name",
		headerName: "Worker Name",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Worker Name"} />,
		renderCell: (params) => (
			<ColumnEmployeeCell params={params} searchText={searchText} />
		),
        valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
        order: 6,
	});
	// Add reporting manager column
	col.push({
		field: "managerName",
		headerName: "Reporting to",
		width: 200,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Reporting to"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.managerName ?? "-"}
				searchText={searchText}
			/>
		),
        valueGetter: (params) => params.row.managerName.toLowerCase(),
        order: 7,
	});
	// Add score column
	col.push({
		field: "skillScore",
		headerName: "Total Skill Score",
		width: 160,
		headerAlign: "center",
		renderHeader: () => <ColumnHeader text={"Total Skill Score"} />,
		renderCell: (params) => (
			<ColumnCell
				text={params.row.skillScore ?? "-"}
				searchText={searchText}
			/>
		),
		order: 8,
	});
	const skillsCol = jobIdsSkillsArr.map((skill, idx) => {
		const currentSkill = { ...skill, id: skill.skillId };
		return {
			field: isJobFilter ? skill.id + skill.jobColor : skill.id.toString(),
			headerName: skill.name,
			width: 100,
			type: "actions",
			headerAlign: "center",
			sortable: true,
			renderCell: (params) => (
				<ColumnSkillCell
					skill={isJobFilter ? currentSkill : skill}
					params={params}
					handleSkillLevels={handleSkillLevels}
					allSkillLevels={allSkillLevels}
					handleAddSkillInterested={handleAddSkillInterested}
				/>
			),
			renderHeader: () => (
				<ColumnSkillHeader
					skill={skill}
					isJobFilter={isJobFilter}
					searchText={searchText}
				/>
			),
			valueGetter: (params) => {
				// For sorting skills
				const skillInCell = params.row.skills.find(
					(x) => x.skillId === parseInt(skill.skillId)
				);
				if (skillInCell) {
					return skillInCell.level + 2;
				} else {
					return 1;
				}
			},
			order: 10 + idx,
		};
	});
	const columns = [...col, ...skillsCol];

	return { columns };
};

export default useCrossSkillColumn;
