import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BackHandIcon from "@mui/icons-material/BackHand";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturb from "@mui/icons-material/DoDisturb";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
	Box,
	Checkbox,
	List,
	ListItem,
	ListItemText,
	Popover,
} from "@mui/material";
import {
	deepPurple,
	green,
	grey,
	lightBlue,
	lime,
	red,
	teal,
} from "@mui/material/colors";
import React, { useEffect, useState } from "react";

interface OptionsType {
	label: string;
	checked: boolean;
	[key: string]: any;
	icon?: string;
	count?: number;
}

interface CheckboxPopoverProps {
	options?: OptionsType[];
	setOptions?: (options: OptionsType[]) => void;
	anchorElement: HTMLElement | null;
	onClose: () => void;
	onApply?: (options: OptionsType[]) => void;
	minWidth?: string | number;
	allCheckedText?: string;
	selectAllCheckboxOption?: boolean;
	showCount?: boolean;
	disableMaxHeight?: boolean;
}

const CheckboxPopover: React.FC<CheckboxPopoverProps> = ({
	options = [],
	setOptions = () => {
		("");
	},
	anchorElement,
	onClose,
	minWidth = "100%",
	allCheckedText = "All Status",
	selectAllCheckboxOption = true,
	showCount = false,
	disableMaxHeight = false,
}) => {
	const [allStatusChecked, setAllStatusChecked] = useState(false);
	// Temporarily using Icons here, Ideally it should come with options
	// some error while using that method
	const statusIcons: any = {
		Applied: (
			<BackHandIcon
				sx={{
					color: `${lightBlue[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Confirmed: (
			<ThumbUpIcon
				sx={{
					color: `${green[700]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Declined: (
			<CancelIcon
				sx={{
					color: `${red[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Viewed: (
			<RemoveRedEyeIcon
				sx={{
					color: `${lime[900]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Notified: (
			<CampaignIcon
				sx={{
					color: `${teal[900]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		Assigned: (
			<AssignmentTurnedInIcon
				sx={{
					color: `${deepPurple[800]} !important`,
					fontSize: "16px",
				}}
			/>
		),
		None: <Box></Box>,
		Cancelled: (
			<DoDisturb
				sx={{
					color: `${red[300]} !important`,
					fontSize: "16px",
				}}
			/>
		),
	};

	useEffect(() => {
		const allOtherChecked = options.every((option) => option.checked);
		setAllStatusChecked(allOtherChecked);
	}, [options]);

	const handleCheckboxChange = (index: number) => {
		const updatedOptions = [...options];
		updatedOptions[index].checked = !updatedOptions[index].checked;
		setOptions(updatedOptions);

		// Check if all other checkboxes are checked
		const allOtherChecked = updatedOptions.every((option) => option.checked);

		// Update the state of "All Status" checkbox based on other checkboxes
		setAllStatusChecked(allOtherChecked);
	};

	const handleAllStatusChange = () => {
		const updatedOptions = [...options];
		// const allStatus = updatedOptions[0];
		setAllStatusChecked(!allStatusChecked);

		// Update the state of other checkboxes based on "All Status"
		updatedOptions.forEach((option, index) => {
			// Skip the "All Status" checkbox itself
			option.checked = !allStatusChecked;
		});

		setOptions(updatedOptions);
	};

	// With icons style
	const styles = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: "100%",
	};
	const withIcons = options.some((option) => option.icon);

	return (
		<Popover
			open={Boolean(anchorElement)}
			anchorEl={anchorElement}
			onClose={onClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			sx={{
				"& .MuiListItem-root": {
					padding: "1px 16px 1px 8px",
				},
			}}
		>
			<List
				disablePadding
				sx={{ minWidth: minWidth, maxHeight: disableMaxHeight ? "none" : 215, overflowY: "scroll" }}
			>
				{selectAllCheckboxOption && (
					<ListItem key={-1}>
						<Checkbox
							checked={allStatusChecked}
							onChange={handleAllStatusChange}
							color='primary'
						/>
						<ListItemText primary={allCheckedText} />
					</ListItem>
				)}
				{options.map((option, index) => (
					<ListItem key={index} sx={withIcons ? styles : {}}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Checkbox
								checked={option.checked}
								onChange={() => handleCheckboxChange(index)}
								color='primary'
							/>
							<ListItemText
								primary={option.label + (showCount ? ` (${option.count})` : "")}
							/>
						</Box>
						{withIcons && (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								{option.icon ? statusIcons[option.icon] : null}
							</Box>
						)}
					</ListItem>
				))}
			</List>
		</Popover>
	);
};

export default CheckboxPopover;
