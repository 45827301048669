import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";

import GATCheckbox from "./GATCheckbox";

const CrossSkillWorkcenterFilter = ({
  allWorkcenters,
  filtersContainerHeight,
  removeSpace,
  noSearchBarSpace,
  workcenterExpanded,
  setWorkcenterExpanded,
  applyFilter,
  isWorkCenterChecked,
}) => {
  return (
    <Box key={`worker-center-filter-${allWorkcenters.length}`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        py={1}
        sx={{
          cursor: "pointer",
        }}
        onClick={() => {
          if (workcenterExpanded) {
            setWorkcenterExpanded(false);
          } else {
            setWorkcenterExpanded(true);
          }
          // resetFilters();
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          Work Center ({allWorkcenters.length})
        </Typography>
        {workcenterExpanded ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {workcenterExpanded && (
        <Box
          sx={{
            overflowY: "auto",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "4px",
            paddingRight: "20px",
            height: filtersContainerHeight - removeSpace + noSearchBarSpace,
            boxShadow:
              "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
          }}
        >
          <FormGroup>
            <>
              {allWorkcenters.map((wc) => (
                <FormControlLabel
                  key={`worker-type-${wc.id}`}
                  control={
                    <GATCheckbox
                      checked={isWorkCenterChecked(wc.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          applyFilter("workCenter", true, wc.id, "", wc.name);
                        } else {
                          applyFilter("workCenter", false, wc.id);
                        }
                      }}
                    />
                  }
                  label={
                    <Box display="flex" alignItems="center">
                      <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                        {wc.name}
                      </span>
                    </Box>
                  }
                />
              ))}
            </>
          </FormGroup>
        </Box>
      )}
    </Box>
  );
};

export default CrossSkillWorkcenterFilter;
