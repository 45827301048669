// TODO - Type 'any' needs to be fixed.
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Button from "../../../../../design/button"
import DialogWrapper from "../../../../../design/dialog-wrapper";

interface CommentDialogProps{
	markAttendance : any;
	open : boolean;
	setOpen : any;
	worker : any;
	setWorker : any;
}

const CommentDialog: React.FC<CommentDialogProps> = ({
	markAttendance,
	open,
	setOpen,
	worker,
	setWorker,
}) => {
	const dispatch = useDispatch();
	const item = worker;

	const [comment, setComment] = useState(worker.comment ?? "");

	const handleClose = () => {
		setComment("");
		setOpen(false);
		setWorker({});
	};

	const handleComment = () => {
		dispatch(
			markAttendance({
				workerId: item.id,
				status: item.status,
				shiftId: item.shiftId,
				comment: comment,
			})
		);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-describedby='alert-dialog-slide-description'
		>
			<DialogWrapper
				dialogTitle='Add Comments'
				dialogSubTitle={`Add comments for ${item.workerName}`}
				dialogContent={
					<Box width={300} height={150}>
						<TextField
							id='outlined-multiline-static'
							multiline
							rows={4}
							fullWidth
							defaultValue={item.comment ?? ""}
							onChange={(e) => setComment(e.target.value)}
							error={comment.length > 250}
							helperText={
								comment.length > 250
									? "Comment should be less than 250 characters."
									: ""
							}
						/>
					</Box>
				}
				actionButtons={
					<Box mt={-8} display='flex'>
						<Box mr={1}>
							<Button onClick={handleClose} variant='outlined'>
								Cancel
							</Button>
						</Box>
						<Button
							disabled={comment.length === 0 || comment.length > 250}
							onClick={handleComment}
						>
							Done
						</Button>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default CommentDialog;
