// TODO - Type 'any' needs to be fixed.
import Dialog from "@mui/material/Dialog";
import format from "date-fns/format";
import React from "react";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import ActionButtons from "./action-buttons";
import useMarkAttendanceDialog from "./controllers/use-mark-attendance-dialog";
import MarkAttendanceTable from "./mark-attendance-table";

interface MarkAttendanceDialogProps {
	handleClose: any;
	open: boolean;
	shiftId: number;
}

const MarkAttendanceDialog: React.FC<MarkAttendanceDialogProps> = ({
	handleClose,
	open,
	shiftId,
}) => {
	const [
		{
			rows,
			shiftMetadata,
			shiftAttendanceDetailsStatus,
			markAttendanceError,
			markAttendanceStatus,
			isLoading,
		},
	] = useMarkAttendanceDialog(shiftId);

	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth='lg'
		>
			<DialogWrapper
				dialogTitle='Mark Attendance'
				dialogSubTitle={`${
					shiftMetadata?.startDate &&
					format(new Date(shiftMetadata?.startDate), "EEE, MMMM do yyyy h:mm a")
				} - ${
					shiftMetadata?.endDate
						? format(new Date(shiftMetadata?.endDate), "h:mm a")
						: null
				}`}
				dialogContent={
					<MarkAttendanceTable
						rows={rows}
						isLoading={isLoading}
					/>
				}
				actionButtons={
					<ActionButtons
						onClose={handleClose}
						markAttendanceError={markAttendanceError}
						markAttendanceStatus={markAttendanceStatus}
					/>
				}
			/>
		</Dialog>
	);
};

export default MarkAttendanceDialog;
