import React from "react";

import { deCamelCase } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";

interface CompanyUserControllerProps {
	companyUserData: any;
	setCompanyUserData: any;
}

const useCompanyUserController = ({
	companyUserData,
	setCompanyUserData,
}: CompanyUserControllerProps) => {
	const {
		createNewCompanyUserStatus,
		updateCompanyUserStatus,
		openCompanyUserDialog,
		allCompanyUserRoles,
		allCompanyWorkCenters,
	} = useGatAdminConfigData();

	const checkRoleId = (id: any) => {
		// To handle role check, as API do not include roleId in company user data
		try {
			if (Object.prototype.hasOwnProperty.call(companyUserData, "roleId")) {
				return parseInt(companyUserData.roleId) === parseInt(id);
			} else if (
				Object.prototype.hasOwnProperty.call(companyUserData, "roles")
			) {
				const roleId = companyUserData.roles[0].id ?? "5";
				return parseInt(roleId) === parseInt(id);
			}
		} catch (e) {
			return false;
		}
	};

	const getUserRole = (roleId: number | undefined) => {
		try {
			if (roleId && allCompanyUserRoles) {
				const roleName = allCompanyUserRoles.filter(
					(role: { id: number }) => role.id === roleId
				)[0].name;
				console.log("roleName", roleName);
				return deCamelCase(roleName) ?? "";
			}
		} catch (e) {
			return "";
		}
	};

	const workCenterIncluded = (id: any) => {
		try {
			const workCentersIdsArray =
				companyUserData.workCenterIds.split(",") ?? [];
			if (workCentersIdsArray.length > 0) {
				return workCentersIdsArray.includes(id.toString());
			} else {
				return false;
			}
		} catch (e) {
			return false;
		}
	};

	const addWorkCenterFromCompany = (id: any) => {
		try {
			let workCenterIdsArray = companyUserData.workCenterIds.split(",") ?? [];
			// Split empty string workaround
			if (workCenterIdsArray.length > 0) {
				if (workCenterIdsArray[0] === "") {
					workCenterIdsArray = [];
				}
			}
			workCenterIdsArray.push(id);
			setCompanyUserData({
				...companyUserData,
				workCenterIds: workCenterIdsArray.toString(),
				userWorkCenters: workCenterIdsArray,
			});
		} catch (e) {
			// Do nothing
		}
	};

	const removeWorkCenterFromCompany = (id: any) => {
		try {
			let workCenterIdsArray = companyUserData.workCenterIds.split(",") ?? [];
			workCenterIdsArray = workCenterIdsArray.filter(
				(wcId: any) => wcId !== id.toString()
			);
			setCompanyUserData({
				...companyUserData,
				workCenterIds: workCenterIdsArray.toString(),
				userWorkCenters: workCenterIdsArray,
			});
		} catch (e) {
			// Do nothing
		}
	};

	return [
		{
			createNewCompanyUserStatus,
			updateCompanyUserStatus,
			openCompanyUserDialog,
			allCompanyUserRoles,
			allCompanyWorkCenters,
		},
		{
			checkRoleId,
			getUserRole,
			workCenterIncluded,
			addWorkCenterFromCompany,
			removeWorkCenterFromCompany,
		},
	];
};

export default useCompanyUserController;
