import { WarningAmber } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { colors } from "../../../../../../../export/config";
import { getRepLevel, getRepType } from "../../../../../../utils/helpers";
import { SkillLevel } from "../../../../../cross-skilling/types";
import { updateCompanySettingsLevel } from "../../../../store/gat-admin-slice";
import alphabetIcon from "../cross-skilling/assets/alphabet@3x.png";
import alphanumericInfo from "../cross-skilling/assets/alphanumeric_info@3x.png";
import alphanumericPresentation from "../cross-skilling/assets/alphanumeric_presentation@3x.png";
import boxInfo from "../cross-skilling/assets/box_info@3x.png";
import boxPresentation from "../cross-skilling/assets/box_presentation@3x.png";
import boxIcon from "../cross-skilling/assets/box@3x.png";
import circleInfo from "../cross-skilling/assets/circle_info@3x.png";
import circlePresentation from "../cross-skilling/assets/circle_presentation@3x.png";
import circleIcon from "../cross-skilling/assets/circle@3x.png";
import donutInfo from "../cross-skilling/assets/donut_info@3x.png";
import donutPresentation from "../cross-skilling/assets/donut_presentation@3x.png";
import donutIcon from "../cross-skilling/assets/donut@3x.png";
import numberInfo from "../cross-skilling/assets/number_info@3x.png";
import numberPresentation from "../cross-skilling/assets/number_presentation@3x.png";
import numberIcon from "../cross-skilling/assets/number@3x.png";
import {
  ALPHABET,
  BOX,
  CIRCLE,
  DONUT,
  EMPTY,
  NUMBER,
} from "../cross-skilling/constants/constants";
import CrossSkillingLevelsTableHeader from "../cross-skilling/cross-skilling-levels-table/cross-skilling-levels-table-header";
import CrossSkillingLevelsTableRowEdit from "../cross-skilling/cross-skilling-levels-table/cross-skilling-levels-table-row-edit";

const steps = ["Select skill level representation", "Configure skill levels"];

interface CrossSkillingLevelsDialogProps {
  onClose: () => void;
  skillLevels: SkillLevel[];
  companySettings: string;
  updateCompanySettingsLevelStatus: string;
  handleSave: () => void;
  openCrossSkillingLevelsDialog: boolean;
}

const CrossSkillingLevelsDialog: React.FC<CrossSkillingLevelsDialogProps> = ({
  onClose,
  skillLevels,
  companySettings,
  updateCompanySettingsLevelStatus,
  handleSave,
  openCrossSkillingLevelsDialog,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialCrossSkillingLevelsData, setInitialCrossSkillingLevelsData] =
    useState<SkillLevel[]>([]);
  const [lastSkillLevels, setLastSkillLevels] = useState<SkillLevel[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeSkillRepresentationType, setActiveSkillRepresentationType] =
    useState(ALPHABET);
  const [maxSkillLevels, setMaxSkillLevels] = useState(5);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [skillRepresentationLevels, setSkillRepresentationLevels] = useState<
    SkillLevel[]
  >([
    {
      id: 0,
      level: 0,
      repLevel: EMPTY,
      repCode: "alphabet-1",
      backColorCode: "",
      foreColorCode: "",
      value: "",
      icon: "",
      name: "",
      desc: "",
      toBeDeleted: false,
      existing: false,
    },
  ]);

  useEffect(() => {
    const isAlphabetOrNum =
      activeSkillRepresentationType === ALPHABET ||
      activeSkillRepresentationType === NUMBER;
    if (activeStep === 0) {
      if (initialCrossSkillingLevelsData.length === 0) {
        setSkillRepresentationLevels([
          {
            id: 0,
            level: 0,
            repLevel: EMPTY,
            repCode: `${activeSkillRepresentationType}-${
              isAlphabetOrNum ? "1" : EMPTY
            }`,
            backColorCode: "",
            foreColorCode: "",
            value: "",
            icon: "",
            name: "",
            desc: "",
            toBeDeleted: false,
            existing: false,
          },
        ]);
      } else {
        if (initialCrossSkillingLevelsData.length > 0) {
          // If skill representation type changed
          if (
            activeSkillRepresentationType !==
            getRepType(initialCrossSkillingLevelsData[0].repCode)
          ) {
            // Map skills
            const newMappedSkillRepresentationLevels: SkillLevel[] = [];
            initialCrossSkillingLevelsData.forEach(
              (skillRepresentationLevel: SkillLevel) => {
                newMappedSkillRepresentationLevels.push({
                  ...skillRepresentationLevel,
                  repCode: `${activeSkillRepresentationType}-${
                    isAlphabetOrNum
                      ? parseInt(String(skillRepresentationLevel.level)) + 1
                      : skillRepresentationLevel.repLevel
                  }`,
                  icon:
                    activeSkillRepresentationType === ALPHABET
                      ? !Number.isNaN(skillRepresentationLevel.value)
                        ? ""
                        : skillRepresentationLevel.value
                      : activeSkillRepresentationType === NUMBER
                      ? skillRepresentationLevel.level + 1
                      : skillRepresentationLevel.icon,
                  value:
                    activeSkillRepresentationType === ALPHABET
                      ? !Number.isNaN(skillRepresentationLevel.value)
                        ? ""
                        : skillRepresentationLevel.value
                      : activeSkillRepresentationType === NUMBER
                      ? skillRepresentationLevel.level + 1
                      : skillRepresentationLevel.value,
                });
              }
            );
            setSkillRepresentationLevels(newMappedSkillRepresentationLevels);
          } else {
            setSkillRepresentationLevels(initialCrossSkillingLevelsData);
          }
        }
      }
    }
    if (isAlphabetOrNum) {
      setMaxSkillLevels(10);
    } else {
      setMaxSkillLevels(5);
    }
  }, [
    activeStep,
    activeSkillRepresentationType,
    initialCrossSkillingLevelsData,
  ]);

  useEffect(() => {
    try {
      if (skillLevels) {
        if (
          (skillLevels.length > 0 &&
            initialCrossSkillingLevelsData.length === 0) ||
          JSON.stringify(skillLevels) !== JSON.stringify(lastSkillLevels)
        ) {
          setShowDeleteWarning(false);
          setLastSkillLevels(skillLevels);
          setActiveStep(1);
          const newSkillRepresentationLevels: SkillLevel[] = [];
          skillLevels.forEach((skillLevel, index) => {
            if (index === 0) {
              setActiveSkillRepresentationType(getRepType(skillLevel.repCode));
            }
            newSkillRepresentationLevels.push({
              id: skillLevel.id,
              level: skillLevel.level,
              repLevel: getRepLevel(skillLevel.repCode, skillLevel.level),
              repCode: skillLevel.repCode,
              backColorCode: skillLevel.backColorCode ?? "",
              foreColorCode: skillLevel.foreColorCode ?? "",
              value: skillLevel.value ?? "",
              icon: skillLevel.value ?? "",
              name: skillLevel.name ?? "",
              desc: skillLevel.desc ?? "",
              toBeDeleted: false,
              existing: true,
            });
          });
          setSkillRepresentationLevels(newSkillRepresentationLevels);
          setInitialCrossSkillingLevelsData(newSkillRepresentationLevels);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      // Do nothing
    }
  }, [skillLevels, initialCrossSkillingLevelsData, lastSkillLevels]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      // Change id to 0 for non existing skill levels
      const skillLevelsForAPI: SkillLevel[] = [];
      skillRepresentationLevels.forEach((skillRepresentationLevel) => {
        if (skillRepresentationLevel.existing) {
          skillLevelsForAPI.push({
            ...skillRepresentationLevel,
            icon: skillRepresentationLevel.icon.toString(),
            value: skillRepresentationLevel.value.toString(),
          });
        } else {
          skillLevelsForAPI.push({
            ...skillRepresentationLevel,
            id: 0,
            icon: skillRepresentationLevel.icon.toString(),
            value: skillRepresentationLevel.value.toString(),
          });
        }
      });
      handleSave();
      dispatch(
        updateCompanySettingsLevel({
          companyId: companySettings,
          skillLevels: skillLevelsForAPI,
        })
      );
    }
  };
  const handleBack = () => {
    if (activeStep !== 0) {
      const isAlphabetOrNum =
        activeSkillRepresentationType === ALPHABET ||
        activeSkillRepresentationType === NUMBER;
      if (!skillLevels) {
        setSkillRepresentationLevels([
          {
            id: 0,
            level: 0,
            repLevel: EMPTY,
            repCode: `${activeSkillRepresentationType}-${
              isAlphabetOrNum ? "1" : EMPTY
            }`,
            backColorCode: "",
            foreColorCode: "",
            value: "",
            icon: "",
            name: "",
            desc: "",
            toBeDeleted: false,
            existing: false,
          },
        ]);
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const closeCrossSkillingLevelsDialog = () => {
    try {
      if (skillLevels.length === 0) {
        setInitialCrossSkillingLevelsData([]);
        setActiveSkillRepresentationType(ALPHABET);
        setSkillRepresentationLevels([
          {
            id: 0,
            level: 0,
            repLevel: EMPTY,
            repCode: "alphabet-1",
            backColorCode: "",
            foreColorCode: "",
            value: "",
            icon: "",
            name: "",
            desc: "",
            toBeDeleted: false,
            existing: false,
          },
        ]);
        setTimeout(() => {
          setActiveStep(0);
        }, 500);
      } else {
        setShowDeleteWarning(false);
        setSkillRepresentationLevels(initialCrossSkillingLevelsData);
        setTimeout(() => {
          setActiveStep(1);
        }, 500);
      }
      onClose();
    } catch (e) {
      onClose();
    }
  };

  const validateSkillLevels = () => {
    let returnValue;
    // Function to check duplicate values in object array
    const hasDuplicateValues = (arr: SkillLevel[], keysToCheck: string[]) => {
      const keySets = new Map();

      for (const key of keysToCheck) {
        keySets.set(key, new Set());
      }

      for (const obj of arr) {
        for (const key of keysToCheck) {
          const value = obj[key as keyof SkillLevel];

          if (keySets.get(key).has(value)) {
            return true;
          } else {
            keySets.get(key).add(value);
          }
        }
      }
      return false;
    };
    // Check for duplicate values
    let keysToCheck = ["name", "value"];
    if (
      activeSkillRepresentationType === ALPHABET ||
      activeSkillRepresentationType === NUMBER
    ) {
      keysToCheck = ["icon", "name", "value"];
    }
    returnValue = !hasDuplicateValues(skillRepresentationLevels, keysToCheck);
    // Check for empty values
    for (let i = 0; i < skillRepresentationLevels.length; i++) {
      // Check if level code or level name is empty
      if (
        skillRepresentationLevels[i].value === "" ||
        skillRepresentationLevels[i].name === ""
      ) {
        returnValue = false;
        break;
      }
      if (
        activeSkillRepresentationType === ALPHABET ||
        activeSkillRepresentationType === NUMBER
      ) {
        // Also check icon
        if (skillRepresentationLevels[i].icon === "") {
          returnValue = false;
          break;
        } else if (parseInt(skillRepresentationLevels[i].icon as string) < 0) {
          returnValue = false;
          break;
        }
      }
    }
    return returnValue;
  };

  return (
    <Dialog
      onClose={() => closeCrossSkillingLevelsDialog()}
      aria-labelledby="add-new-work-center"
      open={openCrossSkillingLevelsDialog}
      maxWidth={false}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialogTitle
        id="work-center-dialog-title"
        sx={{
          width: "930px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "24px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.87)",
            paddingTop: "29px",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          Skill Level Configuration
        </Typography>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
            color: "rgba(0, 0, 0, 0.6)",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingBottom: "12px",
          }}
        >
          {`Configure upto ${maxSkillLevels} skill levels to manage skills for workers.`}
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxWidth: "547px",
          }}
        >
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </DialogTitle>
      <Divider />
      {showDeleteWarning && activeStep !== 0 && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: colors.infoBoxBackground,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "14px 16px",
          }}
        >
          <WarningAmber
            sx={{
              color: colors.warningColor,
            }}
          />
          <Typography
            sx={{
              color: colors.errorText,
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 400,
              marginLeft: "12px",
            }}
          >
            Deleting levels from the existing skill levels will lead to a data
            loss. All the existing levels will be downgraded to highest level
            remaining.
          </Typography>
        </Box>
      )}
      <DialogContent>
        {activeStep === 0 && !loading && (
          <>
            <Grid
              container
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  Choose a skill levels representation
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      borderRadius: "100px",
                      backgroundColor:
                        activeSkillRepresentationType === ALPHABET
                          ? colors.stepperBackground
                          : "rgba(0,0,0,0.08)",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={() => setActiveSkillRepresentationType(ALPHABET)}
                  >
                    <img
                      src={alphabetIcon}
                      alt="alphabet-skill"
                      height={24}
                      style={
                        activeSkillRepresentationType === ALPHABET
                          ? {
                              filter: "invert(100%)",
                            }
                          : {}
                      }
                    />
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        color:
                          activeSkillRepresentationType === ALPHABET
                            ? colors.light
                            : "rgba(0, 0, 0, 0.87)",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                      }}
                    >
                      Alphanumeric
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      borderRadius: "100px",
                      backgroundColor:
                        activeSkillRepresentationType === NUMBER
                          ? colors.stepperBackground
                          : "rgba(0,0,0,0.08)",
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                    onClick={() => setActiveSkillRepresentationType(NUMBER)}
                  >
                    <img
                      src={numberIcon}
                      alt="number-skill"
                      height={24}
                      style={
                        activeSkillRepresentationType === NUMBER
                          ? {
                              filter: "invert(100%)",
                            }
                          : {}
                      }
                    />
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "13px",
                        fontWeight: 400,
                        color:
                          activeSkillRepresentationType === NUMBER
                            ? colors.light
                            : "rgba(0, 0, 0, 0.87)",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                      }}
                    >
                      Number
                    </Typography>
                  </Box>
                  <Tooltip
                    title={
                      skillRepresentationLevels.length > 5
                        ? "Cannot change to this representation type when more than 5 levels are added"
                        : null
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        borderRadius: "100px",
                        backgroundColor:
                          activeSkillRepresentationType === CIRCLE
                            ? colors.stepperBackground
                            : skillRepresentationLevels.length > 5
                            ? "rgba(0,0,0,0.12)"
                            : "rgba(0,0,0,0.08)",
                        cursor:
                          skillRepresentationLevels.length > 5
                            ? "not-allowed"
                            : "pointer",
                        marginRight: "8px",
                      }}
                      onClick={() => {
                        if (skillRepresentationLevels.length <= 5)
                          setActiveSkillRepresentationType(CIRCLE);
                      }}
                    >
                      <img
                        src={circleIcon}
                        alt="circle-skill"
                        height={24}
                        style={
                          activeSkillRepresentationType === CIRCLE
                            ? {
                                filter: "invert(100%)",
                              }
                            : skillRepresentationLevels.length > 5
                            ? {
                                opacity: 0.2,
                              }
                            : {}
                        }
                      />
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "13px",
                          fontWeight: 400,
                          color:
                            activeSkillRepresentationType === CIRCLE
                              ? colors.light
                              : skillRepresentationLevels.length > 5
                              ? "rgba(0,0,0,0.26)"
                              : "rgba(0, 0, 0, 0.87)",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                        }}
                      >
                        Circle
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      skillRepresentationLevels.length > 5
                        ? "Cannot change to this representation type when more than 5 levels are added"
                        : null
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        borderRadius: "100px",
                        backgroundColor:
                          activeSkillRepresentationType === DONUT
                            ? colors.stepperBackground
                            : skillRepresentationLevels.length > 5
                            ? "rgba(0,0,0,0.12)"
                            : "rgba(0,0,0,0.08)",
                        cursor:
                          skillRepresentationLevels.length > 5
                            ? "not-allowed"
                            : "pointer",
                        marginRight: "8px",
                      }}
                      onClick={() => {
                        if (skillRepresentationLevels.length <= 5)
                          setActiveSkillRepresentationType(DONUT);
                      }}
                    >
                      <img
                        src={donutIcon}
                        alt="donut-skill"
                        height={24}
                        style={
                          activeSkillRepresentationType === DONUT
                            ? {
                                padding: "1px",
                                filter:
                                  "invert(0%) sepia(0%) saturate(1200%) hue-rotate(312deg) brightness(100%) contrast(107%)",
                              }
                            : skillRepresentationLevels.length > 5
                            ? {
                                opacity: 0.2,
                                    padding: "1px",
                              }
                            : { padding: "1px" }
                        }
                      />
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "13px",
                          fontWeight: 400,
                          color:
                            activeSkillRepresentationType === DONUT
                              ? colors.light
                              : skillRepresentationLevels.length > 5
                              ? "rgba(0,0,0,0.26)"
                              : "rgba(0, 0, 0, 0.87)",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                        }}
                      >
                        Donut
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      skillRepresentationLevels.length > 5
                        ? "Cannot change to this representation type when more than 5 levels are added"
                        : null
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "3px",
                        paddingBottom: "3px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        borderRadius: "100px",
                        backgroundColor:
                          activeSkillRepresentationType === BOX
                            ? colors.stepperBackground
                            : skillRepresentationLevels.length > 5
                            ? "rgba(0,0,0,0.12)"
                            : "rgba(0,0,0,0.08)",
                        cursor:
                          skillRepresentationLevels.length > 5
                            ? "not-allowed"
                            : "pointer",
                        marginRight: "8px",
                      }}
                      onClick={() => {
                        if (skillRepresentationLevels.length <= 5)
                          setActiveSkillRepresentationType(BOX);
                      }}
                    >
                      <img
                        src={boxIcon}
                        alt="box-skill"
                        height={24}
                        style={
                          activeSkillRepresentationType === BOX
                            ? {
                                filter: "invert(100%)",
                                padding: "1px",
                              }
                            : skillRepresentationLevels.length > 5
                            ? {
                                opacity: 0.2,
                                    padding: "1px",
                              }
                            : {
                                padding: "1px",
                              }
                        }
                      />
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "13px",
                          fontWeight: 400,
                          color:
                            activeSkillRepresentationType === BOX
                              ? colors.light
                              : skillRepresentationLevels.length > 5
                              ? "rgba(0,0,0,0.26)"
                              : "rgba(0, 0, 0, 0.87)",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                        }}
                      >
                        Box
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "570px",
                  paddingTop: "80px",
                  paddingBottom: "80px",
                }}
              >
                {activeSkillRepresentationType === ALPHABET && (
                  <>
                    <img
                      src={alphanumericPresentation}
                      height={125}
                      alt="alphanumeric-presentation"
                    />
                    <img
                      src={alphanumericInfo}
                      height={46}
                      style={{ marginTop: "8px" }}
                      alt="alphanumeric-info"
                    />
                  </>
                )}
                {activeSkillRepresentationType === NUMBER && (
                  <>
                    <img
                      src={numberPresentation}
                      height={125}
                      alt="number-presentation"
                    />
                    <img
                      src={numberInfo}
                      height={46}
                      style={{ marginTop: "8px" }}
                      alt="number-info"
                    />
                  </>
                )}
                {activeSkillRepresentationType === CIRCLE && (
                  <>
                    <img
                      src={circlePresentation}
                      height={125}
                      alt="circle-presentation"
                    />
                    <img
                      src={circleInfo}
                      height={20}
                      style={{
                        marginTop: "8px",
                        marginBottom: "26px", // To align with alphanumeric
                      }}
                      alt="circle-info"
                    />
                  </>
                )}
                {activeSkillRepresentationType === DONUT && (
                  <>
                    <img
                      src={donutPresentation}
                      height={125}
                      alt="donut-presentation"
                    />
                    <img
                      src={donutInfo}
                      height={20}
                      style={{
                        marginTop: "8px",
                        marginBottom: "26px", // To align with alphanumeric
                      }}
                      alt="donut-info"
                    />
                  </>
                )}
                {activeSkillRepresentationType === BOX && (
                  <>
                    <img
                      src={boxPresentation}
                      height={125}
                      alt="box-presentation"
                    />
                    <img
                      src={boxInfo}
                      height={20}
                      style={{
                        marginTop: "8px",
                        marginBottom: "26px", // To align with alphanumeric
                      }}
                      alt="box-info"
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {activeStep === 1 && !loading && (
          <Box
            sx={{
              minHeight: "431px",
            }}
          >
            <Grid
              container
              sx={{
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {
                    // eslint-disable-next-line quotes
                    'Note: "Icon" will be used in Skill Matrix in the UI and "Level code" will be used while export cross skill in excel sheets.'
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
              >
                <Box
                  sx={{ width: "100%", display: "flex", flexDirection: "row" }}
                >
                  <CrossSkillingLevelsTableHeader />
                  <Box sx={{ width: "74px" }} />
                </Box>
                <Divider sx={{ width: "100%" }} />
                {skillRepresentationLevels.map(
                  (representationLevel, representationLevelIndex) => (
                    <Box
                      key={`rep-level-${representationLevel.id}`}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CrossSkillingLevelsTableRowEdit
                        skillRepresentationLevels={skillRepresentationLevels}
                        level={representationLevel.level}
                        repType={activeSkillRepresentationType}
                        repLevel={representationLevel.repLevel}
                        backColorCode={representationLevel.backColorCode}
                        levelCode={representationLevel.value}
                        icon={representationLevel.icon}
                        levelName={representationLevel.name}
                        levelDescription={representationLevel.desc}
                        toBeDeleted={representationLevel.toBeDeleted}
                        changeRepLevel={(event) => {
                          const value = event.target.value;
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  repLevel: value,
                                  repCode: `${activeSkillRepresentationType}-${value}`,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                        changeBackColorCode={(event) => {
                          const value = event.target.value;
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  backColorCode: value,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                        changeLevelCode={(value) => {
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  value: value,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                        changeIcon={(value) => {
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  icon: value,
                                  repCode: `${activeSkillRepresentationType}-${
                                    parseInt(
                                      String(existingRepresentationLevel.level)
                                    ) + 1
                                  }`,
                                  value: value,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                        changeLevelName={(value) => {
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  name: value,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                        changeLevelDescription={(value) => {
                          const newSkillRepresentationLevels: SkillLevel[] = [];
                          skillRepresentationLevels.forEach(
                            (existingRepresentationLevel) => {
                              if (
                                existingRepresentationLevel.id ===
                                representationLevel.id
                              ) {
                                newSkillRepresentationLevels.push({
                                  ...existingRepresentationLevel,
                                  desc: value,
                                });
                              } else {
                                newSkillRepresentationLevels.push(
                                  existingRepresentationLevel
                                );
                              }
                            }
                          );
                          setSkillRepresentationLevels(
                            newSkillRepresentationLevels
                          );
                        }}
                      />
                      <Box
                        sx={{
                          width: "74px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor:
                            (skillRepresentationLevels.filter(
                              (srl) => !srl.toBeDeleted
                            ).length === maxSkillLevels &&
                              representationLevel.level === 0) ||
                            representationLevelIndex === 0
                              ? "no-drop"
                              : "pointer",
                        }}
                        onClick={() => {
                          if (
                            (skillRepresentationLevels.filter(
                              (srl) => !srl.toBeDeleted
                            ).length === maxSkillLevels &&
                              representationLevel.level === 0) ||
                            representationLevelIndex === 0
                          ) {
                            // Do nothing
                          } else {
                            let found = false;
                            initialCrossSkillingLevelsData.forEach(
                              (initialLevel) => {
                                if (initialLevel.id !== 0) {
                                  found = true;
                                }
                              }
                            );
                            const levelIdToDelete = representationLevel.id;
                            const newSkillRepresentationLevels: SkillLevel[] =
                              [];
                            let existingSkillRepresentationLevels = [
                              ...skillRepresentationLevels,
                            ];
                            if (found) {
                              // While editing existing skill levels
                              let levelModifier = 0;
                              existingSkillRepresentationLevels.forEach(
                                (existingSkillRepresentationLevel, index) => {
                                  if (
                                    existingSkillRepresentationLevel.id ===
                                    levelIdToDelete
                                  ) {
                                    if (
                                      existingSkillRepresentationLevel.existing
                                    ) {
                                      if (
                                        skillRepresentationLevels.filter(
                                          (srl) => !srl.toBeDeleted
                                        ).length < maxSkillLevels &&
                                        existingSkillRepresentationLevel.toBeDeleted
                                      ) {
                                        // Only work when there are less than required skills
                                        newSkillRepresentationLevels.push({
                                          ...existingSkillRepresentationLevel,
                                          toBeDeleted: false,
                                          level: index - levelModifier,
                                        });
                                      } else {
                                        levelModifier += 1;
                                        newSkillRepresentationLevels.push({
                                          ...existingSkillRepresentationLevel,
                                          toBeDeleted: true,
                                          level: 0,
                                        });
                                      }
                                    } else {
                                      levelModifier += 1;
                                    }
                                  } else {
                                    if (
                                      existingSkillRepresentationLevel.toBeDeleted
                                    ) {
                                      newSkillRepresentationLevels.push({
                                        ...existingSkillRepresentationLevel,
                                        level: 0,
                                      });
                                      levelModifier += 1;
                                    } else {
                                      newSkillRepresentationLevels.push({
                                        ...existingSkillRepresentationLevel,
                                        level: index - levelModifier,
                                      });
                                    }
                                  }
                                }
                              );

                              // Check if there are any skill to be deleted
                              let haveSkillsToBeDeleted = false;
                              newSkillRepresentationLevels.forEach(
                                (newSkillRepresentationLevel) => {
                                  if (newSkillRepresentationLevel.toBeDeleted) {
                                    haveSkillsToBeDeleted = true;
                                  }
                                }
                              );
                              setShowDeleteWarning(haveSkillsToBeDeleted);

                              setSkillRepresentationLevels(
                                newSkillRepresentationLevels
                              );
                            } else {
                              // When create all new skill levels
                              existingSkillRepresentationLevels =
                                existingSkillRepresentationLevels.filter(
                                  (srl) => srl.id !== levelIdToDelete
                                );
                              existingSkillRepresentationLevels.forEach(
                                (existingSkillRepresentationLevel, index) => {
                                  newSkillRepresentationLevels.push({
                                    ...existingSkillRepresentationLevel,
                                    level: index,
                                  });
                                }
                              );
                              setSkillRepresentationLevels(
                                newSkillRepresentationLevels
                              );
                            }
                          }
                        }}
                      >
                        {representationLevel.toBeDeleted && (
                          <Box
                            sx={{
                              display: "flex",
                              padding: "4px 10px",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                              border: 1,
                              borderWidth: "1px",
                              borderColor:
                                skillRepresentationLevels.filter(
                                  (srl) => !srl.toBeDeleted
                                ).length < maxSkillLevels
                                  ? colors.stepperBackground
                                  : colors.gray,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontSize: "13px",
                                fontWeight: 500,
                                textTransform: "uppercase",
                                color:
                                  skillRepresentationLevels.filter(
                                    (srl) => !srl.toBeDeleted
                                  ).length < maxSkillLevels
                                    ? colors.stepperBackground
                                    : colors.gray,
                              }}
                            >
                              UNDO
                            </Typography>
                          </Box>
                        )}
                        {!representationLevel.toBeDeleted && (
                          <DeleteOutlineIcon
                            sx={{
                              color:
                                representationLevel.level === 0
                                  ? "rgba(0,0,0,0.38)"
                                  : "rgba(0,0,0,0.56)",
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )
                )}
                {skillRepresentationLevels.filter((srl) => !srl.toBeDeleted)
                  .length < maxSkillLevels && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      startIcon={<AddCircleIcon />}
                      onClick={() => {
                        const newSkillRepresentationLevels = [
                          ...skillRepresentationLevels,
                        ];
                        let newLevel = 1;
                        let newId = 1;
                        if (newSkillRepresentationLevels.length > 0) {
                          // Get level of last valid skill
                          for (
                            let i = newSkillRepresentationLevels.length - 1;
                            i >= 0;
                            i--
                          ) {
                            if (!newSkillRepresentationLevels[i].toBeDeleted) {
                              newLevel =
                                newSkillRepresentationLevels[i].level + 1;
                              newId = newSkillRepresentationLevels[i].id + 1;
                              break;
                            }
                          }
                        }
                        const isAlphabetOrNum =
                          activeSkillRepresentationType === ALPHABET ||
                          activeSkillRepresentationType === NUMBER;
                        newSkillRepresentationLevels.push({
                          id: newId,
                          level: newLevel,
                          repLevel: EMPTY,
                          repCode: `${activeSkillRepresentationType}-${
                            isAlphabetOrNum ? "1" : EMPTY
                          }`,
                          backColorCode: "",
                          foreColorCode: "",
                          value: "",
                          icon: "",
                          name: "",
                          desc: "",
                          toBeDeleted: false,
                          existing: false,
                        });
                        setSkillRepresentationLevels(
                          newSkillRepresentationLevels
                        );
                      }}
                    >
                      Level
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        {loading && <CircularProgress />}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "16px",
            marginBottom: "16px",
            marginRight: "24px",
            marginLeft: "24px",
            width: "100%",
          }}
        >
          {activeStep !== 0 ? (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          ) : (
            <Box />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => closeCrossSkillingLevelsDialog()}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={
                activeStep === steps.length - 1 ? !validateSkillLevels() : false
              }
              variant="contained"
              sx={{ marginLeft: "16px" }}
              onClick={handleNext}
              loading={updateCompanySettingsLevelStatus === "pending"}
              loadingIndicator={
                <CircularProgress
                  size={16}
                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                />
              }
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CrossSkillingLevelsDialog;
