// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DUMMY_PRIORITIES_ARRAY } from "../../../assets/constants";
import axios from "../../../utils/api-client";
import { appendPriorityToSkills } from "../../../utils/helpers";
import { Priority, SkillItem } from "../../../utils/types";
import { closeAddWorkerDialogReducer } from "./reducers/closeAddWorkerDialogReducer";
import { closeEditWorkerDialogReducer } from "./reducers/closeEditWorkerDialogReducer";
import { openAddWorkerDialogReducer } from "./reducers/openAddWorkerDialogReducer";
import { resetCreateNewWorkerReducer } from "./reducers/resetCreateNewWorkerReducer";
import { resetDeleteWorkersReducer } from "./reducers/resetDeleteWorkersReducer";
import { resetEditWorkerReducer } from "./reducers/resetEditWorkerReducer";

//Not in use
export const fetchAllWorkers: any = createAsyncThunk(
	"manageWorkers/fetchAllWorkers",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/workers`
			);
			//console.log(workers)
			return fulfillWithValue(workers.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchAllOrganizations: any = createAsyncThunk(
	"manageWorkers/fetchAllOrganizations",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const organizations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/organizations`
			);
			return fulfillWithValue(organizations.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

// export const fetchAllRegularShiftTypes = createAsyncThunk(
// 	"manageWorkers/fetchAllRegularShiftTypes",
// 	async (payload, { fulfillWithValue, rejectWithValue }) => {
// 		try {
// 			const regularShiftTypes = await axios.get(
// 				`${process.env.REACT_APP_API_END_POINT}/RShifts/AllTypes`
// 			);
// 			return fulfillWithValue(regularShiftTypes.data);
// 		} catch (error) {
// 			return rejectWithValue(error.message);
// 		}
// 	}
// );

export const fetchOrganizationTypes: any = createAsyncThunk(
	"manageWorkers/fetchOrganizationTypes",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const organizationTypes = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/organizationtypes`
			);
			return fulfillWithValue(organizationTypes.data);
		} catch (error: any) {
			return rejectWithValue(error.value);
		}
	}
);

export const fetchAllManagers: any = createAsyncThunk(
	"manageWorkers/fetchAllManagers",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const organizations = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/managers`
			);
			return fulfillWithValue(organizations.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const getExpLevel: any = createAsyncThunk(
	"manageWorkers/getExpLevel",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/getexplevels`
			);
			return fulfillWithValue(workers.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchWorkerProfile: any = createAsyncThunk(
	"manageWorkers/fetchWorkerProfile",
	/**  @param payload {workerId:string} */
	async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
		try {
			const workers = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/companies/workerprofile/${payload}`
			);
			return fulfillWithValue({ ...workers.data, workerId: payload });
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillLevels: any = createAsyncThunk(
	"manageWorkers/skillLevels",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillLevels = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Levels`
			);
			return fulfillWithValue(getSkillLevels.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrix: any = createAsyncThunk(
	"manageWorkers/fetchSkillMatrix",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrix = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetForUser`,
				payload
			);
			return fulfillWithValue(getSkillMatrix.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrixFilters: any = createAsyncThunk(
	"manageWorkers/fetchSkillMatrixFilters",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrixFilters = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetFiltersV2`
			);
			return fulfillWithValue(getSkillMatrixFilters.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchSkillMatrixDefaultFilters: any = createAsyncThunk(
	"manageWorkers/fetchSkillMatrixDefaultFilters",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const getSkillMatrixFilters = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetDefaultFilters`
			);
			return fulfillWithValue(getSkillMatrixFilters.data);
		} catch (error: any) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchManageWorkersData: any = createAsyncThunk(
	"manageWorkers/fetchManageWorkersData",
	async (
		payload: any,
		{ fulfillWithValue, rejectWithValue, getState }: any
	) => {
		const userId = getState().user.userData.id;
		// let getAll = payload.getAll ?? false;
		const version: any = payload.version ?? 1;
		// let url = `${process.env.REACT_APP_API_END_POINT}/companies/workers?getAll=${getAll}`;
		let url = `${process.env.REACT_APP_API_END_POINT}/SkillsMatrix/GetForUserV2`;
		if (version === 2) {
			url = `${process.env.REACT_APP_API_END_POINT}/companies/v2/workers`;
		}
		try {
			const getAllWorkers = axios.get(url);
			const getAllJobRoles = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Jobs/GetJobsByUser`,
				{
					params: {
						userid: userId,
					},
				}
			);
			const getAllSkills = axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);

			let [allWorkers, allSkills, allJobRoles]: any = await Promise.all([
				getAllWorkers,
				getAllSkills,
				getAllJobRoles,
			]);

			allWorkers = allWorkers.data;
			allSkills = allSkills.data;
			allJobRoles = allJobRoles.data;

			const normalizedJobRoles: any = {};

			allJobRoles.length > 0 &&
				allJobRoles.forEach((jobRole: any) => {
					normalizedJobRoles[jobRole.id] = jobRole;
				});

			return fulfillWithValue({ allWorkers, allSkills, normalizedJobRoles });
		} catch (error: any) {
			// console.error(error);
			return rejectWithValue(error.message);
		}
	}
);

export const addSkillInterested: any = createAsyncThunk(
	"manageWorker/addSkillInterested",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const skillInterested = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillInterest/${payload}/A`
			);
			const data = skillInterested.data;
			return fulfillWithValue(data);
		} catch (error: any) {
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const rejectSkillInterested: any = createAsyncThunk(
	"manageWorker/rejectSkillInterested",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const skillInterested = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillInterest/${payload}/R`
			);
			const data = skillInterested.data;
			return fulfillWithValue(data);
		} catch (error: any) {
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const createNewWorker: any = createAsyncThunk(
	"manageWorkers/createNewWorker",
	async (
		payload: any,
		{ fulfillWithValue, rejectWithValue, getState }: any
	) => {
		const {
			firstName,
			lastName,
			companyEmployeeId,
			mobilePhoneNumber,
			// workerTypeId,
			jobs,
			jobTitle,
			workerJobs,
			skills,
			joiningDate,
			orgUnitIds,
			locationId,
			rShiftIds,
			managerUserId,
			workCenterId,
			shiftPatternId,
		} = payload;
		let workerTypeId = payload.workerTypeId;
		let orgIds = "";
		if (orgUnitIds.length !== 0) {
			orgIds = orgUnitIds.toString();
		}

		let jobIds: any = [];
		if (jobs) {
			jobIds = Object.keys(jobs).reduce((acc: any, key: any) => {
				if (jobs[key] === true) acc.push(key);
				return acc;
			}, []);
		}

		if (jobIds.length !== 0) {
			jobIds = jobIds.toString();
		} else {
			// return rejectWithValue("Please select at least one job role");
			jobIds = "";
		}
		workerTypeId = Number.parseInt(workerTypeId);

		const user = getState().user.userData.id;
		const phone = mobilePhoneNumber.split("").reduce((acc: any, curr: any) => {
			if (Number.isInteger(Number.parseInt(curr))) {
				acc += curr;
			}
			return acc;
		}, "+");

		const workerData = {
			firstName,
			lastName,
			companyEmployeeId,
			mobilePhoneNumber: phone.length > 1 ? phone : null,
			workerTypeId,
			jobIds,
			jobTitle,
			skills,
			createdBy: `${user}`,
			companyId: 1,
			workerJobs,
			joiningDate,
			orgUnitIds: orgIds,
			locationId,
			rShiftIds,
			managerUserId,
			workCenterId,
			shiftPatternId,
		};
		try {
			const newWorker = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Create`,
				workerData
			);
			const data = newWorker.data;
			if (newWorker.status === 200) {
				return fulfillWithValue(data);
			} else {
				return rejectWithValue("Error while creating new worker");
			}
		} catch (error: any) {
			if (Object.prototype.hasOwnProperty.call(error, "message")) {
				if (
					error.message === "Request failed with status code 500" ||
					error.message === "Request failed with status code 400"
				) {
					if (error.response.data.message) {
						return rejectWithValue(error.response.data.message);
					} else {
						return rejectWithValue("Error while creating new worker");
					}
				} else {
					return rejectWithValue(error.message);
				}
			} else if (
				Object.prototype.hasOwnProperty.call(error, "MobilePhoneNumber")
			) {
				if (error.MobilePhoneNumber[0]) {
					return rejectWithValue(error.MobilePhoneNumber[0]);
				} else {
					return rejectWithValue("Error while creating new worker");
				}
			} else {
				return rejectWithValue("Error while creating new worker");
			}
		}
	}
);

export const deleteWorkers: any = createAsyncThunk(
	"manageWorkers/deleteWorkers",
	async (payload: any, { fulfillWithValue, rejectWithValue }) => {
		try {
			const response = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Delete`,
				{
					params: {
						workerIds: payload.toString(),
					},
				}
			);
			if (response.status === 200) return fulfillWithValue(payload);
			else return rejectWithValue("An error occurred");
		} catch (error: any) {
			if (error.response.status === 500)
				return rejectWithValue(error.response.data.message);
			return rejectWithValue(error.message);
		}
	}
);

export const editWorker: any = createAsyncThunk(
	"manageWorkers/editWorker",
	async (
		payload: any,
		{ fulfillWithValue, rejectWithValue, getState }: any
	) => {
		const workerId = getState().manageWorkers.editWorkerDialog.workerId;
		const allWorkers = getState().manageWorkers.allWorkers;
		let oldWorkerData = allWorkers.filter(
			(worker: any) => worker.id === workerId
		);
		if (payload?.ignoreExistingShifts !== undefined) {
			const {
				firstName,
				lastName,
				mobilePhoneNumber,
				// workerTypeId,
				//  ignoreExistingShifts,
				//  removeWorkerFromShifts,
				jobs,
				jobTitle,
				skills,
				workerJobs,
				joiningDate,
				orgUnitIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
			} = payload;
			let workerTypeId = payload.workerTypeId;
			let orgIds = "";
			if (orgUnitIds.length !== 0) {
				orgIds = orgUnitIds.toString();
			}

			let jobIds = Object.keys(jobs).reduce((acc: any, key: any) => {
				if (jobs[key] === true) acc.push(key);
				return acc;
			}, []);

			if (jobIds.length !== 0) {
				jobIds = jobIds.toString();
			} else {
				//return rejectWithValue("Please select at least one job role");
				jobIds = "";
			}
			workerTypeId = Number.parseInt(workerTypeId);

			// Sending jobId in request
			// It's jobIds in data

			const user = getState().user.userData.id;
			const phone =
				mobilePhoneNumber.length > 3
					? mobilePhoneNumber.split("").reduce((acc: any, curr: any) => {
							if (Number.isInteger(Number.parseInt(curr))) {
								acc += curr;
							}
							return acc;
					  }, "+")
					: null;

			const workerData = {
				firstName,
				lastName,
				mobilePhoneNumber: phone.length > 1 ? phone : null,
				workerTypeId,
				jobId: jobIds,
				jobTitle,
				skills,
				workerJobs,
				updatedBy: user,
				joiningDate,
				orgUnitIds: orgIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
			};

			oldWorkerData = oldWorkerData[0];

			let requestPayload = {
				...oldWorkerData,
				...workerData,
			};

			if (payload?.ignoreExistingShifts !== undefined)
				requestPayload = {
					...requestPayload,
					ignoreExistingShifts: true,
					removeWorkerFromShifts: payload?.removeWorkerFromShifts,
				};

			try {
				const editedWorker = await axios.put(
					`${process.env.REACT_APP_API_END_POINT}/Workers/Put`,
					requestPayload
				);

				if (editedWorker.status === 204) {
					requestPayload["jobIds"] = requestPayload?.jobId;

					switch (requestPayload["workerTypeId"]) {
						case 1:
							requestPayload["type"] = "FullTime";
							break;
						case 2:
							requestPayload["type"] = "PartTime";
							break;
						case 3:
							requestPayload["type"] = "FlexWorker";
							break;
						default:
							requestPayload["type"] = "FullTime";
							break;
					}
					// requestPayload["workerTypeId"] = requestPayload["workerTypeId"].toString();
					return fulfillWithValue({ workerId, data: requestPayload });
				}
			} catch (error: any) {
				if (error.response.data.shifts && error.response.data.shifts.length > 0)
					return rejectWithValue(error.response.data);
				if (error.message === "Request failed with status code 400")
					return rejectWithValue({ message: "An error occurred" });
				return rejectWithValue({ message: error.response.data });
			}
		} else {
			const {
				firstName,
				lastName,
				mobilePhoneNumber,
				// workerTypeId,
				jobs,
				jobTitle,
				skills,
				workerJobs,
				joiningDate,
				orgUnitIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
			} = payload;
			let workerTypeId = payload.workerTypeId;
			let orgIds = "";
			if (orgUnitIds.length !== 0) {
				orgIds = orgUnitIds.toString();
			}
			let jobIds = Object.keys(jobs).reduce((acc: any, key: any) => {
				if (jobs[key] === true) acc.push(key);
				return acc;
			}, []);

			if (jobIds.length !== 0) {
				jobIds = jobIds.toString();
			} else {
				//return rejectWithValue("Please select at least one job role");
				jobIds = "";
			}
			workerTypeId = Number.parseInt(workerTypeId);

			// Sending jobId in request
			// It's jobIds in data

			const user = getState().user.userData.id;
			const phone = mobilePhoneNumber
				.split("")
				.reduce((acc: any, curr: any) => {
					if (Number.isInteger(Number.parseInt(curr))) {
						acc += curr;
					}
					return acc;
				}, "+");

			const workerData = {
				firstName,
				lastName,
				mobilePhoneNumber: phone.length > 3 ? phone : null,
				workerTypeId,
				jobId: jobIds,
				jobTitle,
				workerJobs,
				skills,
				updatedBy: user,
				joiningDate,
				orgUnitIds: orgIds,
				companyEmployeeId,
				locationId,
				rShiftIds,
				managerUserId,
				workCenterId,
				shiftPatternId,
			};

			oldWorkerData = oldWorkerData[0];

			const requestPayload = {
				...oldWorkerData,
				...workerData,
			};

			try {
				const editedWorker = await axios.put(
					`${process.env.REACT_APP_API_END_POINT}/Workers/Put`,
					requestPayload
				);

				if (editedWorker.status === 204) {
					requestPayload["jobIds"] = requestPayload?.jobId;

					switch (requestPayload["workerTypeId"]) {
						case 1:
							requestPayload["type"] = "FullTime";
							break;
						case 2:
							requestPayload["type"] = "PartTime";
							break;
						case 3:
							requestPayload["type"] = "FlexWorker";
							break;
						default:
							requestPayload["type"] = "FullTime";
							break;
					}
					// requestPayload["workerTypeId"] = requestPayload["workerTypeId"].toString();
					return fulfillWithValue({ workerId, data: requestPayload });
				}
			} catch (error: any) {
				if (error.response.data.shifts && error.response.data.shifts.length > 0)
					return rejectWithValue(error.response.data);

				if (error.response.status === 500 || error.response.status === 400)
					return rejectWithValue({ message: error.response.data.message });
				if (error.message === "Request failed with status code 400")
					return rejectWithValue({ message: "An error occurred" });
				return rejectWithValue({ message: error.response.data });
			}
		}
	}
);

export const updateWorkerJobExpiry: any = createAsyncThunk(
	"manageWorkers/updateWorkerJobExpiry",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		const { status, jobId, workerId }: any = payload;
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/workerjob`,
				{
					workerId: workerId,
					JobId: jobId,
					IsExpired: status,
				}
			);
			if (response.status === 200) return fulfillWithValue(payload);
			else
				return rejectWithValue({ error: "An error occurred", data: payload });
		} catch (error: any) {
			if (error.response.status === 500) {
				return rejectWithValue({
					error: error.response.data.message ?? "An error occurred",
					data: payload,
				});
			} else {
				return rejectWithValue({
					error: error.message ?? "An error occurred",
					data: payload,
				});
			}
		}
	}
);

export const updateWorkerSkillLevel: any = createAsyncThunk(
	"manageWorker/updateWorkerSkillLevel",
	async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
		try {
			const useObsoleteSkills = getState().user.userData.useObsoleteSkills;
			const skillPriorities =
				getState().manageWorkers.skillMatrixFiltersSkillPriorities;
			const skillLevels =
				getState().manageWorkers.skillMatrixFiltersSkillLevels;
			const skills = getState().manageWorkers.skillMatrixFiltersSkills;

			const res = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/workers/SkillLevel`,
				payload
			);
			if (res.status === 200) {
				const data = {
					payload: payload,
					priorities: skillPriorities,
					levels: skillLevels,
					skills: skills,
					useObsoleteSkills: useObsoleteSkills,
				};
				return fulfillWithValue(data);
			}

			return fulfillWithValue(payload);
		} catch (error: any) {
			if (error.message === "Request failed with status code 400")
				return rejectWithValue("Error adding skill interested.");
			else return rejectWithValue(error.response.data);
		}
	}
);

export const getWorkerTypes: any = createAsyncThunk(
	"manageWorker/get-worker-type",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Workers/Types`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			} else if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// Check employee Id or worker Id uniqueness
export const isEmployeeIdUnique: any = createAsyncThunk(
	"manageWorker/isEmployeeIdUnique",
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Workers/IsEmployeeIdUnique?companyEmployeeId=${payload}`
			);
			if (res.status === 200) {
				return fulfillWithValue({
					employeeId: payload,
					isUnique: res.data,
				});
			} else {
				return fulfillWithValue({
					employeeId: payload,
					isUnique: false,
				});
			}
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// Fetch all locations by user id
export const fetchUserLocations: any = createAsyncThunk(
	"manageWorker/fetchUserLocations",
	async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
		try {
			const userId = getState().user.userData.id;
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Locations/GetLocationsByUser?userid=${userId}`
			);
			if (res.status === 200) {
				return fulfillWithValue(res.data);
			} else if (res.status === 204) {
				return fulfillWithValue([]);
			}
			return rejectWithValue("An error occurred");
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all Teams by location
export const getLocationShiftPatterns: any = createAsyncThunk(
	"manageWorkers/get-location-shift-patterns",
	/**  @param payload {locationId:number} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const { locationId }: any = payload;
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/patterns/${locationId}`
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to get all shift types
export const getAllShiftTypes: any = createAsyncThunk(
	"manageWorkers/get-all-shift-types",
	/**  @param payload {locationId:number} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		const { locationId }: any = payload;
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/ftshifts/types/${locationId}`
			);
			if (res.status === 200) return fulfillWithValue(res.data);
			else if (res.status === 204) return fulfillWithValue([]);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to delete skills for worker
export const deleteWorkerSkills: any = createAsyncThunk(
	"manageWorkers/delete-worker-skills",
	/**  @param payload {workerId:string, skillId:number} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		const { workerId, skillId }: any = payload;
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill/${workerId}/${skillId}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to delete certifications for worker
export const deleteWorkerCertifications: any = createAsyncThunk(
	"manageWorkers/delete-worker-certifications",
	/**  @param payload {workerCertId:string} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		const { workerCertId }: any = payload;
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert/${workerCertId}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to add worker skills
export const addWorkerSkills: any = createAsyncThunk(
	"manageWorkers/add-worker-skills",
	/**  @param payload {workerId:string, skillId:number}[] */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		// const { workerId, skillId } = payload;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill/`,
				payload
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to modify worker skill
export const modifyWorkerSkill: any = createAsyncThunk(
	"manageWorkers/modify-worker-skill",
	/**  @param payload {workerId:string, skillId:number, levelId:number, validFrom: string, validTo: string} */
	async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
		const { skillId, levelId, validFrom, validTo }: any = payload;
		const workerId = getState().manageWorkers.editWorkerDialog.workerId;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/skill`,
				[
					{
						WorkerId: workerId,
						SkillId: skillId,
						LevelId: levelId,
						ValidFrom: validFrom,
						ValidTo: validTo,
					},
				]
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to add worker certification

export const addWorkerCertifications: any = createAsyncThunk(
	"manageWorkers/add-worker-certifications",
	/**  @param payload {workerId:string, CompanyCertId:number}[] */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert/`,
				payload
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to edit worker certification
export const editWorkerCertification: any = createAsyncThunk(
	"manageWorkers/edit-worker-certification",
	/**  @param payload {workerId:string, certificationId:number, validFrom: string, validTo: string} */
	async (payload, { fulfillWithValue, rejectWithValue, getState }: any) => {
		const { companyCertId, validFrom, validTo, isActive }: any = payload;
		const workerId = getState().manageWorkers.editWorkerDialog.workerId;
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/cert`,
				[
					{
						WorkerId: workerId,
						CompanyCertId: companyCertId,
						ValidFrom: validFrom,
						ValidTo: validTo,
						isActive: isActive,
					},
				]
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to upload worker certification
export const uploadWorkerCertification: any = createAsyncThunk(
	"manageWorkers/upload-worker-certification",
	/**  @param payload {workerCertId : number, file: file} */
	async (payload: any, { fulfillWithValue, rejectWithValue, getState }) => {
		// const { workerCertId, file } = payload;
		const formData = new FormData();
		formData.append("WorkerCertId", payload.workerCertId);
		formData.append("File", payload.file);
		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/workers/certupload`,
				formData
			);
			if (res.status === 200) return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

export const deleteWorkerCertificationFile: any = createAsyncThunk(
	"manageWorkers/delete-worker-certification-file",
	/**  @param payload {workerCertId : number} */
	async (payload, { fulfillWithValue, rejectWithValue }) => {
		try {
			const res = await axios.delete(
				`${process.env.REACT_APP_API_END_POINT}/workers/certfile/${payload}`
			);
			if (res.status === 200) return fulfillWithValue(payload);
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

// API to download worker certificate
export const downloadWorkerCertification: any = createAsyncThunk(
	"manageWorkers/download-worker-certification",
	/**  @param payload {workerCertFileId : number, fileName: string} */
	async (
		payload: any,
		{ fulfillWithValue, rejectWithValue, dispatch }: any
	) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/workers/certfileV2/${payload.workerCertFileId}`,
				{
					responseType: "blob",
					onDownloadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						// console.log("Download progress:", progress);
						dispatch(
							setDownloadProgress({
								workerCertFileId: payload.workerCertFileId,
								progress,
							})
						);
					},
				}
			);
			if (res.status === 200) {
				// Create a blob from the response data
				const blob = new Blob([res.data], {
					type: res.headers["content-type"],
				});
				const url = window.URL.createObjectURL(blob);

				// Extract the filename from the Content-Disposition header
				const contentDisposition = res.headers["content-disposition"];
				// let fileName = "downloaded-file";
				// if (contentDisposition) {
				// 	const matches = /filename="([^"]*)"/.exec(contentDisposition);
				// 	if (matches != null && matches[1]) {
				// 	fileName = matches[1];
				// }
				// }
				const fileName = payload.fileName;

				// Create a link element and trigger the download
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				dispatch(
					resetDownloadProgress({ workerCertFileId: payload.workerCertFileId })
				);
				return fulfillWithValue();
			} else {
				return rejectWithValue("Failed to download file");
			}
		} catch (error: any) {
			try {
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

const initialState: any = {
	addWorkerDialog: {
		isOpen: false,
	},
	editWorkerDialog: {
		isOpen: false,
		workerId: "",
		currentStep: 0,
	},
	editWorkerSkillDialog: {
		isOpen: false,
		skillData: null,
		certificationData: null,
	},
	editWorkerCertificationDialog: {
		isOpen: false,
		certificationData: null,
	},
	allWorkers: [],
	allOrganizations: [],
	allOrganizationTypes: [],
	allRegularShiftTypes: [],
	userLocations: [],
	allManagers: [],
	allJobRoles: [],
	allSkills: [],
	locationShiftPatterns: [],
	fetchAllWorkers: {
		status: "idle",
		errorMessage: "",
	},
	fetchAllOrganizations: {
		status: "idle",
		errorMessage: "",
	},
	// fetchAllRegularShiftTypes: {
	// 	status: "idle",
	// 	errorMessage: "",
	// },
	fetchOrganizationTypes: {
		status: "idle",
		errorMessage: "",
	},
	fetchUserLocations: {
		status: "idle",
		errorMessage: "",
	},
	fetchAllManagers: {
		status: "idle",
		errorMessage: "",
	},
	fetchManageWorkersData: {
		status: "idle",
		errorMessage: "",
	},
	createNewWorker: {
		status: "idle",
		errorMessage: "",
	},
	addWorkerSkills: {
		status: "idle",
		errorMessage: "",
	},
	addWorkerCertifications: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkers: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkerSkills: {
		status: "idle",
		errorMessage: "",
	},
	deleteWorkerCertifications: {
		status: "idle",
		errorMessage: "",
	},
	editWorkerCertification: {
		status: "idle",
		errorMessage: "",
	},
	editWorker: {
		status: "idle",
		errorMessage: "",
		shifts: [],
	},
	editWorkerSkill: {
		status: "idle",
		errorMessage: "",
	},
	updateWorkerJobExpiry: {
		status: "idle",
		errorMessage: "",
	},
	workerDetails: {
		data: null,
	},
	workerProfile: {
		status: "idle",
		errorMessage: "",
		profile: null,
		scoreItems: null,
		isOpen: false,
		workerId: "",
	},
	getExpLevel: {
		status: "idle",
		errorMessage: "",
	},
	addSkillInterested: {
		status: "idle",
		errorMessage: "",
	},
	rejectSkillInterested: {
		status: "idle",
		errorMessage: "",
	},
	updateSkillLevel: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillLevels: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillMatrix: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillMatrixFilters: {
		status: "idle",
		errorMessage: "",
	},
	fetchSkillMatrixDefaultFilters: {
		status: "idle",
		errorMessage: "",
	},
	skillMatrixAppliedFilters: {},
	postUploadFiles: {
		status: "idle",
		successfulUploads: 0,
		failedUploads: 0,
	},
	postDeleteFiles: {
		status: "idle",
		successfulDeletes: 0,
		failedDeletes: 0,
	},
	skillMatrixData: [],
	skillMatrixFiltersJobRoles: {},
	skillMatrixFiltersAllJobs: [],
	skillMatrixFiltersPriorities: [],
	skillMatrixFiltersOrgTypes: [],
	skillMatrixFiltersOrganizations: [],
	skillMatrixFiltersSkillPriorities: [],
	skillMatrixFiltersLocations: [],
	skillMatrixFiltersManagers: [],
	skillMatrixFiltersWorkCenters: [],
	skillMatrixFiltersWorkerTypes: [],
	skillMatrixFiltersSkills: [],
	skillMatrixFiltersSkillLevels: [],
	skillLevels: [], // This is company level skills levels
	expLevel: [], // This is exp Level of all workers for Worker table
	getWorkerTypes: {
		status: "idle",
		errorMessage: "",
	},
	getLocationShiftPatterns: {
		status: "idle",
		errorMessage: "",
	},
	workerTypes: [],
	getAllShiftTypes: {
		status: "idle",
		errorMessage: "",
		data: [],
	},
	downloadWorkerCertification: {
		status: "idle",
		errorMessage: "",
		progress: {},
		errors: {},
	},
	skillPriorities: [...DUMMY_PRIORITIES_ARRAY],
	isEmployeeIdUnique: {
		status: "idle",
		errorMessage: "",
		data: {
			originalEmployeeId: "",
			employeeId: "",
			isUnique: false,
			checked: false,
		},
	},
};

export const ManageWorkersSlice = createSlice({
	name: "manageWorkers",
	initialState: initialState,
	reducers: {
		openAddWorkerDialog: openAddWorkerDialogReducer,
		closeAddWorkerDialog: closeAddWorkerDialogReducer,
		openEditWorkerSkillDialog: (state, action) => {
			state.editWorkerSkillDialog.isOpen = true;
			state.editWorkerSkillDialog.skillData = action.payload;
		},
		closeEditWorkerSkillDialog: (state) => {
			state.editWorkerSkillDialog.isOpen = false;
			state.editWorkerSkillDialog.skillData = null;
			state.editWorkerSkillDialog.certificationData = null;
		},
		openEditWorkerCertificationDialog: (state, action) => {
			state.editWorkerCertificationDialog.isOpen = true;
			state.editWorkerCertificationDialog.certificationData = action.payload;
		},
		closeEditWorkerCertificationDialog: (state) => {
			state.editWorkerCertificationDialog.isOpen = false;
			state.editWorkerCertificationDialog.certificationData = null;
		},
		setWorkerDetails: (state, action) => {
			state.workerDetails.data = action.payload;
		},
		resetWorkerDetails: (state) => {
			state.workerDetails.data = null;
			state.workerProfile.profile = null;
			state.workerProfile.scoreItems = null;
			state.workerProfile.errorMessage = "";
			state.workerProfile.status = "idle";
		},
		resetAddSkillInterested: (state) => {
			state.addSkillInterested.status = "idle";
			state.addSkillInterested.errorMessage = "";
		},
		openEditWorkerDialog: (state, action) => {
			state.editWorkerDialog.isOpen = true;
			state.editWorkerDialog.workerId = action.payload.workerId;
			state.editWorkerDialog.currentStep = action.payload.step;
			state.editWorker.status = "idle";
			state.editWorker.errorMessage = "";
		},
		changeEditWorkerStep: (state, action) => {
			state.editWorkerDialog.currentStep = action.payload.step;
		},
		closeEditWorkerDialog: closeEditWorkerDialogReducer,
		resetCreateNewWorker: resetCreateNewWorkerReducer,
		resetEditWorker: resetEditWorkerReducer,
		resetDeleteWorkers: resetDeleteWorkersReducer,
		resetWorkerJobStatus: (state) => {
			state.updateWorkerJobExpiry.status = "idle";
			state.updateWorkerJobExpiry.errorMessage = "";
		},
		resetSkillLevelStatus: (state) => {
			state.updateSkillLevel.status = "idle";
			state.updateSkillLevel.errorMessage = "";
		},
		resetSkillMatrixFilter: (state) => {
			state.fetchSkillMatrixFilters.status = "idle";
			state.fetchSkillMatrixFilters.errorMessage = "";
		},
		resetSkillMatrix: (state) => {
			state.fetchSkillMatrix.status = "idle";
			state.fetchSkillMatrix.errorMessage = "";
		},
		resetSkillMatrixDefaultFilters: (state) => {
			state.fetchSkillMatrixDefaultFilters.status = "idle";
			state.fetchSkillMatrixDefaultFilters.errorMessage = "";
		},
		setJobExpiry: (state: any, action: any) => {
			const { status, jobId, workerId } = action.payload;
			const index = state.allWorkers.findIndex((el: any) => el.id === workerId);
			const temp = state.allWorkers[index];
			state.allWorkers[index] = {
				...temp,
				workerJobs: temp.workerJobs.map((job: any) => {
					if (job.jobId === jobId) return { jobId: jobId, isExpired: status };
					return job;
				}),
			};
		},
		openWorkerProfile: (state, action) => {
			state.workerProfile.isOpen = true;
			state.workerProfile.workerId = action.payload;
		},
		closeWorkerProfile: (state) => {
			state.workerProfile.isOpen = false;
			state.workerProfile.workerId = "";
		},
		resetWorkerTypes: (state) => {
			state.getWorkerTypes.status = "idle";
			state.getWorkerTypes.errorMessage = "";
			state.workerTypes = [];
		},
		resetDeleteWorkerSkills: (state) => {
			state.deleteWorkerSkills.status = "idle";
			state.deleteWorkerSkills.errorMessage = "";
		},
		resetAddWorkerSkills: (state) => {
			state.addWorkerSkills.status = "idle";
			state.addWorkerSkills.errorMessage = "";
		},
		resetEditWorkerSkill: (state) => {
			state.editWorkerSkill.status = "idle";
			state.editWorkerSkill.errorMessage = "";
		},
		resetDeleteWorkerCertifications: (state) => {
			state.deleteWorkerCertifications.status = "idle";
			state.deleteWorkerCertifications.errorMessage = "";
		},
		resetAddWorkerCertifications: (state) => {
			state.addWorkerCertifications.status = "idle";
			state.addWorkerCertifications.errorMessage = "";
		},
		resetEditWorkerCertification: (state) => {
			state.editWorkerCertification.status = "idle";
			state.editWorkerCertification.errorMessage = "";
		},
		setUploadedFiles: (state: any, action) => {
			try {
				state.workerProfile.profile.workerCerts =
					state.workerProfile.profile.workerCerts.map((cert: any) => {
						if (cert.workerCertId === action.payload.workerCertId) {
							return {
								...cert,
								workerCertFiles: [...cert.workerCertFiles, action.payload],
							};
						}
						return cert;
					});
			} catch (e) {
				console.log(e);
			}
		},
		setDeletedFiles: (state: any, action) => {
			try {
				state.workerProfile.profile.workerCerts =
					state.workerProfile.profile.workerCerts.map((cert: any) => {
						// if (cert.workerCertId === action.payload.workerCertId) {
						return {
							...cert,
							workerCertFiles: cert.workerCertFiles.filter(
								(file: any) =>
									parseInt(file.workerCertFileId) !==
									parseInt(action.payload.workerCertFileId)
							),
						};
						// }
						// return cert;
					});
			} catch (e) {
				console.log(e);
			}
		},
		resetPostUploadFiles: (state) => {
			state.postUploadFiles.status = "idle";
			state.postUploadFiles.successfulUploads = 0;
			state.postUploadFiles.failedUploads = 0;
		},
		resetPostDeleteFiles: (state) => {
			state.postDeleteFiles.status = "idle";
			state.postDeleteFiles.successfulDeletes = 0;
			state.postDeleteFiles.failedDeletes = 0;
		},
		setPostUploadStatus: (state, action) => {
			state.postUploadFiles.status = action.payload;
		},
		setPostDeleteStatus: (state, action) => {
			state.postDeleteFiles.status = action.payload;
		},
		setDownloadProgress: (state: any, action) => {
			const { workerCertFileId, progress } = action.payload;
			state.downloadWorkerCertification.progress[workerCertFileId] = progress;
		},
		setDownloadError: (state: any, action) => {
			const { workerCertFileId, error } = action.payload;
			state.downloadWorkerCertification.errors[workerCertFileId] = error;
		},
		resetDownloadProgress: (state: any, action) => {
			const { workerCertFileId } = action.payload;
			delete state.downloadWorkerCertification.progress[workerCertFileId];
		},
		resetFetchAllOrganizations: (state) => {
			state.fetchAllOrganizations.status = "idle";
			state.fetchAllOrganizations.errorMessage = "";
		},
		resetIsEmployeeIdUnique: (state) => {
			state.isEmployeeIdUnique.status = "idle";
			state.isEmployeeIdUnique.errorMessage = "";
			state.isEmployeeIdUnique.data = {
				originalEmployeeId: "",
				employeeId: "",
				isUnique: false,
				checked: false,
			};
		},
		setIsEmployeeIdUnique: (state, action) => {
			state.isEmployeeIdUnique.status = "idle";
			state.isEmployeeIdUnique.errorMessage = "";
			state.isEmployeeIdUnique.data = {
				originalEmployeeId: action.payload.originalEmployeeId,
				employeeId: action.payload.employeeId,
				isUnique: action.payload.isUnique,
				checked: action.payload.checked,
			};
		},
	},
	extraReducers: {
		[fetchAllWorkers.fulfilled]: (state, action) => {
			// To handle empty string instead of empty array from api
			state.allWorkers = Array.isArray(action.payload) ? action.payload : [];
			state.fetchAllWorkers.status = "fulfilled";
		},
		[fetchAllWorkers.pending]: (state) => {
			state.fetchAllWorkers.status = "pending";
		},
		[fetchAllWorkers.rejected]: (state, action) => {
			state.fetchAllWorkers.status = "error";
			state.fetchAllWorkers.errorMessage =
				action.payload ?? "An error occurred";
		},
		// [fetchAllRegularShiftTypes.fulfilled]: (state, action) => {
		// 	const convertTimeToStandard = (time) => {
		// 		try {
		// 			time = time.split(":");

		// 			let hours = Number(time[0]);
		// 			let minutes = Number(time[1]);
		// 			let timeValue;

		// 			if (hours > 0 && hours <= 12) {
		// 				timeValue= "" + hours;
		// 			} else if (hours > 12) {
		// 				timeValue= "" + (hours - 12);
		// 			} else if (hours === 0) {
		// 				timeValue= "12";
		// 			}

		// 			timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
		// 			timeValue += (hours >= 12) ? " PM" : " AM";
		// 			return timeValue;
		// 		} catch (e) {
		// 			return "";
		// 		}
		// 	}
		// 	state.allRegularShiftTypes = Array.isArray(action.payload) ? action.payload.map(rShiftType => {
		// 		const scheduleArray = rShiftType.schedule
		// 			? rShiftType.schedule.length > 0 ?
		// 				rShiftType.schedule : [] : [];
		// 		let nameWithTime = rShiftType.name;
		// 		if (scheduleArray.length > 0) {
		// 			if (Object.prototype.hasOwnProperty.call(rShiftType.schedule[0], "startTime") &&
		// 				Object.prototype.hasOwnProperty.call(rShiftType.schedule[0], "endTime")) {
		// 				nameWithTime = `${rShiftType.name} (${convertTimeToStandard(rShiftType.schedule[0].startTime)} - ${convertTimeToStandard(rShiftType.schedule[0].endTime)})`;
		// 			}
		// 		}
		// 		return {
		// 			...rShiftType,
		// 			nameWithTime: nameWithTime,
		// 		}
		// 	}) : [];
		// 	state.fetchAllRegularShiftTypes.status = "fulfilled";
		// 	state.fetchAllRegularShiftTypes.errorMessage = "";
		// },
		// [fetchAllRegularShiftTypes.pending]: (state) => {
		// 	state.fetchAllRegularShiftTypes.status = "pending";
		// 	state.fetchAllRegularShiftTypes.errorMessage = "";
		// },
		// [fetchAllRegularShiftTypes.rejected]: (state, action) => {
		// 	state.fetchAllRegularShiftTypes.status = "error";
		// 	state.fetchAllRegularShiftTypes.errorMessage = action.payload ?? "An error occurred";
		// },
		[fetchAllOrganizations.fulfilled]: (state, action) => {
			state.allOrganizations = action.payload;
			state.fetchAllOrganizations.status = "fulfilled";
			state.fetchAllOrganizations.errorMessage = "";
		},
		[fetchAllOrganizations.pending]: (state) => {
			state.fetchAllOrganizations.status = "pending";
			state.fetchAllOrganizations.errorMessage = "";
		},
		[fetchAllOrganizations.rejected]: (state, action) => {
			state.fetchAllOrganizations.status = "error";
			state.fetchAllOrganizations.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchOrganizationTypes.fulfilled]: (state, action) => {
			state.allOrganizationTypes = action.payload;
			state.fetchOrganizationTypes.status = "fulfilled";
		},
		[fetchOrganizationTypes.pending]: (state) => {
			state.fetchOrganizationTypes.status = "pending";
		},
		[fetchOrganizationTypes.rejected]: (state, action) => {
			state.fetchOrganizationTypes.status = "error";
			state.fetchOrganizationTypes.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchAllManagers.fulfilled]: (state, action) => {
			state.allManagers = action.payload;
			state.fetchAllManagers.status = "fulfilled";
		},
		[fetchAllManagers.pending]: (state) => {
			state.fetchAllManagers.status = "pending";
		},
		[fetchAllManagers.rejected]: (state, action) => {
			state.fetchAllManagers.status = "error";
			state.fetchAllManagers.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchManageWorkersData.fulfilled]: (state: any, action) => {
			const { allWorkers, allSkills, normalizedJobRoles } = action.payload;
			// To handle empty string instead of empty array from api
			state.allWorkers = Array.isArray(allWorkers) ? allWorkers : [];
			state.allJobRoles = normalizedJobRoles;
			state.allSkills = allSkills ? allSkills : [];
			state.fetchManageWorkersData.status = "fulfilled";
		},
		[fetchManageWorkersData.pending]: (state) => {
			state.fetchManageWorkersData.status = "pending";
		},
		[fetchManageWorkersData.rejected]: (state, action) => {
			state.fetchManageWorkersData.status = "error";
			state.fetchManageWorkersData.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchWorkerProfile.rejected]: (state, action) => {
			state.workerProfile.status = "error";
			state.workerProfile.errorMessage = action.payload ?? "An error occurred";
			state.workerProfile.profile = null;
			state.workerProfile.scoreItems = null;
		},
		[fetchWorkerProfile.pending]: (state) => {
			state.workerProfile.status = "pending";
			state.workerProfile.errorMessage = "";
			state.workerProfile.profile = null;
			state.workerProfile.scoreItems = null;
		},
		[fetchWorkerProfile.fulfilled]: (state: any, action) => {
			state.workerProfile.status = "fulfilled";
			state.workerProfile.errorMessage = "";
			state.workerProfile.profile = action.payload;

			state.workerProfile.profile.scoreItems = {
				// (status === 'C' && isCancelOutsidePeriod)
				cancelledAndOutside: action.payload.scoreItems.filter(
					({ status, isCancelOutsidePeriod }: any) =>
						status === "C" && isCancelOutsidePeriod
				),
				// && (status === 'C' && !isCancelOutsidePeriod)
				cancelledAndInside: action.payload.scoreItems.filter(
					({ status, isCancelOutsidePeriod }: any) =>
						status === "C" && !isCancelOutsidePeriod
				),
				// && (status === 'A' && isAbsent)
				absent: action.payload.scoreItems.filter(
					({ isAbsent }: any) => isAbsent
				),
			};
		},
		[createNewWorker.fulfilled]: (state, action) => {
			state.addWorkerDialog.isOpen = false;
			state.createNewWorker.status = "fulfilled";
		},
		[createNewWorker.pending]: (state) => {
			state.createNewWorker.status = "pending";
		},
		[createNewWorker.rejected]: (state, action) => {
			state.createNewWorker.status = "error";
			state.createNewWorker.errorMessage =
				action.payload ?? "An error occurred";
		},
		[deleteWorkers.fulfilled]: (state, action) => {
			const workerIds = action.payload;
			// console.log(workerIds);
			// const index = state.allWorkers.indexOf(workerIds[0]);

			const index = state.allWorkers.findIndex(
				(el: any) => el.id === workerIds[0]
			);
			state.allWorkers.splice(index, 1);
			// console.log(index);

			// workerIds.forEach((id) => {
			// state.allWorkers.splice(state.allWorkers.indexOf(id), 1);
			// });
			state.deleteWorkers.status = "fulfilled";
		},
		[deleteWorkers.pending]: (state) => {
			state.deleteWorkers.status = "pending";
		},
		[deleteWorkers.rejected]: (state, action) => {
			state.deleteWorkers.status = "error";
			state.deleteWorkers.errorMessage = action.payload ?? "An error occurred";
		},
		[editWorker.fulfilled]: (state: any, action) => {
			const workerId = action.payload?.workerId;
			const newWorkerData = action.payload?.data;
			const index = state.allWorkers.findIndex((el: any) => el.id === workerId);
			state.allWorkers[index] = newWorkerData;
			// state.editWorkerDialog.isOpen = false;
			state.editWorker.status = "fulfilled";
		},
		[editWorker.pending]: (state) => {
			state.editWorker.status = "pending";
		},
		[editWorker.rejected]: (state, action) => {
			state.editWorker.status = "error";
			const errorMessage = "An error occurred";
			state.editWorker.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
			state.editWorker.shifts = action.payload
				? action.payload.shifts
					? action.payload.shifts
					: []
				: [];
		},
		[getExpLevel.fulfilled]: (state, action) => {
			state.expLevel = action.payload;
			state.getExpLevel.status = "idle";
			state.getExpLevel.errorMessage = "";
		},
		[getExpLevel.pending]: (state) => {
			state.getExpLevel.status = "pending";
		},
		[getExpLevel.rejected]: (state, action) => {
			state.getExpLevel.status = "error";
			const errorMessage = "An error occurred";
			state.getExpLevel.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
			state.expLevel = [];
		},
		[addSkillInterested.fulfilled]: (state) => {
			state.addSkillInterested.status = "fulfilled";
			state.addSkillInterested.errorMessage = "";
		},
		[addSkillInterested.pending]: (state) => {
			state.addSkillInterested.status = "pending";
		},
		[addSkillInterested.rejected]: (state, action) => {
			state.addSkillInterested.status = "error";
			const errorMessage = "An error occurred";
			state.addSkillInterested.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		[rejectSkillInterested.fulfilled]: (state) => {
			state.rejectSkillInterested.status = "fulfilled";
			state.rejectSkillInterested.errorMessage = "";
		},
		[rejectSkillInterested.pending]: (state) => {
			state.rejectSkillInterested.status = "pending";
		},
		[rejectSkillInterested.rejected]: (state, action) => {
			state.rejectSkillInterested.status = "error";
			const errorMessage = "An error occurred";
			state.rejectSkillInterested.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},

		//update skill level
		[updateWorkerSkillLevel.fulfilled]: (state: any, action) => {
			const { workerId, skillId, levelId, presentLevelId, useObsoleteSkills } =
				action.payload.payload;

			const priorities = action.payload.priorities;
			const levels = action.payload.levels;
			const skills = action.payload.skills;
			const selectedSkill = skills.find((el: any) => el.id === skillId);
			const isSkillObsolete = selectedSkill.isObsolete;
			if ((useObsoleteSkills && isSkillObsolete) || !isSkillObsolete) {
				const newLevel = levels.find((el: any) => el.id === levelId) ?? {
					level: 1,
				};
				const oldLevel = levels.find((el: any) => el.id === presentLevelId) ?? {
					level: 0,
				};

				const skillPriority = priorities.find(
					(el: any) => el.id === selectedSkill.priorityId
				) ?? {
					multiplier: 1,
				};

				const index = state.allWorkers.findIndex(
					(el: any) => el.id === workerId
				);
				const temp = state.allWorkers[index];

				const scoreChange =
					skillPriority.multiplier * (newLevel.level - oldLevel.level);

				const newScore = temp.skillScore + scoreChange;

				state.allWorkers[index] = {
					...temp,
					skills: temp.skills.map((skill: any) => {
						return skill.skillId === skillId
							? { ...skill, levelId: levelId }
							: skill;
					}),
					skillScore: newScore,
				};
			}

			state.updateSkillLevel.status = "fulfilled";
			state.updateSkillLevel.errorMessage = "";
		},
		[updateWorkerSkillLevel.pending]: (state) => {
			state.updateSkillLevel.status = "pending";
		},
		[updateWorkerSkillLevel.rejected]: (state, action) => {
			state.updateSkillLevel.status = "error";
			const errorMessage = "An error occurred";
			state.updateSkillLevel.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		[fetchSkillLevels.fulfilled]: (state, action) => {
			const temp = action.payload.map((el: any) => ({
				...el,
				levelId: el.id,
			}));
			state.skillLevels = temp;
			state.fetchSkillLevels.status = "fulfilled";
			state.fetchSkillLevels.errorMessage = "";
		},
		[fetchSkillLevels.pending]: (state) => {
			state.fetchSkillLevels.status = "pending";
		},
		[fetchSkillLevels.rejected]: (state, action) => {
			state.fetchSkillLevels.status = "error";
			const errorMessage = "An error occurred";
			state.fetchSkillLevels.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		[fetchSkillMatrix.fulfilled]: (state, action) => {
			state.skillMatrixData = action.payload ?? { workers: [], totalCount: 0 };
			state.fetchSkillMatrix.status = "fulfilled";
			state.fetchSkillMatrix.errorMessage = "";
		},
		[fetchSkillMatrix.pending]: (state) => {
			state.fetchSkillMatrix.status = "pending";
		},
		[fetchSkillMatrix.rejected]: (state, action) => {
			state.fetchSkillMatrix.status = "error";
			const errorMessage = "An error occurred";
			state.fetchSkillMatrix.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		[fetchSkillMatrixFilters.fulfilled]: (state, action) => {
			const data = action.payload;
			let skills = data.skills ?? [];
			const priorities = data.skillPriorities ?? DUMMY_PRIORITIES_ARRAY;
			skills = appendPriorityToSkills(skills, priorities);

			// Setting all jobs for filter
			if (
				Object.prototype.hasOwnProperty.call(data, "jobs") &&
				Object.prototype.hasOwnProperty.call(data, "jobSkills") &&
				skills
			) {
				const groupedSkillsByPriority = skills.reduce(
					(acc: any, skill: any) => {
						if (!acc[skill.priority.id]) {
							acc[skill.priority.id] = [];
						}
						acc[skill.priority.id].push(skill);
						return acc;
					},
					{}
				);

				const allJobs = data.jobs
					? data.jobs.map((job: any) => {
							const filterJobSkills = data.jobSkills.filter(
								(jobSkill: any) => jobSkill.jobId === job.id
							);
							const filterSkillsByJobId: SkillItem[] = [];
							filterJobSkills.forEach((jobSkill: any) => {
								const item = skills.find(
									(skill: any) => skill.id === jobSkill.skillId
								);
								if (item) {
									filterSkillsByJobId.push({
										id: item.id,
										jobId: job.id,
										name: item.name,
										skillId: item.id,
										priority: item.priority,
									});
								}
							});

							const priorityMap = new Map<string, Priority>();

							filterSkillsByJobId.forEach((item) => {
								if (!priorityMap.has(String(item.priority.id))) {
									priorityMap.set(String(item.priority.id), {
										...item.priority,
										skills: [],
									});
								}
								priorityMap.get(String(item.priority.id))!.skills!.push(item);
							});

							const uniquePriorities = Array.from(priorityMap.values());

							return {
								...job,
								priorities: uniquePriorities,
								isActive: true,
								skills: [...filterSkillsByJobId],
							};
					  })
					: [];

				state.skillMatrixFiltersAllJobs = allJobs;

				state.skillMatrixFiltersPriorities = Object.keys(
					groupedSkillsByPriority
				).map((priorityId) => {
					const priority = groupedSkillsByPriority[priorityId][0].priority;
					const associatedSkills = groupedSkillsByPriority[priorityId];
					const associatedJobs = allJobs.filter((job: { priorities: any[] }) =>
						job.priorities.some((w) => w.id === priority.id)
					);

					return {
						...priority,
						skills: associatedSkills,
						jobs: associatedJobs,
					};
				});

				let allNewJobRoles = {};
				if (allJobs.length > 0) {
					allNewJobRoles = allJobs.reduce(
						(obj: any, cur: any) => ({ ...obj, [cur.id]: cur }),
						{}
					);
					state.skillMatrixFiltersJobRoles = allNewJobRoles;
				}
			}

			// Setting organization types
			if (Object.prototype.hasOwnProperty.call(data, "orgTypes")) {
				state.skillMatrixFiltersOrgTypes = data.orgTypes ?? [];
			}

			// Setting organizations
			if (Object.prototype.hasOwnProperty.call(data, "organizations")) {
				state.skillMatrixFiltersOrganizations = data.organizations ?? [];
			}

			// Setting locations
			if (Object.prototype.hasOwnProperty.call(data, "locations")) {
				state.skillMatrixFiltersLocations = data.locations ?? [];
			}

			// Skill Priorities
			if (Object.prototype.hasOwnProperty.call(data, "skillPriorities")) {
				state.skillMatrixFiltersSkillPriorities = data.skillPriorities ?? [];
			}

			// Setting managers
			if (Object.prototype.hasOwnProperty.call(data, "managers")) {
				state.skillMatrixFiltersManagers = data.managers ?? [];
			}

			// Setting work centers
			if (Object.prototype.hasOwnProperty.call(data, "workcenters")) {
				state.skillMatrixFiltersWorkCenters = data.workcenters ?? [];
			}

			// Setting worker types
			if (Object.prototype.hasOwnProperty.call(data, "workerTypes")) {
				state.skillMatrixFiltersWorkerTypes = data.workerTypes ?? [];
			}

			// Setting skills and skill levels
			if (
				Object.prototype.hasOwnProperty.call(data, "skills") &&
				Object.prototype.hasOwnProperty.call(data, "levels")
			) {
				if (
					skills &&
					skills.length > 0 &&
					data.levels &&
					data.levels.length > 0
				) {
					//forming skill level object
					const temp = skills.map((item: any) => {
						{
							return {
								...item,
								levels: data.levels,
							};
						}
					});
					state.skillMatrixFiltersSkillLevels = temp[0].levels.map(
						(el: any) => ({
							...el,
							levelId: el.id,
						})
					);
				}
				state.skillMatrixFiltersSkills = skills;
			}
			state.fetchSkillMatrixFilters.status = "fulfilled";
			state.fetchSkillMatrixFilters.errorMessage = "";
		},
		[fetchSkillMatrixFilters.pending]: (state) => {
			state.fetchSkillMatrixFilters.status = "pending";
			state.fetchSkillMatrixFilters.errorMessage = "";
		},
		[fetchSkillMatrixFilters.rejected]: (state, action) => {
			state.fetchSkillMatrixFilters.status = "error";
			const errorMessage = "An error occurred";
			state.fetchSkillMatrixFilters.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		[fetchSkillMatrixDefaultFilters.fulfilled]: (state, action) => {
			state.fetchSkillMatrixDefaultFilters.status = "fulfilled";
			state.fetchSkillMatrixDefaultFilters.errorMessage = "";
			const newSkillMatrixAppliedFilters = action.payload
				? {
						...action.payload,
						managers: action.payload.managerId
							? `${action.payload.managerId}`
							: "",
				  }
				: {};
			state.skillMatrixAppliedFilters = newSkillMatrixAppliedFilters;
		},
		[fetchSkillMatrixDefaultFilters.pending]: (state) => {
			state.fetchSkillMatrixDefaultFilters.status = "pending";
			state.fetchSkillMatrixDefaultFilters.errorMessage = "";
		},
		[fetchSkillMatrixDefaultFilters.rejected]: (state, action) => {
			state.fetchSkillMatrixDefaultFilters.status = "error";
			const errorMessage = "An error occurred";
			state.fetchSkillMatrixDefaultFilters.errorMessage = action.payload
				? action.payload.message
					? action.payload.message
					: errorMessage
				: errorMessage;
		},
		//update worker's job status
		[updateWorkerJobExpiry.fulfilled]: (state) => {
			state.updateWorkerJobExpiry.status = "fulfilled";
			state.updateWorkerJobExpiry.errorMessage = "";
		},
		[updateWorkerJobExpiry.pending]: (state) => {
			state.updateWorkerJobExpiry.status = "pending";
		},
		[updateWorkerJobExpiry.rejected]: (state: any, action) => {
			const { error, data }: any = action.payload;
			state.updateWorkerJobExpiry.status = "error";
			state.updateWorkerJobExpiry.errorMessage = error;
			// Revert job expiry if fail
			ManageWorkersSlice.caseReducers.setJobExpiry(state, {
				payload: {
					status: !data.status,
					workerId: data.workerId,
					jobId: data.jobId,
				},
			});
		},
		[getWorkerTypes.pending]: (state) => {
			state.getWorkerTypes.status = "pending";
			state.getWorkerTypes.errorMessage = "";
		},
		[getWorkerTypes.rejected]: (state, action) => {
			state.getWorkerTypes.status = "error";
			state.getWorkerTypes.errorMessage = action.payload ?? "An error occurred";
		},
		[getWorkerTypes.fulfilled]: (state, action) => {
			state.getWorkerTypes.status = "fulfilled";
			state.getWorkerTypes.errorMessage = "";
			state.workerTypes = action.payload ?? [];
		},
		[fetchUserLocations.pending]: (state) => {
			state.fetchUserLocations.status = "pending";
			state.fetchUserLocations.errorMessage = "";
		},
		[fetchUserLocations.rejected]: (state, action) => {
			state.fetchUserLocations.status = "error";
			state.fetchUserLocations.errorMessage =
				action.payload ?? "An error occurred";
		},
		[fetchUserLocations.fulfilled]: (state, action) => {
			state.fetchUserLocations.status = "fulfilled";
			state.fetchUserLocations.errorMessage = "";
			state.userLocations = action.payload ?? [];
		},
		[getLocationShiftPatterns.pending]: (state) => {
			state.getLocationShiftPatterns.status = "pending";
			state.getLocationShiftPatterns.errorMessage = "";
		},
		[getLocationShiftPatterns.rejected]: (state, action) => {
			state.getLocationShiftPatterns.status = "error";
			state.getLocationShiftPatterns.errorMessage =
				action.payload ?? "An error occurred";
		},
		[getLocationShiftPatterns.fulfilled]: (state, action) => {
			state.getLocationShiftPatterns.status = "fulfilled";
			state.getLocationShiftPatterns.errorMessage = "";
			state.locationShiftPatterns = action.payload ?? [];
		},
		//getAllShiftTypes
		[getAllShiftTypes.pending]: (state) => {
			state.getAllShiftTypes.status = "pending";
			state.getAllShiftTypes.errorMessage = "";
		},
		[getAllShiftTypes.rejected]: (state, action) => {
			state.getAllShiftTypes.status = "error";
			state.getAllShiftTypes.errorMessage =
				action.payload ?? "An error occurred";
		},
		[getAllShiftTypes.fulfilled]: (state, action) => {
			state.getAllShiftTypes.status = "fulfilled";
			state.getAllShiftTypes.errorMessage = "";
			state.getAllShiftTypes.data = action.payload ?? [];
		},
		[deleteWorkerSkills.fulfilled]: (state: any, action) => {
			state.deleteWorkerSkills.status = "fulfilled";
			state.deleteWorkerSkills.errorMessage = "";
			//  remove skill from workerprofile jobs
			const skillId = action.payload.skillId;
			const workerJobs = state.workerProfile.profile.jobs;
			const otherSkills = state.workerProfile.profile.otherSkills;
			const removedSkill = workerJobs.map((job: any) => {
				const skills = job.skills.filter(
					(skill: any) => skill.skillId !== skillId
				);
				return { ...job, skills };
			});
			const removedOtherSkills = otherSkills.filter(
				(skill: any) => skill.skillId !== skillId
			);
			state.workerProfile.profile.jobs = removedSkill;
			state.workerProfile.profile.otherSkills = removedOtherSkills;
		},
		[deleteWorkerSkills.pending]: (state) => {
			state.deleteWorkerSkills.status = "pending";
			state.deleteWorkerSkills.errorMessage = "";
		},
		[deleteWorkerSkills.rejected]: (state, action) => {
			state.deleteWorkerSkills.status = "error";
			state.deleteWorkerSkills.errorMessage =
				action.payload ?? "An error occurred";
		},

		[addWorkerSkills.fulfilled]: (state, action) => {
			state.addWorkerSkills.status = "fulfilled";
			state.addWorkerSkills.errorMessage = "";
		},
		[addWorkerSkills.pending]: (state) => {
			state.addWorkerSkills.status = "pending";
			state.addWorkerSkills.errorMessage = "";
		},
		[addWorkerSkills.rejected]: (state, action) => {
			state.addWorkerSkills.status = "error";
			state.addWorkerSkills.errorMessage =
				action.payload ?? "An error occurred";
		},
		[modifyWorkerSkill.fulfilled]: (state: any, action) => {
			state.editWorkerSkill.status = "fulfilled";
			state.editWorkerSkill.errorMessage = "";
			state.editWorkerSkillDialog.isOpen = false;
			state.editWorkerSkillDialog.skillData = null;
			const { skillId, level, levelId, validFrom, validTo, levelName } =
				action.payload;
			const jobs = state.workerProfile.profile.jobs;
			const otherSkills = state.workerProfile.profile.otherSkills;
			// updating skills mapped to job
			for (const job of jobs) {
				for (const skill of job.skills) {
					if (skill.skillId === skillId) {
						skill.level = level;
						skill.levelName = levelName;
						skill.levelId = levelId;
						skill.validFrom = validFrom;
						skill.validTo = validTo;
					}
				}
			}
			// updating unmapped skills
			for (const skill of otherSkills) {
				if (skill.skillId === skillId) {
					skill.level = level;
					skill.levelName = levelName;
					skill.levelId = levelId;
					skill.validFrom = validFrom;
					skill.validTo = validTo;
				}
			}
			state.workerProfile.profile.jobs = jobs;
			state.workerProfile.profile.otherSkills = otherSkills;
		},
		[modifyWorkerSkill.pending]: (state) => {
			state.editWorkerSkill.status = "pending";
			state.editWorkerSkill.errorMessage = "";
		},
		[modifyWorkerSkill.rejected]: (state, action) => {
			state.editWorkerSkill.status = "error";
			state.editWorkerSkill.errorMessage =
				action.payload ?? "An error occurred";
		},
		[deleteWorkerCertifications.fulfilled]: (state: any, action) => {
			state.deleteWorkerCertifications.status = "fulfilled";
			state.deleteWorkerCertifications.errorMessage = "";
			//  remove skill from workerprofile jobs
			const workerCertId = action.payload.workerCertId;

			const certifications = state.workerProfile.profile.workerCerts;
			const removedCertifications = certifications.filter(
				(cert: any) => cert.workerCertId !== workerCertId
			);
			state.workerProfile.profile.workerCerts = removedCertifications;
		},
		[deleteWorkerCertifications.pending]: (state) => {
			state.deleteWorkerCertifications.status = "pending";
			state.deleteWorkerCertifications.errorMessage = "";
		},
		[deleteWorkerCertifications.rejected]: (state, action) => {
			state.deleteWorkerCertifications.status = "error";
			state.deleteWorkerCertifications.errorMessage =
				action.payload ?? "An error occurred";
		},
		[editWorkerCertification.fulfilled]: (state: any, action) => {
			// state.editWorkerCertificationDialog.isOpen = false;
			state.editWorkerCertificationDialog.certificationData = null;
			state.editWorkerCertification.status = "fulfilled";
			const { companyCertId, validFrom, validTo } = action.payload;
			const certifications = state.workerProfile.profile.workerCerts;
			const updatedCertifications = certifications.map((cert: any) => {
				if (cert.companyCertId === companyCertId) {
					return { ...cert, validFrom, validTo };
				}
				return cert;
			});
			state.workerProfile.profile.workerCerts = updatedCertifications;
		},
		[editWorkerCertification.pending]: (state) => {
			state.editWorkerCertification.status = "pending";
			state.editWorkerCertification.errorMessage = "";
		},
		[editWorkerCertification.rejected]: (state, action) => {
			state.editWorkerCertification.status = "error";
			state.editWorkerCertification.errorMessage =
				action.payload ?? "An error occurred";
		},
		[addWorkerCertifications.fulfilled]: (state, action) => {
			state.addWorkerCertifications.status = "fulfilled";
			state.addWorkerCertifications.errorMessage = "";
		},
		[addWorkerCertifications.pending]: (state) => {
			state.addWorkerCertifications.status = "pending";
			state.addWorkerCertifications.errorMessage = "";
		},
		[addWorkerCertifications.rejected]: (state, action) => {
			state.addWorkerCertifications.status = "error";
			state.addWorkerCertifications.errorMessage =
				action.payload ?? "An error occurred";
		},
		[uploadWorkerCertification.fulfilled]: (state, action) => {
			state.postUploadFiles.successfulUploads += 1;
		},
		[uploadWorkerCertification.rejected]: (state, action) => {
			state.postUploadFiles.failedUploads += 1;
		},
		[deleteWorkerCertificationFile.fulfilled]: (state, action) => {
			state.postDeleteFiles.successfulDeletes += 1;
		},
		[deleteWorkerCertificationFile.rejected]: (state, action) => {
			state.postDeleteFiles.failedDeletes += 1;
		},
		[downloadWorkerCertification.pending]: (state: any, action) => {
			const { workerCertFileId } = action.meta.arg;
			state.downloadWorkerCertification.status = "pending";
			state.downloadWorkerCertification.errors[workerCertFileId] = undefined;
		},
		[downloadWorkerCertification.rejected]: (state: any, action) => {
			const { workerCertFileId } = action.meta.arg;
			state.downloadWorkerCertification.status = "error";
			state.downloadWorkerCertification.errors[workerCertFileId] =
				action.payload ?? "An error occurred";
		},
		[downloadWorkerCertification.fulfilled]: (state: any, action) => {
			const { workerCertFileId } = action.meta.arg;
			state.downloadWorkerCertification.status = "fulfilled";
			state.downloadWorkerCertification.errors[workerCertFileId] = undefined;
		},
		[isEmployeeIdUnique.fulfilled]: (state, action) => {
			state.isEmployeeIdUnique.status = "fulfilled";
			state.isEmployeeIdUnique.errorMessage = "";
			state.isEmployeeIdUnique.data = {
				...state.isEmployeeIdUnique.data,
				isUnique: action.payload.isUnique,
				employeeId: action.payload.employeeId,
				checked: true,
			};
		},
		[isEmployeeIdUnique.pending]: (state) => {
			state.isEmployeeIdUnique.status = "pending";
			state.isEmployeeIdUnique.errorMessage = "";
		},
		[isEmployeeIdUnique.rejected]: (state, action) => {
			state.isEmployeeIdUnique.status = "error";
			state.isEmployeeIdUnique.errorMessage =
				action.payload ?? "An error occurred";
		},
	},
});

export const {
	openAddWorkerDialog,
	openEditWorkerSkillDialog,
	closeAddWorkerDialog,
	closeEditWorkerSkillDialog,
	openEditWorkerDialog,
	changeEditWorkerStep,
	closeEditWorkerDialog,
	resetCreateNewWorker,
	resetEditWorker,
	resetDeleteWorkers,
	setWorkerDetails,
	resetWorkerDetails,
	resetAddSkillInterested,
	setJobExpiry,
	resetWorkerJobStatus,
	openWorkerProfile,
	closeWorkerProfile,
	resetSkillLevelStatus,
	resetWorkerTypes,
	resetDeleteWorkerSkills,
	resetAddWorkerSkills,
	resetEditWorkerSkill,
	resetDeleteWorkerCertifications,
	resetSkillMatrixDefaultFilters,
	resetSkillMatrixFilter,
	resetSkillMatrix,
	openEditWorkerCertificationDialog,
	closeEditWorkerCertificationDialog,
	resetAddWorkerCertifications,
	resetEditWorkerCertification,
	setUploadedFiles,
	setDeletedFiles,
	resetPostUploadFiles,
	resetPostDeleteFiles,
	setPostUploadStatus,
	setPostDeleteStatus,
	setDownloadProgress,
	resetDownloadProgress,
	setDownloadError,
	resetFetchAllOrganizations,
	resetIsEmployeeIdUnique,
	setIsEmployeeIdUnique,
} = ManageWorkersSlice.actions;

export default ManageWorkersSlice.reducer;
