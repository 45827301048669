import Button from "@gigandtake/button";
import {
  ArrowDropDown,
  ArrowRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { VariableSizeList } from "react-window";

import { JobColor } from "../../../../../export/jobs";
import Searchbar from "../../../../design/search-bar";
import { deCamelCase } from "../../../../utils/helpers";
import useViewport from "../../../../utils/useViewport";
import Highlight from "../../../Highlight";
import CrossSkillWorkcenterFilter from "./ui/cross-skill-workcenter-filter";
import GATCheckbox from "./ui/GATCheckbox";

/* This CSS style is applied when the drawer is opened */
const openedStyle = {
  opacity: 1,
};

/* This CSS style is applied when the drawer is closed */
const closedStyle = {
  opacity: 0,
};

const CrossSkillingFilter = ({
  filterControllerProps,
  filterControllerEvents,
  filterStatus,
  allSkillLevels,
  allManagers,
}) => {
  const boxRef = useRef();
  const { height, width } = useViewport();
  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);

  const {
    isSkillLevelChecked,
    isSkillChecked,
    isJobChecked,
    isLocationChecked,
    isWorkerTypeChecked,
    isSkillPriorityChecked,
    isManagerChecked,
    isWorkCenterChecked,
    isSkillExpanded,
    filterUpdateCount,
    filteredJobs,
    filteredWorkcenters,
    searchedWorkcenters,
    allWorkerTypes,
    allSkillPriorities,
    jobsExpanded,
    workcenterExpanded,
    filteredLocations,
    filteredSkills,
    filteredManagers,
    skillsExpanded,
    locationsExpanded,
    managerExpanded,
    workerTypeExpanded,
    skillPriorityExpanded,
    searchedJob,
    searchedJobs,
    searchedSkill,
    searchedSkills,
    searchedManager,
    searchedLocations,
    skillListRef,
    rowHeightsForSkill,
    getSkillItemSize,
    getJobItemSize,
    getLocationItemSize,
  } = filterControllerProps;
  const {
    resetFilters,
    applyFilter,
    expandSkill,
    setSkillPriorityExpanded,
    setLocationsExpanded,
    setJobsExpanded,
    setWorkcenterExpanded,
    setSkillsExpanded,
    setManagerExpanded,
    setWorkerTypeExpanded,
    setSearchedJob,
    setSearchedManager,
    setSearchedSkill,
    setFilterUpdateCount,
    setRowHeightForSkill,
  } = filterControllerEvents;

  const isSkillInsightsEnabled = useSelector(
    (state) => state.user.userData.skillInsightsEnabled
  );
  const isSkillPriorityEnabled = useSelector(
    (state) => state.user.userData.skillPriorityEnabled
  );

  useEffect(() => {
    // This function calculate X and Y
    const getPosition = () => {
      try {
        if (boxRef) {
          // top content and padding
          const extraSpace = isSkillInsightsEnabled ? 248 : 190;

          //const y = boxRef.current.offsetTop;
          const newFiltersHeight = height - extraSpace;
          if (newFiltersHeight >= 696) {
            setFiltersContainerHeight(height - extraSpace);
            setFilterUpdateCount((prevState) => prevState + 1);
          } else {
            setFiltersContainerHeight(696);
          }
        }
      } catch (e) {
        // Do nothing
      }
    };
    getPosition();
  }, [height, setFilterUpdateCount, isSkillInsightsEnabled]);

  const gotManagers = allManagers.length > 0;
  let removeSpace = 397;
  const noSearchBarSpace = 70;
  removeSpace -= gotManagers ? 0 : 40;

  const SkillRows = (props) => {
    const { index, style, data } = props;
    const {
      searchedSkills,
      rowHeightsForSkill,
      setRowHeightForSkill,
      allSkillLevels,
      applyFilter,
      expandSkill,
      isSkillChecked,
      isSkillExpanded,
      isSkillLevelChecked,
      searchedSkill,
    } = data;
    const skill = searchedSkills[index];

    const isFirst = index === 0;
    const isLast = index === searchedSkills.length - 1;

    const handleCheckboxChange = useCallback(
      (e) => {
        if (e.target.checked) {
          setRowHeightForSkill(
            index,
            skill.id,
            30 + allSkillLevels.length * 30
          );
        } else {
          setRowHeightForSkill(index, skill.id, 30);
        }
        applyFilter(
          "skill",
          e.target.checked ?? false,
          skill.id,
          "",
          skill.name
        );
      },
      [index, allSkillLevels.length, applyFilter, setRowHeightForSkill, skill]
    );

    const skillLevels = useMemo(() => {
      return allSkillLevels.map((level) => (
        <FormControlLabel
          key={`level-${skill.id}-${level.id}`}
          label={<span style={{ fontSize: "14px" }}>{level.name}</span>}
          control={
            <GATCheckbox
              checked={isSkillLevelChecked(skill.id, level.id)}
              level={level.level}
              repCode={level.repCode}
              value={level.value}
              backColorCode={level.backColorCode}
              foreColorCode={level.foreColorCode}
              onChange={(e) => {
                applyFilter(
                  "skill",
                  e.target.checked ?? false,
                  skill.id,
                  level.id,
                  skill.name
                );
              }}
            />
          }
        />
      ));
    }, [allSkillLevels, applyFilter, isSkillLevelChecked, skill]);

    return (
      <div
        style={{
          ...style,
          height: rowHeightsForSkill[index],
          paddingTop: isFirst ? "12px" : "0px",
          paddingBottom: isLast ? "12px" : "0px",
        }}
      >
        <Box key={`skill-level-${skill.id}`}>
          <FormControlLabel
            control={
              <GATCheckbox
                onClick={() => expandSkill(skill.id)}
                checked={isSkillChecked(skill.id)}
                level={-1}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {isSkillExpanded(skill.id) ? <ArrowDropDown /> : <ArrowRight />}
                <Tooltip title={skill.name}>
                  <span
                    style={{
                      fontSize: "14px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Highlight value={skill.name} searchText={searchedSkill} />
                  </span>
                </Tooltip>
              </div>
            }
          />
          <Box
            sx={{
              display: isSkillExpanded(skill.id) ? "flex" : "none",
              flexDirection: "column",
              ml: 4,
            }}
          >
            {skillLevels}
          </Box>
        </Box>
      </div>
    );
  };

  function LocationRows(props) {
    const { index, style, data } = props;
    const location = data[index];

    const isFirst = index === 0;
    const isLast = index === data.length - 1;

    return (
      <div
        style={{
          ...style,
          paddingTop: isFirst ? "12px" : "0px",
          paddingBottom: isLast ? "12px" : "0px",
        }}
      >
        <FormControlLabel
          key={`filter-location-${location.id}`}
          control={
            <GATCheckbox
              checked={isLocationChecked(location.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  applyFilter("location", true, location.id, "", location.name);
                } else {
                  applyFilter(
                    "location",
                    false,
                    location.id,
                    "",
                    location.name
                  );
                }
              }}
            />
          }
          label={
            <Box display="flex" alignItems="center">
              <Tooltip title={location.name}>
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Highlight
                    value={location.name}
                    searchText={searchedLocations}
                  />
                </span>
              </Tooltip>
            </Box>
          }
        />
      </div>
    );
  }

  function JobRows(props) {
    const { index, style, data } = props;
    const job = data[index];

    const isFirst = index === 0;
    const isLast = index === data.length - 1;

    return (
      <div
        style={{
          ...style,
          paddingTop: isFirst ? "12px" : "0px",
          paddingBottom: isLast ? "12px" : "0px",
        }}
      >
        <FormControlLabel
          key={`filter-job-${job.id}`}
          control={
            <GATCheckbox
              checked={isJobChecked(job.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  applyFilter("job", true, job.id, "", job.name);
                } else {
                  applyFilter("job", false, job.id);
                }
              }}
            />
          }
          label={
            <Box display="flex" alignItems="center">
              <JobColor color={`#${job.hexColor}`} />
              <Tooltip title={job.name}>
                <span
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Highlight value={job.name} searchText={searchedJob} />
                </span>
              </Tooltip>
            </Box>
          }
        />
      </div>
    );
  }

  return (
    <Box
      ref={boxRef}
      style={filterStatus ? openedStyle : closedStyle}
      sx={{
        height: filtersContainerHeight,
        overflowY: "hidden",
        "@media (max-width: 1312px)": {
          width: "100%",
          minWidth: "292px",
          borderRight: "0px",
          borderBottom: "1px #CCC solid",
        },
        "@media (min-width: 1312px)": {
          width: "100%",
          minWidth: "292px",
          maxWidth: "292px",
          borderRight: "1px  #CCC solid",
        },
      }}
    >
      <Button
        onClick={resetFilters}
        size="small"
        variant="text"
        customStyles={{
          textTransform: "none",
          marginLeft: "18px",
          marginTop: "8px",
        }}
      >
        Reset Filters
      </Button>
      <Box key={`location-filter-${filterUpdateCount}`}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          mt={1}
          px={3}
          py={1}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (locationsExpanded) {
              setLocationsExpanded(false);
            } else {
              setSkillPriorityExpanded(false);
              setLocationsExpanded(true);
              setJobsExpanded(false);
              setWorkcenterExpanded(false);
              setManagerExpanded(false);
              setSkillsExpanded(false);
              setWorkerTypeExpanded(false);
            }
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Locations ({filteredLocations.length})
          </Typography>
          {locationsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
        {locationsExpanded && (
          <Box
            sx={{
              overflowY: "auto",
              paddingLeft: "4px",
              height: filtersContainerHeight - removeSpace + noSearchBarSpace,
              boxShadow:
                "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
            }}
          >
            <FormGroup>
              <VariableSizeList
                height={filtersContainerHeight - removeSpace}
                width={"100%"}
                itemSize={(index) => getLocationItemSize(index)}
                itemCount={searchedLocations.length}
                overscanCount={5}
                itemData={searchedLocations}
              >
                {LocationRows}
              </VariableSizeList>
            </FormGroup>
          </Box>
        )}
      </Box>
      <CrossSkillWorkcenterFilter
        allWorkcenters={searchedWorkcenters}
        filtersContainerHeight={filtersContainerHeight}
        removeSpace={removeSpace}
        noSearchBarSpace={noSearchBarSpace}
        applyFilter={applyFilter}
        isWorkCenterChecked={isWorkCenterChecked}
        workcenterExpanded={workcenterExpanded}
        setWorkcenterExpanded={(workCenterExpanded) => {
          setWorkcenterExpanded(workCenterExpanded);
          if (workCenterExpanded) {
            setSkillPriorityExpanded(false);
            setLocationsExpanded(false);
            setWorkerTypeExpanded(false);
            setManagerExpanded(false);
            setSkillsExpanded(false);
            setJobsExpanded(false);
          }
        }}
      />
      <Box key={`worker-type-filter-${filterUpdateCount}`}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={1}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (workerTypeExpanded) {
              setWorkerTypeExpanded(false);
            } else {
              setSkillPriorityExpanded(false);
              setLocationsExpanded(false);
              setWorkerTypeExpanded(true);
              setWorkcenterExpanded(false);
              setManagerExpanded(false);
              setSkillsExpanded(false);
              setJobsExpanded(false);
            }
            // resetFilters();
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Worker Type ({allWorkerTypes.length})
          </Typography>
          {workerTypeExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
        {workerTypeExpanded && (
          <Box
            sx={{
              overflowY: "auto",
              paddingTop: "12px",
              paddingBottom: "12px",
              paddingLeft: "4px",
              height: filtersContainerHeight - removeSpace + noSearchBarSpace,
              boxShadow:
                "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
            }}
          >
            <FormGroup>
              <>
                {allWorkerTypes.map((workerType) => (
                  <FormControlLabel
                    key={`worker-type-${workerType.id}`}
                    control={
                      <GATCheckbox
                        checked={isWorkerTypeChecked(workerType.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            applyFilter(
                              "workerType",
                              true,
                              workerType.id,
                              "",
                              workerType.text
                            );
                          } else {
                            applyFilter("workerType", false, workerType.id);
                          }
                        }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                          {workerType.text ?? deCamelCase(workerType.name)}
                        </span>
                      </Box>
                    }
                  />
                ))}
              </>
            </FormGroup>
          </Box>
        )}
      </Box>
      <Box key={`job-filter-${filterUpdateCount}`}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={1}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (jobsExpanded) {
              setJobsExpanded(false);
            } else {
              setSkillPriorityExpanded(false);
              setLocationsExpanded(false);
              setJobsExpanded(true);
              setWorkcenterExpanded(false);
              setManagerExpanded(false);
              setSkillsExpanded(false);
              setWorkerTypeExpanded(false);
            }
            // resetFilters();
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Job Roles ({filteredJobs.length})
          </Typography>
          {jobsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>

        {jobsExpanded && (
          <Searchbar
            placeholder="Search Jobs"
            value={searchedJob}
            setSearch={(value) => setSearchedJob(value)}
            width={"30ch"}
            key="job-search"
            id="outlined-job-search-small"
            hiddenLabel
            label={false}
          />
        )}
        {jobsExpanded && (
          <Box
            sx={{
              overflowY: "auto",
              paddingLeft: "4px",
              height: filtersContainerHeight - removeSpace,
              boxShadow:
                "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
            }}
          >
            <FormGroup>
              <VariableSizeList
                height={filtersContainerHeight - removeSpace}
                width={"100%"}
                itemSize={(index) => getJobItemSize(index)}
                itemCount={searchedJobs.length}
                overscanCount={5}
                itemData={searchedJobs}
              >
                {JobRows}
              </VariableSizeList>
            </FormGroup>
          </Box>
        )}
      </Box>
      {isSkillPriorityEnabled && (
          <Box key={`skill-priority-filter-${filterUpdateCount}`}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                px={3}
                py={1}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (skillPriorityExpanded) {
                    setSkillPriorityExpanded(false);
                  } else {
                    setSkillPriorityExpanded(true);
                    setLocationsExpanded(false);
                    setJobsExpanded(false);
                    setWorkcenterExpanded(false);
                    setManagerExpanded(false);
                    setSkillsExpanded(false);
                    setWorkerTypeExpanded(false);
                  }
                  // resetFilters();
                }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                Skill Priorities ({allSkillPriorities.length})
              </Typography>
              {skillPriorityExpanded ? <ExpandLess /> : <ExpandMore />}
            </Box>
            {skillPriorityExpanded && (
                <Box
                    sx={{
                      overflowY: "auto",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "4px",
                      height: filtersContainerHeight - removeSpace + noSearchBarSpace,
                      boxShadow:
                          "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
                    }}
                >
                  <FormGroup>
                    <>
                      {allSkillPriorities.map((skillPriority) => (
                          <FormControlLabel
                              key={`worker-type-${skillPriority.id}`}
                              control={
                                <GATCheckbox
                                    checked={isSkillPriorityChecked(skillPriority.id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        applyFilter(
                                            "skillPriority",
                                            true,
                                            skillPriority.id,
                                            "",
                                            `${skillPriority.code} (${skillPriority.name})`
                                        );
                                      } else {
                                        applyFilter(
                                            "skillPriority",
                                            false,
                                            skillPriority.id
                                        );
                                      }
                                    }}
                                />
                              }
                              label={
                                <Box display="flex" alignItems="center">
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                          {`${skillPriority.code} (${skillPriority.name})`}
                        </span>
                                </Box>
                              }
                          />
                      ))}
                    </>
                  </FormGroup>
                </Box>
            )}
          </Box>
      )}
      <Box key={`skill-filter-${filterUpdateCount}`}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={1}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            if (skillsExpanded) {
              setSkillsExpanded(false);
            } else {
              setSkillPriorityExpanded(false);
              setLocationsExpanded(false);
              setSkillsExpanded(true);
              setWorkcenterExpanded(false);
              setManagerExpanded(false);
              setJobsExpanded(false);
              setWorkerTypeExpanded(false);
            }
            // resetFilters();
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            Skills ({filteredSkills.length})
          </Typography>
          {skillsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>

        {skillsExpanded && (
          <Searchbar
            placeholder="Search Skills"
            value={searchedSkill}
            setSearch={(value) => setSearchedSkill(value)}
            width={"30ch"}
            key="skill-search"
            id="outlined-skill-search-small"
            hiddenLabel
            label={false}
          />
        )}
        {skillsExpanded && (
          <Box
            sx={{
              overflowY: "auto",
              height: filtersContainerHeight - removeSpace,
              paddingLeft: "4px",
              borderRadius: gotManagers
                ? "0px"
                : width >= 1312
                ? "0px 0px 0px 15px"
                : "0px",
              boxShadow:
                "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
            }}
          >
            <FormGroup>
              <VariableSizeList
                ref={skillListRef}
                height={filtersContainerHeight - removeSpace}
                width={"100%"}
                itemSize={(index) => {
                  try {
                    const skill = searchedSkills[index];
                    return getSkillItemSize(index, skill.id);
                  } catch (e) {
                    return 30;
                  }
                }}
                itemCount={searchedSkills.length}
                overscanCount={5}
                itemData={{
                  searchedSkills,
                  rowHeightsForSkill,
                  setRowHeightForSkill,
                  allSkillLevels,
                  applyFilter,
                  expandSkill,
                  isSkillChecked,
                  isSkillExpanded,
                  isSkillLevelChecked,
                  searchedSkill,
                }}
              >
                {SkillRows}
              </VariableSizeList>
            </FormGroup>
          </Box>
        )}
      </Box>
      {gotManagers && (
        <Box key={`manager-filter-${filterUpdateCount}`}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            px={3}
            py={1}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (managerExpanded) {
                setManagerExpanded(false);
              } else {
                setSkillPriorityExpanded(false);
                setManagerExpanded(true);
                setLocationsExpanded(false);
                setWorkcenterExpanded(false);
                setSkillsExpanded(false);
                setJobsExpanded(false);
                setWorkerTypeExpanded(false);
              }
              // resetFilters();
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              Managers ({filteredManagers.length})
            </Typography>
            {managerExpanded ? <ExpandLess /> : <ExpandMore />}
          </Box>
          {managerExpanded && (
            <Searchbar
              placeholder="Search Manager"
              value={searchedManager}
              setSearch={(value) => setSearchedManager(value)}
              width={"30ch"}
              key="manager-search"
              id="outlined-manager-search-small"
              hiddenLabel
              label={false}
            />
          )}
          {managerExpanded && (
            <Box
              sx={{
                overflowY: "auto",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "4px",
                height: filtersContainerHeight - removeSpace,
                borderRadius: width >= 1312 ? "0px 0px 0px 15px" : "0px",
                boxShadow:
                  "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
              }}
            >
              <FormGroup>
                <>
                  {filteredManagers.map((manager) => (
                    <FormControlLabel
                      key={`filter-manager-${manager.id}`}
                      control={
                        <GATCheckbox
                          checked={isManagerChecked(manager.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              applyFilter(
                                "manager",
                                true,
                                manager.id,
                                "",
                                manager.name
                              );
                            } else {
                              applyFilter("manager", false, manager.id);
                            }
                          }}
                        />
                      }
                      label={
                        <Box display="flex" alignItems="center">
                          <span
                            style={{ fontSize: "14px", marginLeft: "10px" }}
                          >
                            <Highlight
                              searchText={searchedManager}
                              value={manager.name}
                            />
                          </span>
                        </Box>
                      }
                    />
                  ))}
                </>
              </FormGroup>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CrossSkillingFilter;
