import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

interface ISectionActionsProps {
	oldData?: any
	newData: any
	resetChanges?: any
	handleSave: any
	status?: any
	loading?: any
}
const SectionActions: React.FC<ISectionActionsProps> = ({
	oldData,
	newData,
	resetChanges,
	handleSave,
	status,
	loading,
}) => {

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				paddingTop: "16px",
				paddingBottom: "12px",
			}}
		>
			<Button
				variant='outlined'
				disabled={JSON.stringify(oldData) === JSON.stringify(newData)}
				onClick={resetChanges}
			>
				Discard all new changes
			</Button>
			<LoadingButton
				variant='contained'
				disabled={
					JSON.stringify(oldData) === JSON.stringify(newData) || loading
				}
				sx={{ marginLeft: "16px" }}
				loading={status === "pending" || loading}
				onClick={handleSave}
				loadingIndicator={
					<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
				}
			>
				Save
			</LoadingButton>
		</Box>
	);
};

export default SectionActions;
