import {Box, Typography} from "@mui/material";
import React from "react";

function AbbreviationSkillRepresentation({ text, foregroundColor = "rgba(0,0,0,0.87)", inSelect = false }) {
    return <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }}>
        {inSelect ? (
            <Box sx={{
                width: text.length > 1 ? 16 : 8,
            }}>
                <Typography sx={{
                    fontFamily: "Roboto",
                    color: foregroundColor ? `#${foregroundColor} !important` : "rgba(0,0,0,0.87)",
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "uppercase",
                }}>
                    {text}
                </Typography>
            </Box>
        ) : (
            <Box sx={{
                display: "flex",
                width: 20,
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Typography sx={{
                    fontFamily: "Roboto",
                    color: foregroundColor ? `#${foregroundColor} !important` : "rgba(0,0,0,0.87)",
                    fontWeight: 400,
                    fontSize: "14px",
                    textTransform: "uppercase",
                }}>
                    {text}
                </Typography>
            </Box>
        )}
    </Box>;
}

export default AbbreviationSkillRepresentation;
