// TODO - Type 'any' needs to be fixed.
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid-pro-6";
import React from "react";
import { useDispatch } from "react-redux";

import { markAttendance } from "../../../../../../../export/scheduler";

type StatusKey = "p" | "a" | "t" | "e" | "x";
const useMarkAttendanceColumns = ({
  setOpen,
  setWorker,
}: {
  setOpen: (value: boolean) => void;
  setWorker: (value: any) => void;
}) => {
  const dispatch = useDispatch();

  const statusObj: Record<
    StatusKey,
    { title: string; color: string; icon: JSX.Element }
  > = {
    p: {
      title: "Present",
      color: "#017374",
      icon: <CheckCircleOutlinedIcon color="success" />,
    },
    a: {
      title: "No Show",
      color: "#b00020",
      icon: <DoDisturbOutlinedIcon color="error" />,
    },
    t: {
      title: "Tardy",
      color: "#01a299",
      icon: <AccessTimeOutlinedIcon color="warning" />,
    },
    e: {
      title: "Excused Absence",
      color: "#7d5260",
      icon: <PauseCircleOutlinedIcon color="info" />,
    },
    x: {
      title: "Excused Tardy",
      color: "#7d5260",
      icon: <PauseCircleOutlinedIcon color="info" />,
    },
  };

  const actionItems = [
    { title: "Present", status: "p", color: "#017374" },
    { title: "No Show", status: "a", color: "#b00020" },
    { title: "Tardy", status: "t", color: "#01a299" },
    { title: "Excused Absence", status: "e", color: "#7d5260" },
    { title: "Excused Tardy", status: "x", color: "#7d5260" },
  ];
  const columns: GridColDef[] = [
    {
      field: "workerName",
      headerName: "Worker",
      width: 150,
      renderHeader: () => (
        <span style={{ padding: "0px 12x", fontWeight: "bold" }}>
          {"Worker"}
        </span>
      ),
      renderCell: (params) => (
        <Tooltip title={params?.row?.workerName} placement="bottom-start">
          <Typography
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.workerName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      valueGetter: (params) => statusObj[params.row.status as StatusKey]?.title,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Status"}
        </span>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" p={"0 12px"}>
            {statusObj[params.row.status as StatusKey]?.icon}
            <Typography
              align="center"
              variant="subtitle2"
              ml={1}
              flexWrap="wrap"
            >
              {statusObj[params.row.status as StatusKey]?.title}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      flex: 1,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Comment"}
        </span>
      ),

      renderCell: (params) => (
        <Tooltip title={params?.row?.comment} placement="bottom-start">
          <Typography
            variant="body2"
            sx={{
              padding: "0 12px",
              textOverflow: "ellipsis",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params?.row?.comment}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <span style={{ padding: "0px 12px", fontWeight: "bold" }}>
          {"Actions"}
        </span>
      ),
      type: "actions",
      getActions: (params) => {
        const temp = actionItems.filter(
          (item) => item.status !== params.row.status
        );
        return [
          ...temp.map((item) => (
            <GridActionsCellItem
              key={item.title}
              icon={statusObj[item.status as StatusKey].icon}
              onClick={() => {
                dispatch(
                  markAttendance({
                    workerId: params.row.id,
                    status: item.status,
                    shiftId: params.row.shiftId,
                    comment: params.row.comment ?? "",
                  })
                );
              }}
              label={item.title}
              showInMenu
              // if not undefined then ts is throwing error
              onResize={undefined}
              onResizeCapture={undefined}
            />
          )),
          <GridActionsCellItem
            key="comment"
            icon={<ModeCommentOutlinedIcon />}
            onClick={() => {
              setWorker(params.row);
              setOpen(true);
            }}
            label="Comment"
            showInMenu
            // if not undefined then ts is throwing error
            onResize={undefined}
            onResizeCapture={undefined}
          />,
        ];
      },
    },
  ];

  return columns;
};

export default useMarkAttendanceColumns;
