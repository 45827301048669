import { Box, Chip, Divider, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import React from "react";

import colors from "../../../../config/colors";

const CrossSkillAppliedFilters = ({
	filters,
	filterStatus,
	resetFilters,
}) => {
	const [appliedFilters, setAppliedFilters] = useState([]);

	const getTooltipTextForAppliedFilter = (filterArray = [], type = "") => {
		let returnValue = null;
		if (filterArray.length > 0) {
			return (
				<>
					{filterArray.map((filter, index) => (
						<Typography
							key={`tooltip-${type}-${index}`}
							sx={{
								fontFamily: "Roboto",
								fontSize: "12px",
								fontWeight: 500,
								color: "#FFFFFF",
							}}
							display='block'
						>
							{filter}
						</Typography>
					))}
				</>
			);
		}
		return returnValue;
	};

	useEffect(() => {
		try {
			if (filters.length > 0) {
				const getNameById = (id, sourceArray) => {
					let returnValue = "";
					for (let i = 0; i < sourceArray.length; i++) {
						if (sourceArray[i].id === id) {
							returnValue = sourceArray[i].name;
							break;
						}
					}
					return returnValue;
				};

				let newAppliedFilters = [];
				// Worker Type filters
				let workerTypeFilterIndex = -1;
				let jobsFilterIndex = -1;
				let skillsFilterIndex = -1;
				let managerFilterIndex = -1;
				let workCenterFilterIndex = -1;
				let locationFilterIndex = -1;
				let skillPriorityFilterIndex = -1;

				filters.forEach((filter, index) => {
					if (Object.prototype.hasOwnProperty.call(filter, "type")) {
						switch (filter.type) {
							case "workerType":
								workerTypeFilterIndex = index;
								break;
							case "job":
								jobsFilterIndex = index;
								break;
							case "skill":
								skillsFilterIndex = index;
								break;
							case "manager":
								managerFilterIndex = index;
								break;
							case "workCenter":
								workCenterFilterIndex = index;
								break;
							case "location":
								locationFilterIndex = index;
								break;
							case "skillPriority":
								skillPriorityFilterIndex = index;
								break;
							default:
								break;
						}
					}
				});

				// Add worker type filters
				if (workerTypeFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[workerTypeFilterIndex],
							"workerTypes"
						)
					) {
						filters[workerTypeFilterIndex].workerTypes.forEach((workerType) => {
							if (Object.prototype.hasOwnProperty.call(workerType, "id")) {
								const workerTypeIndex = newAppliedFilters.findIndex(
									(obj) => obj.type === "workerType"
								);
								if (workerTypeIndex > -1) {
									if (newAppliedFilters[workerTypeIndex].more === 0) {
										// When just one worker type is selected
										newAppliedFilters[workerTypeIndex].label = `${
											newAppliedFilters[workerTypeIndex].label
										}, ${workerType.text ?? "-"}`;
									} else {
										if (newAppliedFilters[workerTypeIndex].more === 1) {
											// When more than two get selected
											newAppliedFilters[workerTypeIndex].label =
												"Worker Type : ";
											const firstTwoSelectedWorkerTypes = filters[
												workerTypeFilterIndex
											].workerTypes.slice(0, 2);
											firstTwoSelectedWorkerTypes.forEach(
												(firstTwoWorkerType) => {
													newAppliedFilters[workerTypeIndex].tooltipArray.push(
														`${
															firstTwoWorkerType.text ??
															"-"
														}`
													);
												}
											);
										}
										newAppliedFilters[workerTypeIndex].tooltipArray.push(
											`${workerType.text ?? "-"}`
										);
									}
									newAppliedFilters[workerTypeIndex].more =
										newAppliedFilters[workerTypeIndex].more + 1;
								} else {
									newAppliedFilters.push({
										label: `Worker Type : ${
											workerType.text ?? "-"
										}`,
										type: "workerType",
										id: workerType.id,
										tooltipArray: [],
										more: 0,
										timeAdded: workerType.timeAdded,
									});
								}
							}
						});
					}
				}

				// Add skill priority filters
				if (skillPriorityFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[skillPriorityFilterIndex],
							"skillPrioritys"
						)
					) {
						filters[skillPriorityFilterIndex].skillPrioritys.forEach((skillPriority) => {
							if (Object.prototype.hasOwnProperty.call(skillPriority, "id")) {
								const skillPriorityIndex = newAppliedFilters.findIndex(
									(obj) => obj.type === "skillPriority"
								);
								if (skillPriorityIndex > -1) {
									if (newAppliedFilters[skillPriorityIndex].more === 0) {
										// When just one worker type is selected
										newAppliedFilters[skillPriorityIndex].label = `${
											newAppliedFilters[skillPriorityIndex].label
										}, ${skillPriority.text ?? "-"}`;
									} else {
										if (newAppliedFilters[skillPriorityIndex].more === 1) {
											// When more than two get selected
											newAppliedFilters[skillPriorityIndex].label =
												"Skill Priority : ";
											const firstTwoSelectedSkillPriorities = filters[
												skillPriorityFilterIndex
												].skillPrioritys.slice(0, 2);
											firstTwoSelectedSkillPriorities.forEach(
												(firstTwoSkillPriority) => {
													newAppliedFilters[skillPriorityIndex].tooltipArray.push(
														`${
															firstTwoSkillPriority.text ??
															"-"
														}`
													);
												}
											);
										}
										newAppliedFilters[skillPriorityIndex].tooltipArray.push(
											`${skillPriority.text ?? "-"}`
										);
									}
									newAppliedFilters[skillPriorityIndex].more =
										newAppliedFilters[skillPriorityIndex].more + 1;
								} else {
									newAppliedFilters.push({
										label: `Skill Priority : ${
											skillPriority.text ?? "-"
										}`,
										type: "skillPriority",
										id: skillPriority.id,
										tooltipArray: [],
										more: 0,
										timeAdded: skillPriority.timeAdded,
									});
								}
							}
						});
					}
				}

				// Add jobs filters
				if (jobsFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[jobsFilterIndex],
							"jobs"
						)
					) {
						filters[jobsFilterIndex].jobs.forEach((job) => {
							const jobIndex = newAppliedFilters.findIndex(
								(obj) => obj.type === "job"
							);
							if (jobIndex > -1) {
								if (newAppliedFilters[jobIndex].more === 0) {
									// When just one job is selected
									newAppliedFilters[jobIndex].label = `${
										newAppliedFilters[jobIndex].label
									}, ${job.name}`;
								} else {
									if (newAppliedFilters[jobIndex].more === 1) {
										// When more than two get selected
										newAppliedFilters[jobIndex].label = "Job : ";
										const firstTwoSelectedJobs = filters[
											jobsFilterIndex
											].jobs.slice(0, 2);
										firstTwoSelectedJobs.forEach((firstTwoJob) => {
											newAppliedFilters[jobIndex].tooltipArray.push(
												`${firstTwoJob.name}`
											);
										});
									}
									newAppliedFilters[jobIndex].tooltipArray.push(
										`${job.name}`
									);
								}
								newAppliedFilters[jobIndex].more =
									newAppliedFilters[jobIndex].more + 1;
							} else {
								newAppliedFilters.push({
									label: `Job : ${job.name}`,
									type: "job",
									id: job.id,
									tooltipArray: [],
									more: 0,
									timeAdded: job.timeAdded,
								});
							}
						});
					}
				}

				// Add skills filters
				if (skillsFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[skillsFilterIndex],
							"skills"
						)
					) {
						filters[skillsFilterIndex].skills.forEach((skill) => {
							const skillIndex = newAppliedFilters.findIndex(
								(obj) => obj.type === "skill"
							);
							if (skillIndex > -1) {
								if (newAppliedFilters[skillIndex].more === 0) {
									// When just one skill is selected
									newAppliedFilters[skillIndex].label = `${
										newAppliedFilters[skillIndex].label
									}, ${skill.name}`;
								} else {
									if (newAppliedFilters[skillIndex].more === 1) {
										// When more than two get selected
										newAppliedFilters[skillIndex].label = "Skill : ";
										const firstTwoSelectedSkills = filters[
											skillsFilterIndex
										].skills.slice(0, 2);
										firstTwoSelectedSkills.forEach((firstTwoSkill) => {
											newAppliedFilters[skillIndex].tooltipArray.push(
												`${firstTwoSkill.name}`
											);
										});
									}
									newAppliedFilters[skillIndex].tooltipArray.push(
										`${skill.name}`
									);
								}
								newAppliedFilters[skillIndex].more =
									newAppliedFilters[skillIndex].more + 1;
							} else {
								newAppliedFilters.push({
									label: `Skill : ${skill.name}`,
									type: "skill",
									id: skill.id,
									tooltipArray: [],
									more: 0,
									timeAdded: skill.timeAdded,
								});
							}
						});
					}
				}

				// Add manager filters
				if (managerFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[managerFilterIndex],
							"managers"
						)
					) {
						filters[managerFilterIndex].managers.forEach((manager) => {
							const managerIndex = newAppliedFilters.findIndex(
								(obj) => obj.type === "manager"
							);
							if (managerIndex > -1) {
								if (newAppliedFilters[managerIndex].more === 0) {
									// When just one org is selected
									newAppliedFilters[managerIndex].label = `${
										newAppliedFilters[managerIndex].label
									}, ${manager.name}`;
								} else {
									if (newAppliedFilters[managerIndex].more === 1) {
										// When more than two get selected
										newAppliedFilters[managerIndex].label = "Manager : ";
										const firstTwoSelectedManagers = filters[
											managerFilterIndex
											].managers.slice(0, 2);
										firstTwoSelectedManagers.forEach((firstTwoManager) => {
											newAppliedFilters[managerIndex].tooltipArray.push(
												`${firstTwoManager.name}`
											);
										});
									}
									newAppliedFilters[managerIndex].tooltipArray.push(
										`${manager.name}`
									);
								}
								newAppliedFilters[managerIndex].more =
									newAppliedFilters[managerIndex].more + 1;
							} else {
								newAppliedFilters.push({
									label: `Manager : ${manager.name}`,
									type: "manager",
									id: manager.id,
									tooltipArray: [],
									more: 0,
									timeAdded: manager.timeAdded,
								});
							}
						});
					}
				}

				// Work center filter
				if (workCenterFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[workCenterFilterIndex],
							"workCenters"
						)
					) {
						filters[workCenterFilterIndex].workCenters.forEach((workCenter) => {
							const workCenterIndex = newAppliedFilters.findIndex(
								(obj) => obj.type === "workCenter"
							);
							if (workCenterIndex > -1) {
								if (newAppliedFilters[workCenterIndex].more === 0) {
									// When just one org is selected
									newAppliedFilters[
										workCenterIndex
									].label = `${newAppliedFilters[workCenterIndex].label}, ${workCenter.name}`;
								} else {
									if (newAppliedFilters[workCenterIndex].more === 1) {
										// When more than two get selected
										newAppliedFilters[workCenterIndex].label = "Work Center : ";
										const firstTwoSelectedWorkCenters = filters[
											workCenterFilterIndex
										].workCenters.slice(0, 2);
										firstTwoSelectedWorkCenters.forEach((firstTwoWorkCenter) => {
											newAppliedFilters[workCenterIndex].tooltipArray.push(
												`${firstTwoWorkCenter.name}`
											);
										});
									}
									newAppliedFilters[workCenterIndex].tooltipArray.push(
										`${workCenter.name}`
									);
								}
								newAppliedFilters[workCenterIndex].more =
									newAppliedFilters[workCenterIndex].more + 1;
							} else {
								newAppliedFilters.push({
									label: `Work Center : ${workCenter.name}`,
									type: "workCenter",
									id: workCenter.id,
									tooltipArray: [],
									more: 0,
									timeAdded: workCenter.timeAdded,
								});
							}
						});
					}
				}

				// Location filter
				if (locationFilterIndex > -1) {
					if (
						Object.prototype.hasOwnProperty.call(
							filters[locationFilterIndex],
							"locations"
						)
					) {
						filters[locationFilterIndex].locations.forEach((location) => {
							const locationIndex = newAppliedFilters.findIndex(
								(obj) => obj.type === "location"
							);
							if (locationIndex > -1) {
								if (newAppliedFilters[locationIndex].more === 0) {
									// When just one org is selected
									newAppliedFilters[
										locationIndex
										].label = `${newAppliedFilters[locationIndex].label}, ${location.name}`;
								} else {
									if (newAppliedFilters[locationIndex].more === 1) {
										// When more than two get selected
										newAppliedFilters[locationIndex].label = "Location : ";
										const firstTwoSelectedLocations = filters[
											locationFilterIndex
											].locations.slice(0, 2);
										firstTwoSelectedLocations.forEach((firstTwoLocation) => {
											newAppliedFilters[locationIndex].tooltipArray.push(
												`${firstTwoLocation.name}`
											);
										});
									}
									newAppliedFilters[locationIndex].tooltipArray.push(
										`${location.name}`
									);
								}
								newAppliedFilters[locationIndex].more =
									newAppliedFilters[locationIndex].more + 1;
							} else {
								newAppliedFilters.push({
									label: `Location : ${location.name}`,
									type: "location",
									id: location.id,
									tooltipArray: [],
									more: 0,
									timeAdded: location.timeAdded,
								});
							}
						});
					}
				}

				newAppliedFilters = orderBy(newAppliedFilters, ["timeAdded"], ["asc"]);
				setAppliedFilters(newAppliedFilters);
			}
		} catch (e) {
			// Do nothing
		}
	}, [filters]);

	return (
		<Box
			sx={{
				"@media (max-width: 1312px)": {
					width: "calc(100vw - 112px)",
				},
				"@media (min-width: 1312px)": {
					width: filterStatus ? "calc(100vw - 408px)" : "calc(100vw - 112px)",
				},
				overflowX: "scroll",
			}}
		>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='flex-start'
				flexWrap='no-wrap'
				sx={{
					minHeight: "50px",
					padding: "8px 16px",
					height: 57,
				}}
			>
				{appliedFilters.map((filter, index) => (
					<Chip
						key={`applied-filter-${index}`}
						style={{ margin: 4 }}
						onDelete={() => {
							resetFilters([filter.type]);
						}}
						label={
							<Tooltip
								title={getTooltipTextForAppliedFilter(
									filter.tooltipArray,
									filter.type
								)}
								placement='bottom'
								arrow
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "nowrap",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontSize: "13px",
											color: colors.title,
											fontWeight: 400,
										}}
									>
										{filter.label}
									</Typography>
									{filter.more > 1 && (
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontSize: "15px",
												color: colors.title,
												fontWeight: 500,
												marginLeft: "4px",
											}}
										>
											{`${parseInt(filter.more + 1)}`}
										</Typography>
									)}
								</Box>
							</Tooltip>
						}
					/>
				))}
				{appliedFilters.length === 0 && (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							color: colors.gray,
							marginLeft: "8px",
						}}
					>
						No filters applied
					</Typography>
				)}
			</Box>
			<Divider />
		</Box>
	);
};

export default CrossSkillAppliedFilters;
