import { Add } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridValidRowModel } from "@mui/x-data-grid-pro-6";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../../../controllers/use-gat-admin-config-data";
// import { getLeaveTypes } from "../../../../../../store/gat-admin-slice";
import useGatAttendanceColumns from "../../controllers/use-gat-attendance-columns";

const AttendanceManagementTable = ({ handleOpenEdit, handleOpenAdd }: any) => {
	const { columns } = useGatAttendanceColumns(handleOpenEdit);
	const { getLeaveTypesData, getLeaveTypesStatus } = useGatAdminConfigData();

	return (
		<Box
			sx={{
				border: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
				borderRadius: "8px",
				backgroundColor: "#fff",
				padding: "20px",
				marginTop: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					paddingTop: "20px",
					paddingBottom: "20px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						lineHeight: "24px",
						color: "#000000DE",
					}}
				>
					Time Off Types
				</Typography>
				<Button
					disableElevation
					sx={{ height: 36 }}
					variant='outlined'
					color='primary'
					onClick={handleOpenAdd}
					startIcon={<Add />}
				>
					Time Off Type
				</Button>
			</Box>
			<DataGridPro
				columns={columns}
				rows={getLeaveTypesData}
				//disableRowSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnMenu
				//disableColumnPinning
				//disableColumnReorder
				//disableColumnResize
				pagination
				initialState={{
					...getLeaveTypesData,
					pagination: { paginationModel: { pageSize: 10 } },
					filter: {},
				}}
				pageSizeOptions={[10, 25, 50]}
				autoHeight
				getRowHeight={() => "auto"}
				loading={getLeaveTypesStatus === "pending"}
				sx={{
					border: "none !important",
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 24,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
							backgroundColor: "#e6e8ed",
						},
					},
					".MuiDataGrid-cell:focus-within": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus-within": {
						outline: "none",
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#e6e8ed",
					},
				}}
			/>
		</Box>
	);
};

export default AttendanceManagementTable;
