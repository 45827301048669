import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import { Box, Stack } from "@mui/material";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useTableHeight from "../../../../../utils/use-table-height";
import {
	closeUserDeactivateDialog,
	openUserDeactivateDialog,
	resetUserStatus,
} from "../../../store/gat-admin-slice";
import ConfirmDialog from "./company-user-dialog/confirm-dialog";
import useSupervisorColumns from "./controllers/use-supervisor-columns";
import useSupervisorsData from "./controllers/use-supervisors-data";

interface SupervisorTableProps {
	setCompanyUserData: (value: any) => void;
	setWorkCenterData: (value: any) => void;
	searchText: string;
}

const SupervisorTable: React.FC<SupervisorTableProps> = ({
	setCompanyUserData,
	setWorkCenterData,
	searchText,
}) => {
	const dispatch = useDispatch();
	const columns: any = useSupervisorColumns(searchText);
	const {
		companyUsers,
		getCompanyUsersStatus,
		getCompanyUsersErrorMessage,
		isUserDeactivateDialogOpen,
		userDeactivateData,
		changeUserStatusErrorMessage,
		updateUserStatus,
	} = useSupervisorsData();

	const [companyUsersArray, setCompanyUsersArray] = useState([]);
	const { height, boxRef } = useTableHeight();

	useEffect(() => {
		try {
			// Injecting setLocationData into data
			const newCompanyUsers: any = [];
			if (companyUsers.length > 0) {
				companyUsers.forEach((user: any) => {
					newCompanyUsers.push({
						...user,
						changeCompanyUserData: (value: any) => setCompanyUserData(value),
						changeWorkCenterData: (value: any) => setWorkCenterData(value),
					});
				});
				if (searchText === "") {
					setCompanyUsersArray(newCompanyUsers);
				} else {
					const filteredUsers = newCompanyUsers.filter((user: any) => {
						const fullName = `${user.firstName} ${user.lastName}`;
						const email = user.email
							.toLowerCase()
							.includes(searchText.toLowerCase());
						const workcentersAccess = user.userWorkCenters.some(
							(workcenter: any) =>
								workcenter.name.toLowerCase().includes(searchText.toLowerCase())
						);

						const wcLength = user.userWorkCenters.length
							.toString()
							.includes(searchText.toLowerCase());

						const userRoles = user.roles.some((role: any) =>
							role.name.toLowerCase().includes(searchText.toLowerCase())
						);
						const status = user.isActive
							? "active".includes(searchText.toLowerCase())
							: "inactive".includes(searchText.toLowerCase());
						return (
							fullName.toLowerCase().includes(searchText.toLowerCase()) ||
							email ||
							workcentersAccess ||
							status ||
							userRoles ||
							wcLength
						);
					});
					setCompanyUsersArray(filteredUsers);
				}
			} else {
				setCompanyUsersArray([]);
			}
		} catch (e) {
			// Do nothing
		}
	}, [companyUsers, searchText, setCompanyUserData, setWorkCenterData]);

	const [pageSize, setPageSize] = useState(5);
	return (
		<Box
			ref={boxRef}
			style={{
				overflow: "hidden",
				height: "calc(100vh - 335px)",
				width: "100%",
				minHeight: "200px",
			}}
		>
			<DataGridPro
				columns={columns}
				rows={companyUsersArray}
				getRowHeight={() => "auto"}
				loading={getCompanyUsersStatus === "pending"}
				pagination
				disableRowSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				disableColumnMenu
				initialState={{
					// ...companyUsersArray.initialState,
					pagination: { paginationModel: { pageSize: 10 } },
				}}
				pageSizeOptions={[10, 25, 50, 100]}
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-selectedRowCount": {
						display: "none !important",
					},
					"& .MuiDataGrid-toolbar": {
						justifyContent: "flex-end",
						"& .MuiTablePagination-root": {
							marginLeft: "auto",
						},
					},
					"& .MuiDataGrid-columnHeader": {
						"&.hover-column-header": {
							"& .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-sortIcon": {
								display: "none",
							},
						},
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
					},
					".MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row": {
						cursor: "pointer",
						minHeight: 50,
					},
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
					".MuiDataGrid-cell": {
						lineHeight: "50px",
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 0,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-columnHeader:hover": {
						"& .MuiDataGrid-columnSeparator": {
							display: "flex",
						},
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5",
					},
					"& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content": {
						backgroundColor: "#e7e9f0",
					},
				}}
				slots={{
					noRowsOverlay: () => (
						<Stack height='100%' alignItems='center' justifyContent='center'>
							<Box
								sx={{
									position: "relative",
									height: "32px",
									width: "32px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<PersonOffOutlinedIcon
									sx={{
										width: "24px",
										height: "24px",
										color: "rgba(0, 0, 0, 0.38)",
									}}
								/>
							</Box>
							<Typography
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: 400,
									color: "rgba(0, 0, 0, 0.38)",
									marginTop: "4px",
									textAlign: "center",
								}}
							>
								{getCompanyUsersErrorMessage !== ""
									? "Error: " + getCompanyUsersErrorMessage
									: "No User added"}
							</Typography>
						</Stack>
					),
				}}
			/>

			{isUserDeactivateDialogOpen && (
				<ConfirmDialog
					open={isUserDeactivateDialogOpen}
					onClose={() => dispatch(closeUserDeactivateDialog())}
					action={() => {
						dispatch(closeUserDeactivateDialog());
					}}
					text='Are you sure that you want to update the job status?'
					item={userDeactivateData}
				/>
			)}

			<Snackbar
				open={updateUserStatus === "error"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetUserStatus());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='error'>
					{changeUserStatusErrorMessage ??
						"An Error Occurred. Please try again."}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={updateUserStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetUserStatus());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Supervisor Updated Successfully!"}
				</MuiAlert>
			</Snackbar>
		</Box>
	);
};

export default SupervisorTable;
