import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setCurrentCompanyData } from "../../../../../export/gat-admin";
import useViewport from "../../../../utils/use-viewport";
import { OneCompanyWithSearchField } from "../../store/gat-admin-slice-types";

// import colors from "../../../../config/colors";

interface GridColumn {
    field: string;
    type?: string;
    width?: number;
    minWidth?: number;
    flex?: number;
    headerName?: string;
    renderHeader?: () => React.ReactNode;
    renderCell?: (params: { row: any }) => React.ReactNode;
    valueGetter?: (params: { row: any }) => any;
    headerAlign?: "left" | "center" | "right";
    sortable?: boolean;
    getActions?: (params: { row: any }) => any[];
    hide?: boolean
}

const columns: GridColumn[] = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
        field: "name",
        headerName: "Companies",
        width: 200,
        flex: 1.5,
        renderCell: (params: any) => (
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: 3,
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {params.row["name"]}
            </Typography>
        ),
        renderHeader: () => {
            return (
                <div
                    id={"factories-count-div"}
                    style={{
                        fontFamily: "Roboto",
                        padding: "0px 12px",
                        marginLeft: "12px",
                        fontWeight: 500,
                        color: "rgba(0,0,0,0.87)",
                    }}
                >
                    {"Companies"}
                </div>
            );
        },
    },
    {
        field: "updatedBy",
        headerName: "Last Updated By",
        width: 200,
        flex: 1,
        renderCell: (params) => (
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {params.row["updatedBy"]}
            </Typography>
        ),
        renderHeader: () => (
            <div
                style={{
                    fontFamily: "Roboto",
                    padding: "0px 12px",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {"Last Updated By"}
            </div>
        ),
    },
    {
        field: "userCount",
        headerName: "Users",
        width: 100,
        renderCell: (params) => (
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {params.row["userCount"]}
            </Typography>
        ),
        renderHeader: () => (
            <div
                style={{
                    fontFamily: "Roboto",
                    padding: "0px 12px",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {"Users"}
            </div>
        ),
    },
    {
        field: "workerCount",
        headerName: "Workers",
        width: 100,
        renderCell: (params) => (
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {params.row["workerCount"]}
            </Typography>
        ),
        renderHeader: () => (
            <div
                style={{
                    fontFamily: "Roboto",
                    padding: "0px 12px",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {"Workers"}
            </div>
        ),
    },
    {
        field: "createdBy",
        headerName: "Created By",
        width: 200,
        flex: 1,
        renderCell: (params) => (
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: "12px",
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {params.row["createdBy"]}
            </Typography>
        ),
        renderHeader: () => (
            <div
                style={{
                    fontFamily: "Roboto",
                    padding: "0px 12px",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.87)",
                }}
            >
                {"Created By"}
            </div>
        ),
    },
];

const useStyles = makeStyles(() => ({
    dataGridRoot: {
        border: "none",
    },
    dataGridTable: {
        border: "none",
    },
    dataGridCell: {
        border: "none",
    },
    mainContent: {

    }
}));

interface IFactoryTableProps {
    filteredCompanies: OneCompanyWithSearchField[]
    companiesLoadingStatus: string
    isBoeing: boolean
    creatingNewCompany: boolean
    setCreatingNewCompany: (value: boolean) => void;
}

const FactoryTable: React.FC<IFactoryTableProps> = ({
    filteredCompanies,
    companiesLoadingStatus,
    isBoeing,
    creatingNewCompany,
    setCreatingNewCompany,
}) => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { height } = useViewport();
    const boxRef: any = useRef();
    const [factoryTableContainerHeight, setFactoryTableContainerHeight] =
        useState(33);
    const handleClick = (params: any) => {
        if (companiesLoadingStatus !== "pending") {
            setCreatingNewCompany(false);
            dispatch(setCurrentCompanyData(params.row));
            if (isBoeing) {
                history.push({
                    pathname: "/admin-settings",
                    state: { data: params.row, creatingNewCompany },
                });
            } else {
                history.push({
                    pathname: "/admin-settings",
                    state: { data: params.row, creatingNewCompany },
                });
            }
        }
    };

    useEffect(() => {
        // This function calculate X and Y
        const getPosition = () => {
            if (boxRef) {
                try {
                    const y = boxRef.current.offsetTop;
                    setFactoryTableContainerHeight(height - y);
                } catch (error) {
                    // Do nothing
                }
            }
        };
        getPosition();
    }, [height]);

    useEffect(() => {
        try {
            if (filteredCompanies !== undefined) {
                const factoriesCountDiv = document.getElementById(
                    "factories-count-div"
                );
                if (factoriesCountDiv) {
                    factoriesCountDiv.innerHTML = `Companies (${filteredCompanies.length})`;
                }
            }
        } catch (e) {
            // Do nothing
        }
    }, [filteredCompanies]);

    return (
        <div className={classes.mainContent}>
            <Box
                ref={boxRef}
                height={factoryTableContainerHeight - 32}
                minHeight={400}
                sx={{
                    //marginTop: "32px",
                    minWidth: "1000px",
                    maxWidth: "1312px",
                    width: "100%",
                    height: "400px",
                }}
            >
                <DataGridPro
                    disableColumnMenu
                    loading={companiesLoadingStatus === "pending"}
                    onRowClick={handleClick}
                    columns={columns}
                    rows={filteredCompanies ?? []}
                    classes={{
                        root: classes.dataGridRoot,
                        // table: classes.dataGridTable,
                        cell: classes.dataGridCell,
                    }}
                    hideFooter
                    sx={{
                        height: `${factoryTableContainerHeight - 32}px`,
                    }}
                />
            </Box>
        </div>
    );
};

export default FactoryTable;
