import React, { createContext, useState } from "react";
import { ReactNode } from "react";

export interface FilterData {
  id: string;
  timeAdded: number;
  name: string;
}

export interface AppliedFilter {
  type: string;
  timeAdded: number;
  data: FilterData[];
}

interface FilterContextType {
  appliedFilters: AppliedFilter[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFilter[]>>;
  filterAccordionHeight: number;
  setFilterAccordionHeight: React.Dispatch<React.SetStateAction<number>>;
  setSearchedValue: React.Dispatch<React.SetStateAction<string>>;
  searchedValue: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilterContext = createContext<FilterContextType | undefined>(
  undefined
);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
  const [filterAccordionHeight, setFilterAccordionHeight] = useState<number>(0);
  const [searchedValue, setSearchedValue] = useState("");
  const [open, setOpen] = useState(true);

  return (
    <FilterContext.Provider
      value={{
        appliedFilters,
        setAppliedFilters,
        filterAccordionHeight,
        setFilterAccordionHeight,
        searchedValue,
        setSearchedValue,
        open,
        setOpen,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
